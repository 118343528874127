import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import './index.css';
import { resetPwd } from '../../../../../data/API';

const ConfirmPassword = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //get query params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  if (email == null) {
    navigate('/404');
  }

  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  const onSubmit = async () => {
    setIsBtnDisabled(true);

    let formValues = await form.validateFields();

    let { password, cnf_password } = formValues;

    let reset = await resetPwd({ email, password, cnfPassword: cnf_password });

    if (reset?.data?.success) {
      message.success(reset?.data?.message);
      setIsBtnDisabled(false);
      navigate('/storage-box/reset-password/success');
    } else {
      setIsBtnDisabled(false);
      return message.error('Unable to reset password, Please try again...!');
    }
  };

  return (
    <>
      <Container fluid={true} className="p-0 m-0">
        <div className="login-header px-3">
          <div className="login-header-content">
            <p className="header">Reset your password</p>
          </div>
        </div>
      </Container>

      <Container fluid={true} className="signup-form-bg py-5">
        <Row className="align-items-center justify-content-center">
          <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12}>
            <div className="signup-form px-2">
              <Form form={form} layout="vertical" autoComplete="off">
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please enter the password' }]}>
                        <Input
                          size={'large'}
                          type={!showPassword ? 'password' : 'text'}
                          placeholder="******"
                          className="signup-form-input bluebox-placeholder m-0"
                        />
                      </Form.Item>
                      <div className="toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        name="cnf_password"
                        label="Confirm password"
                        rules={[
                          { required: true, message: 'Please enter the confirm password' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('The two passwords that you entered do not match!')
                              );
                            }
                          })
                        ]}>
                        <Input
                          size={'large'}
                          type={!showCnfPassword ? 'password' : 'text'}
                          placeholder="******"
                          className="signup-form-input bluebox-placeholder m-0"
                        />
                      </Form.Item>
                      <div
                        className="toggle-icon"
                        onClick={() => setShowCnfPassword(!showCnfPassword)}>
                        {showCnfPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="text-right pb-0">
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="signup-register-btn mt-2"
                        disabled={isBtnDisabled}
                        onClick={() => onSubmit()}>
                        Reset password
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ConfirmPassword;

ConfirmPassword.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
