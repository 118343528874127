import React, { useEffect, useState } from 'react';
import { getFooter } from '../../data/API';
import AOS from 'aos';
import * as $ from 'jquery';

export default function Footer() {
  const [footer, setFooter] = useState([]);

  const Footerdata = () => {
    getFooter()
      .then((res) => {
        setFooter(res.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    AOS.init();

    setTimeout(() => {
      $('#contact_phone').addClass('aos-animate');
      $('#contact_email').addClass('aos-animate');
    }, 1000);

    Footerdata();
  }, []);
  return (
    <>
      <div className="silouhetter"></div>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row">
                <div id="contact_phone" className="col-md-6 my-2 aos-init aos-animate">
                  <div className="row">
                    <div className="col-3 m-auto">
                      <i className="fa fa-headphones text-white"></i>
                    </div>
                    <div className="col-9">
                      <p className="mb-0 fs-13 font-weight-bold text-white">Phone</p>
                      <a href="tel:(650)502-9522" className="text-white fs-13">
                        {footer.results?.[0].phone_number}
                      </a>
                    </div>
                  </div>
                </div>
                <div id="contact_email " className="col-md-6 my-2 aos-init aos-animate">
                  <div className="row">
                    <div className="col-3 m-auto">
                      <i className="far fa-envelope text-white"></i>
                    </div>
                    <div className="col-9">
                      <p className="mb-0 fs-13 font-weight-bold text-white">Email:</p>
                      <a href="mailto:info@bluebox.rent" className="text-white fs-13">
                        {footer.results?.[0].email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3 d-flex flex-column">
              <div>
                <a href="/">
                  <img
                    src={'../../img/footer-logo-bluebox.png'}
                    className="img-fluid logo"
                    alt="footer logo"
                    height="100%"
                    width="100%"
                  />
                </a>
              </div>
              <div className="d-flex mt-3">
                <div className="mr-5">
                  <a href="/">
                    <img
                      src="img/LocallyOwned.png"
                      className="img-fluid logo"
                      alt="footer logo"
                      style={{ height: '100%' }}
                    />
                  </a>
                </div>
                <div>
                  <a href="/">
                    <img
                      src="img/GreenCertifiedBusiness.png"
                      className="img-fluid logo"
                      alt="footer logo"
                      style={{ height: '100%' }}
                    />
                  </a>
                </div>
              </div>
              <p className="text-white fs-13 mt-3"></p>
            </div>
            <div className="col-md-2 pb-3">
              <h5 className="text-white font-weight-bold">Company</h5>
              <div className="line bg-primary w-50"></div>
              <ul>
                <li>
                  <a href="../about" className="text-white fs-12 footer-title">
                    About BlueBox
                  </a>
                </li>
                <li>
                  <a href="../blog" className="text-white fs-12 footer-title">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="../pricing" className="text-white fs-12 footer-title">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="../homemoving" className="text-white fs-12 footer-title">
                    Home Moves
                  </a>
                </li>
                <li>
                  <a href="../officemoving" className="text-white fs-12 footer-title">
                    Office Moves
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2 pb-3">
              <h5 className="text-white font-weight-bold">Support</h5>
              <div className="line bg-primary w-50"></div>
              <ul>
                {/*<li>*/}
                {/*  <a href="../faq" className="text-white fs-12 footer-title">*/}
                {/*    FAQ*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a href="../contactus" className="text-white fs-12 footer-title">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="../terms" className="text-white fs-12 footer-title">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="../privacypolicy" className="text-white fs-12 footer-title">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>






            <div className="col-md-3 pb-3">
              <h5 className="text-white font-weight-bold">Moving boxes near me</h5>
              <div className="line bg-primary w-50"></div>
              <ul>
                <li>
                  <a href="../san-francisco" className="text-white fs-12 footer-title">
                   Moving boxes San Francisco
                  </a>
                </li>
                <li>
                  <a href="../oakland" className="text-white fs-12 footer-title">
                    Moving boxes Oakland
                  </a>
                </li>
                <li>
                  <a href="../san-mateo" className="text-white fs-12 footer-title">
                    Moving boxes San Mateo
                  </a>
                </li>
                 <li>
                  <a href="../san-jose" className="text-white fs-12 footer-title">
                    Moving boxes San Jose 
                  </a>
                </li>
                <li>
                  <a href="../sunnyvale" className="text-white fs-12 footer-title">
                    Moving boxes Sunnyvale
                  </a>
                </li>
                <li>
                  <a href="../fremont" className="text-white fs-12 footer-title">
                    Moving boxes Fremont
                  </a>
                </li>
              </ul>
            </div>















            <div className="col-md-2 pb-3">
              <h5 className="text-white font-weight-bold">Follow us </h5>
              <div className="line bg-primary w-50"></div>
              <div className="row social-icons">
                <div className="col-2 mt-2">
                  <a
                    href="https://instagram.com/rentbluebox"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-instagram text-white"></i>
                  </a>
                </div>
                <div className="col-2 mt-2">
                  <a
                    href="https://www.tiktok.com/@rentbluebox?_t=8f5AM4LnqN4&_r=1"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-tiktok text-white"></i>
                  </a>
                </div>
                <div className="col-2 mt-2">
                  <a
                    href="https://www.facebook.com/rentbluebox?mibextid=LQQJ4d"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-facebook text-white"></i>
                  </a>
                </div>
                <div className="col-2 mt-2">
                  <a
                    href="https://threads.net/@rentbluebox"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa-brands fa-threads text-white"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
