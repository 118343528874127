import * as actionTypes from './constants';

const defaultStateValues = () => {
  return {
    currentStep: 0,
    choosePackage: {
      category: 'Home',
      subCategory: 'Box Packages',
      selectedRentalPeriod: {
        id: '',
        period: ''
      },
      selectedPackage: {},
      packageIndex: null
    },
    packagingSupplies: {
      selectedProducts: []
    },
    movingSupplies: {
      selectedProducts: []
    },
    deliveryDetails: {
      deliveryInfo: {}
    },
    pickupDetails: {
      pickupInfo: {}
    },
    discount: null,
    personalData: {},
    personalPayDetails: {},
    customerAddress: {},
    orderId: null,
    isChangePackage: false
  };
};

const defaultState = defaultStateValues();

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_RENTAL_PERIOD:
      return {
        ...state,
        choosePackage: { ...state.choosePackage, selectedRentalPeriod: action.data }
      };

    case actionTypes.SET_CATEGORY:
      return { ...state, choosePackage: { ...state.choosePackage, category: action.data } };

    case actionTypes.SET_PACKAGE:
      return { ...state, choosePackage: { ...state.choosePackage, selectedPackage: action.data } };

    case actionTypes.SET_PACKAGE_INDEX:
      return { ...state, choosePackage: { ...state.choosePackage, packageIndex: action.data } };

    case actionTypes.SET_STEP_COUNT:
      return { ...state, currentStep: action.data };

    case actionTypes.SET_PACKAGING_PRODUCTS:
      return {
        ...state,
        packagingSupplies: { ...state.packagingSupplies, selectedProducts: [...action.data] }
      };

    case actionTypes.SET_MOVING_SUPPLIES_PRODUCTS:
      return {
        ...state,
        movingSupplies: { ...state.movingSupplies, selectedProducts: [...action.data] }
      };

    case actionTypes.SET_DELIVERY_INFO:
      return { ...state, deliveryDetails: { ...state.deliveryDetails, deliveryInfo: action.data } };

    case actionTypes.SET_CUSTOMER_ADDRESS:
      return { ...state, customerAddress: action.data };

    case actionTypes.SET_PICKUP_DETAIL:
      return { ...state, pickupDetails: { ...state.pickupDetails, pickupInfo: action.data } };

    case actionTypes.SET_ORDER_ID:
      return { ...state, orderId: action.data };

    case actionTypes.SET_CHANGE_PACKAGE:
      return { ...state, isChangePackage: action.data };

    case actionTypes.SET_DISCOUNT:
      return { ...state, discount: action.data };

    case actionTypes.SET_PERSONAL_DATA:
      return { ...state, personalData: action.data };

    case actionTypes.RESET_REDUX_STORE:
      return defaultStateValues();

    default:
      return state;
  }
};
