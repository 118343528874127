import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentCard from '../../ContentCard';

const PasswordSuccess = (props) => {
    useEffect(() => {
        props.setShowHideHeader(true);
        props.setshowHideFooter(false);
        props.setshowHideinnerFooter(false);
    }, []);
    
    return (
        <ContentCard type={"passwordReset"} />
    )
};

export default PasswordSuccess;


PasswordSuccess.propTypes = {
    setShowHideHeader: PropTypes.func,
    setshowHideFooter: PropTypes.func,
    setshowHideinnerFooter: PropTypes.func
};