import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Button, message, Steps } from 'antd';
import './index.css';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowLeftOutlined, ArrowRightOutlined, PhoneFilled, PhoneOutlined, UpOutlined } from '@ant-design/icons';
import { BottomSheet } from 'react-spring-bottom-sheet';
import StorageDetail from './StorageDetail';
import DeliveryDetails from './DeliveryDetails';
import PersonalDetails from './PersonalDetails';
import PaymentDetails from './PaymentDetails';
import { getCustomer, getDeliverWindows, getExtraWork } from '../../../data/API';
import OrderSummary from '../OrderSummary';
import useGetPrice from '../../../hooks/useGetPrice';

import { resetStore, setStepCount } from '../store/actionCreators';

const { Step } = Steps;

const BuyingBox = (props) => {
  const tabref = useRef(null);

  const dispatch = useDispatch();

  const currentStep = useSelector((state) => state.storage.currentStep);

  if (!currentStep) {
    dispatch(setStepCount(0));
  }

  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false);
  const [deliveryWindows, setDeliveryWindows] = useState([]);
  const [extraWork, setExtraWork] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [isParentNextStepClicked, setParentNextStepClicked] = useState(false);
  const [isParentPrevStepClicked, setParentPrevStepClicked] = useState(false);
  const { boxPrices } = useGetPrice();

  const [stepRef, setStepRef] = useState(0);

  const getDeliverySlots = async () => {
    try {
      let response = await getDeliverWindows();
      if (response?.data) {
        setDeliveryWindows(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching delivery windows', err);
    }
  };

  const getExtarworkInfo = async () => {
    try {
      let response = await getExtraWork();
      if (response?.data) {
        setExtraWork(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching extra work info windows', err);
    }
  };

  const getCustomerData = async () => {
    try {
      let response = await getCustomer();
      if (response?.data) {
        setCustomerInfo(response?.data?.data);
      }
    } catch (err) {
      return message.error('error fetching customer data', err);
    }
  };

  const steps = [
    {
      title: 'Storage Details',
      content: (
        <StorageDetail
          isParentNextStepClicked={isParentNextStepClicked}
          setParentNextStepClicked={setParentNextStepClicked}
          tabref={tabref}
          stepRef={stepRef}
        />
      ),
      status: 'process'
    },
    {
      title: 'Delivery Details',
      content: (
        <DeliveryDetails
          tabref={tabref}
          deliveryWindows={deliveryWindows}
          extraWork={extraWork}
          isParentNextStepClicked={isParentNextStepClicked}
          setParentNextStepClicked={setParentNextStepClicked}
          stepRef={stepRef}
          customerInfo={customerInfo}
        />
      ),
      status: 'wait'
    },
    {
      title: 'Personal Details',
      content: (
        <PersonalDetails
          tabref={tabref}
          isParentNextStepClicked={isParentNextStepClicked}
          setParentNextStepClicked={setParentNextStepClicked}
          stepRef={stepRef}
          extraWork={extraWork}
          customerInfo={customerInfo}
        />
      ),
      status: 'wait'
    }
    // {
    //   title: 'Payment Details',
    //   content: <PaymentDetails tabref={tabref} />,
    //   status: 'wait',
    // }
  ];

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);

    getDeliverySlots();
    getExtarworkInfo();
    getCustomerData();

    dispatch(resetStore());
  }, []);

  const onStepChange = (value) => {
    dispatch(setStepCount(value));
  };

  const nextStep = () => {
    if (currentStep == 3) {
      setParentNextStepClicked(true);
    } else {
      setStepRef(currentStep + 1);
      setParentNextStepClicked(true);
    }
  };

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const showOrderSummary = () => {
    setOrderSummaryOpen(true);
  };

  const hideOrderSummary = () => {
    setOrderSummaryOpen(false);
  };

  return (
    <Container fluid={true} className="my-4 p-0">
      <div className="info mr-3">
        <div>
          <h6>Need Help or Have a Question?</h6>
          <div className="info-call">
            <div className="call-label">
              <PhoneFilled />
            </div>
            <div className="call-details">
              <p className='call-text'>Call or text</p>
              <p className='phone-no'>(650) 502-9522</p>
            </div>
          </div>
        </div>
      </div>
      <Row className="justify-content-center boxing-steps m-0" ref={tabref}>
        <Col xxl={6} xl={6} lg={6} md={6} sm={11} xs={11}>
          <Steps
            responsive={false}
            current={currentStep}
            labelPlacement="vertical"
            onChange={onStepChange}>
            {steps.map((item, index) => (
              <Step
                key={index}
                title={item.title}
                // disabled={currentStep < index}
                content={item.content}></Step>
            ))}
          </Steps>

          <Row className="my-4">
            <Col className="px-1 d-flex justify-content-start align-items-center">
              <Button
                className={`btn-previous-step ${currentStep > 0 ? '' : 'd-none'}`}
                icon={<ArrowLeftOutlined />}
                size={'large'}
                onClick={prevStep}>
                Previous Step
              </Button>
            </Col>
            <Col className="px-1 d-flex justify-content-end align-items-center">
              <Button
                className="btn-next-step"
                icon={<ArrowRightOutlined />}
                size={'large'}
                onClick={nextStep}>
                Next Step
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="row d-flex fex-row m-0">
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <section className="pb-0 mb-4 pl-3 pr-3 ">
            <div className="container">
              <div className="row d-flex justify-content-end">
                <div className="col-lg-11 p-0 ">{steps[currentStep]?.content}</div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 p-0 order-summary mb-10">
          <section className="pb-0 order-summary-section">
            <div className="container order-summary-container pr-0">
              <div className="row d-flex mr-0">
                <div className="col-lg-11 p-0 bg-white summary-card order-summary-desktop">
                  <OrderSummary
                    setOrderSummaryOpen={setOrderSummaryOpen}
                    deliveryWindows={deliveryWindows}
                    extraWork={extraWork}
                    boxPrices={boxPrices}
                  />
                </div>
                <div className="order-summary-mt">
                  <Button
                    icon={<UpOutlined />}
                    onClick={showOrderSummary}
                    className="btn  bg-theme-secondary order-summary-mt-btn "
                    disabled={false}>
                    Order Summary
                  </Button>

                  <BottomSheet open={orderSummaryOpen}>
                    <div className="order-summary-mt-header" onClick={hideOrderSummary}>
                      <p className="mb-0 ml-4 pt-3 pb-3 title">
                        {' '}
                        <i className="fa fa-chevron-down mr-2"></i> Order Summary
                      </p>
                    </div>
                    <OrderSummary
                      setOrderSummaryOpen={setOrderSummaryOpen}
                      deliveryWindows={deliveryWindows}
                      extraWork={extraWork}
                      boxPrices={boxPrices}
                    />
                  </BottomSheet>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
};

export default BuyingBox;

BuyingBox.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
