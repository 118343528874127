import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { requestReset } from '../../../../../data/API';

const RequestReset = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [showTimer, setShowTimer] =  useState(false)
  const [isBtnDisabled, setIsBtnDisabled] =  useState(false)

  const reduceTime = () => {
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timer !== 0 && showTimer) reduceTime();
    else if(timer == 0 && showTimer) setIsBtnDisabled(false)
  }, [timer]);

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  const onSubmit = async () => {
    setIsBtnDisabled(true)

    let formValues = await form.validateFields();

    let resetPwd = await requestReset(formValues);
  
    if(resetPwd?.data?.success) {
      setShowTimer(true);
      setTimer(30);

      return message.success(resetPwd?.data?.message)
    } else {
      setIsBtnDisabled(false)
      return message.error("Unable to reset password, Please try again...!")
    }
  }

  return (
    <>
      <Container fluid={true} className="p-0 m-0">
        <div className="login-header px-3">
          <div className="login-header-content">
            <p className="header">Reset Password</p>
          </div>
        </div>
      </Container>

      <Container fluid={true} className="signup-form-bg py-5">
        <Row className="align-items-center justify-content-center">
          <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12}>
            <div className="signup-form px-2">
              <Form
                form={form}
                layout="vertical"
                autoComplete="off">
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className={'pb-0'}>
                    <Form.Item
                      name="email"
                      label="We’ll send a reset link to your email"
                      rules={[
                        { required: true, message: 'Please enter the mail' },
                        { type: 'email', message: 'Please enter a valid e-mail' }
                      ]}>
                      <Input
                        size={'large'}
                        type="text"
                        placeholder="Enter Email"
                        className="signup-form-input bluebox-placeholder"
                      />
                    </Form.Item>
                    { showTimer ? <p className='timer-text s-color-black'>If your email is in our database, you’ll receive a reset link. If you didn’t get it, you can try again in <span className='color-secondary change-package-p'>{timer} seconds</span></p> : "" }
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="text-right pb-0">
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="signup-register-btn"
                        disabled={isBtnDisabled}
                        onClick={() => onSubmit()}
                      >
                        Send link
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <div className="signup-form-divider">
          <div className="divider"></div>
        </div>
        <div className="signup-footer pt-3">
          <p className="mb-0 sub-text">
            <span className="navigate-link " onClick={() => navigate('/storage-box/login')}>
              Go back to login
            </span>
          </p>
        </div>
      </Container>
    </>
  );
};

export default RequestReset;

RequestReset.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
