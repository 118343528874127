import React, { useEffect, useState } from 'react';
import { getPrivacyPolicy } from '../../data/API';
import {Helmet} from "react-helmet";
// import SimpleReactValidator from "simple-react-validator";

export default function index(props) {
  props.setFooteroffice(true);
  props.setshowHideFooter(false);
  const [privacyPolicy, setprivacyPolicy] = useState({});

  const callApi = () => {
    getPrivacyPolicy().then((res) => {
      setprivacyPolicy(res.data);
    });
  };
  useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | BlueBox</title>
        <meta name="description" content="This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from bluebox.rent (the “Site”)." />
        <meta name="keywords" content="bluebox privacy policy" />
      </Helmet>
      <link rel="stylesheet" type="text/css" href="../../public/style/style.css" />

      <div className="privacypolicy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="policyrow policy">
        <div className="columns small-12 large-10 large-offset-1 end" id="page-content">
          <div className="container">
            {privacyPolicy.results?.map((obj, i) => {
              return (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: obj.content
                  }}></div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
