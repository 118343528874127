import * as actionTypes from './constants';

// //Form validation Actions
// export const changeDeliveryFormValidation = (payload) => ({
//   type: actionTypes.CHANGE_DELIVERY_FORM_VALIDATION,
//   data: payload
// });

// export const changePickupFormValidation = (payload) => ({
//   type: actionTypes.CHANGE_PICKUP_FORM_VALIDATION,
//   data: payload
// });

// //Rental Actions
// export const setRentalId = (payload) => ({
//   type: actionTypes.SET_RENTAL_ID,
//   data: payload
// });

// New Code
export const resetReduxStore = (payload) => ({
  type: actionTypes.RESET_REDUX_STORE,
  data: payload
});

export const setRentalPeriod = (payload) => ({
  type: actionTypes.SET_RENTAL_PERIOD,
  data: payload
});

export const setCategory = (payload) => ({
  type: actionTypes.SET_CATEGORY,
  data: payload
});

export const setPackage = (payload) => ({
  type: actionTypes.SET_PACKAGE,
  data: payload
});

export const setPackageIndex = (payload) => ({
  type: actionTypes.SET_PACKAGE_INDEX,
  data: payload
});

export const setStepCount = (payload) => ({
  type: actionTypes.SET_STEP_COUNT,
  data: payload
});
export const setPackagingProducts = (payload) => ({
  type: actionTypes.SET_PACKAGING_PRODUCTS,
  data: payload
});

export const setMovingSuppliesProducts = (payload) => ({
  type: actionTypes.SET_MOVING_SUPPLIES_PRODUCTS,
  data: payload
});

export const setDeliveryInfo = (payload) => ({
  type: actionTypes.SET_DELIVERY_INFO,
  data: payload
});

export const setCustomerAddress = (payload) => ({
  type: actionTypes.SET_CUSTOMER_ADDRESS,
  data: payload
});

export const setPickupDetails = (payload) => ({
  type: actionTypes.SET_PICKUP_DETAIL,
  data: payload
});

export const setOrderId = (payload) => ({
  type: actionTypes.SET_ORDER_ID,
  data: payload
});

export const setChangePackage = (payload) => ({
  type: actionTypes.SET_CHANGE_PACKAGE,
  data: payload
});

export const setPersonalData = (payload) => ({
  type: actionTypes.SET_PERSONAL_DATA,
  data: payload
});

export const setDiscount = (payload) => ({
  type: actionTypes.SET_DISCOUNT,
  data: payload
});
