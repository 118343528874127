import * as actionTypes from './constants';
export const setAUthentication = (payload) => ({
  type: actionTypes.SET_AUTH,
  data: payload
});

export const unSetAuthenticationData = (payload) => ({
  type: actionTypes.RESET_REDUX_STORE,
  data: payload
});
