import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet'
import * as $ from 'jquery'

import 'react-spring-bottom-sheet/dist/style.css'

import uuid from 'react-uuid';
import { ReactSession } from 'react-client-session';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Steps, Modal } from 'antd';
import './index.css';
import { ArrowLeftOutlined, ArrowRightOutlined, UpOutlined, CreditCardOutlined, PhoneFilled } from '@ant-design/icons';

import ChoosePackage from './ChoosePackage';
import PackagingSupplies from './PackagingSupplies';
import MovingSupplies from './MovingSupplies';
import DeliveryDetails from './DeliveryDetails';
import PickupDetails from './PickupDetails';
import PersonalPayDetails from './PersonalPayDetails';
import OrderSummary from './OrderSummary';

import { setStepCount, resetReduxStore } from './store/actionCreators';

import { clearCart, getDiscount, sendOrderReminderEmail } from './../../data/API';
import Offers from "../Offers";
const { Step } = Steps;


ReactSession.setStoreType('localStorage');

if (ReactSession.get('session')) {
  const sessionid = ReactSession.get('session');
  ReactSession.set('session', sessionid);
} else {
  const sessionid = uuid();
  ReactSession.set('session', sessionid);
}


export const handleEventClick = () => {
  const eventData = localStorage.getItem("selectEvent");
  localStorage.setItem("selectEvent", eventData === "true" ? "false" : "true");
}

const Packages = (props) => {
  const tabref = useRef(null)
  const formRef = useRef(null)

  props.setSuppressHeaderOptions(true);

  const dispatch = useDispatch();
  const session = ReactSession.get('session');

  const currentStep = useSelector(state => state.package.currentStep);
  const { user } = useSelector(state => state.auth);

  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false);
  const [isParentNextStepClicked, setParentNextStepClicked] = useState(false);
  const [isParentPrevStepClicked, setParentPrevStepClicked] = useState(false);
  const [isParentPayClicked, setParentPayClicked] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(false);
  const handleOpenPopup = () => {
    setPopupVisible(true);
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };


  const [stepRef, setStepRef] = useState(0);

  const steps = [
    {
      title: 'Choose Package',
      content: <ChoosePackage
        isParentNextStepClicked={isParentNextStepClicked}
        setParentNextStepClicked={setParentNextStepClicked}
        tabref={tabref}
        stepRef={stepRef}
      />,
      status: 'process',
    },
    {
      title: 'Packing Supplies',
      content: <PackagingSupplies
        isParentNextStepClicked={isParentNextStepClicked}
        setParentNextStepClicked={setParentNextStepClicked}
        tabref={tabref}
        stepRef={stepRef}
      />,
      status: 'wait',

    },
    {
      title: 'Moving Supplies',
      content: <MovingSupplies
        isParentNextStepClicked={isParentNextStepClicked}
        setParentNextStepClicked={setParentNextStepClicked}
        tabref={tabref}
        stepRef={stepRef}
      />,
      status: 'wait',

    },
    {
      title: 'Delivery Details',
      content: <DeliveryDetails
        isParentNextStepClicked={isParentNextStepClicked}
        setParentNextStepClicked={setParentNextStepClicked}
        tabref={tabref}
      />,
      status: 'wait'
    },
    {
      title: 'Pick up Details',
      content: <PickupDetails
        isParentNextStepClicked={isParentNextStepClicked}
        setParentNextStepClicked={setParentNextStepClicked}
        tabref={tabref}
      />,
      status: 'wait',
    },
    {
      title: 'Personal & Payment Details',
      content: <PersonalPayDetails
        tabref={tabref}
        isParentPayClicked={isParentPayClicked}
        setParentPayClicked={setParentPayClicked}
      />,
      status: 'wait',

    }
  ];



  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
    // status: item.status
  }));

  let idleTimer;

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      if (isMobileScreen()) {
        const modalShown = localStorage.getItem('modalShownMobile');

        if (!modalShown) {
          setPopupVisible(true);
          localStorage.setItem('modalShownMobile', 'true');
        }
      }
    }, 5000);
  };

  const isMobileScreen = () => {
    return window.innerWidth <= 768;
  };

  const handleBeforeUnload = async (e) => {
    const email = localStorage.getItem("email")
    const packageData = JSON.parse(localStorage.getItem("package"))
    const cartTotal = localStorage.getItem("cartTotal");
    if (email) {
      await sendOrderReminderEmail(email, cartTotal, packageData)
    }
    localStorage.removeItem("email");
    localStorage.removeItem("package");
    localStorage.removeItem("cartTotal");
    localStorage.removeItem('modalShownMobile');
    localStorage.removeItem('modalShown');
  };
  useEffect(() => {
    if (user?.email) {
      localStorage.setItem("email", user?.email);
    }
    resetIdleTimer();
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('touchstart', resetIdleTimer);
    window.addEventListener('touchmove', resetIdleTimer);
    window.addEventListener('touchend', resetIdleTimer);
    window.addEventListener('resize', resetIdleTimer);
    const handleMouseLeave = () => {
      const modalShown = localStorage.getItem('modalShown');
      const selectEvent = localStorage.getItem('selectEvent')
      if (!modalShown && !isMobileScreen() && (selectEvent === "false" || selectEvent === null)) {
        setPopupVisible(true);
        localStorage.setItem('modalShown', 'true');
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return async () => {
      clearTimeout(idleTimer);
      if (formRef.current) {
        formRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
      const email = localStorage.getItem("email")
      const packageData = JSON.parse(localStorage.getItem("package"))
      const cartTotal = localStorage.getItem("cartTotal");
      if (email) {
        await sendOrderReminderEmail(email, cartTotal, packageData)
      }
      localStorage.removeItem('modalShownMobile');
      localStorage.removeItem('modalShown');
      window.removeEventListener('touchstart', resetIdleTimer);
      window.removeEventListener('touchmove', resetIdleTimer);
      window.removeEventListener('touchend', resetIdleTimer);
      window.removeEventListener('resize', resetIdleTimer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  const onStepChange = (value) => {
    dispatch(setStepCount(value))
  };

  const nextStep = () => {
    setStepRef(currentStep + 1);
    setParentNextStepClicked(true);
  }


  const payOrder = () => {
    setParentPayClicked(true);
  }

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  }

  const showOrderSummary = () => {
    setOrderSummaryOpen(true);
    $(".ant-card-body").trigger("click");
  }

  const hideOrderSummary = () => {
    setOrderSummaryOpen(false)
  }
  const fetchOffer = async () => {
    let res = await getDiscount();
    setPopupData(res?.data)
  }
  useEffect(() => {
    fetchOffer()
  }, [])

  return (

    <div className='my-4' ref={formRef}>
      <Offers isVisible={isPopupVisible}
        onClose={handleClosePopup}
        popupData={popupData} />
      <div className="info mr-3 text-end">
        <div>
          <h6>Need Help or Have a Question?</h6>
          <div className="info-call">
            <div className="call-label">
              <PhoneFilled />
            </div>
            <div className="call-details">
              <p className='call-text'>Call or text</p>
              <p className='phone-no'>(650) 502-9522</p>
            </div>
          </div>
        </div>
      </div>

      <Row className="steps-section">
        <Col>
          <div className="w-100" style={{ marginTop: '0vh' }} ref={tabref}>
            <div className="steps-layout">
              <Steps responsive={false} current={currentStep} labelPlacement="vertical" onChange={onStepChange} >
                {steps.map((item, index) => (
                  <Step key={index} title={item.title} disabled={currentStep < index} content={item.content}></Step>
                ))}
              </Steps>
              <div className='container-fluid pb-2 pt-0  mt-4'>
                <div className="row">
                  <div className='col px-1 d-flex justify-content-start align-items-center'>
                    <Button className={`btn-previous-step ${currentStep > 0 ? '' : 'd-none'}`} icon={<ArrowLeftOutlined />} size={"large"} onClick={prevStep}>
                      Previous Step
                    </Button>
                  </div>
                  {
                    currentStep == 5 ?
                      <div className='col px-1 d-flex justify-content-end align-items-center'>
                        <Button className='btn-next-step' icon={<CreditCardOutlined />} size={"large"} onClick={payOrder}>
                          Pay & Order
                        </Button>
                      </div> :
                      <div className='col px-1 d-flex justify-content-end align-items-center'>
                        <Button className='btn-next-step' icon={<ArrowRightOutlined />} size={"large"} onClick={nextStep}>
                          Next Step
                        </Button>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </Col>
      </Row>
      <Row className='row d-flex fex-row' >
        <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>

          <section className="pb-0 mb-4 pl-3 pr-3 ">
            <div className='container'>
              <div className='row d-flex justify-content-end'>
                <div className='col-lg-11 p-0 step-shadow'>
                  {steps[currentStep].content}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 p-0 order-summary mb-10'>

          <section className="pb-0 order-summary-section" >
            <div className='container order-summary-container pr-0'>
              <div className='row d-flex mr-0'>
                <div className='col-lg-11 p-0 bg-white summary-card order-summary-desktop step-shadow'>
                  <OrderSummary setOrderSummaryOpen={setOrderSummaryOpen} />
                </div>
                <div className='order-summary-mt' >

                  <Button icon={<UpOutlined />} onClick={showOrderSummary}
                    className="btn  bg-theme-secondary order-summary-mt-btn "
                    disabled={false}>
                    Order Summary
                  </Button>

                  <BottomSheet open={orderSummaryOpen} blocking={false} data-body-scroll-lock-ignore="true">

                    <div className='order-summary-mt-header' onClick={hideOrderSummary}>
                      <p className='mb-0 ml-4 pt-3 pb-3 title'> <i className="fa fa-chevron-down mr-2" ></i>  Order Summary</p>
                    </div>
                    <OrderSummary setOrderSummaryOpen={setOrderSummaryOpen} />
                  </BottomSheet>


                </div>
              </div>
            </div>
          </section>

        </div>
      </Row>
    </div>
  );
};

export default Packages;

Packages.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func,
  showHideHeader: PropTypes.any,
  showHideFooter: PropTypes.any,
  showHideinnerFooter: PropTypes.any,
  setSuppressHeaderOptions: PropTypes.func
};
