import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import styles from "./styles.module.css"
import {
  rentalsPeriods,
  getDeliverWindows,
  getExtraWork,
  addPickUp,
  checkZipCode,
  addDelivery,
  getTotal,
  getTotalCart,
  getPickupSlots
} from '../../../data/API';
import { ReactSession } from 'react-client-session';
import { constants } from 'buffer';
import moment from 'moment';
import Autocomplete from 'react-google-autocomplete';
import Loading from './loading';
import { useDispatch, useSelector } from 'react-redux';
import { changePickupFormValidation } from '../store/actionCreators';

function step5Btn() {
  document.getElementById('step5').style.display = 'none';
  document.getElementById('step6').style.display = 'block';

  document.getElementById('st5li').classList.remove('current');
  document.getElementById('st5li').classList.add('complete');
  document.getElementById('st6').classList.add('active');
  document.getElementById('st6li').classList.add('current');
}

function step5BackBtn() {
  document.getElementById('step5').style.display = 'none';
  document.getElementById('step4').style.display = 'block';
  document.getElementById('st5').classList.remove('active');
  document.getElementById('st4').classList.add('active');
  document.getElementById('st4li').classList.add('current');
  document.getElementById('st5li').classList.remove('current');
  document.getElementById('st4li').classList.remove('complete');
}
export default function Step5(props) {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(new SimpleReactValidator());
  const [rentals, setRentalList] = React.useState([]);
  const [windows, setWindows] = React.useState([]);
  const [windows1, setWindows1] = React.useState([]);
  const [windows2, setWindows2] = React.useState([]);
  const [extra_work, setExtraWork] = React.useState([]);
  const key = 'AIzaSyCXbgZmfe_pRq23TGroNN5K29oC0KSbm5Y';
  const [isError, setError] = React.useState(false);
  const [errorMsg1, setMsg1] = React.useState('');
  const [errorMsg2, setMsg2] = React.useState('');

  const [getNewAddress, setNewAddress] = React.useState([]);

  const date = props.getPickupdate;
  const { delivery, pickup, setDelivery, setPickup } = props;

  const allFieldsValue = useSelector(state => state.boxPackage.pickup_form_validation);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setDisableSubmit(!Object.values(allFieldsValue).every((elem) => elem));
  }, [allFieldsValue])

  const handleChange = (event) => {
    props.setPickup({ ...props.pickup, [event.target.name]: event.target.value });
    if (event.target.value && event.target.value != "")
      dispatch(changePickupFormValidation({ [event.target.name]: true }))
    else
      dispatch(changePickupFormValidation({ [event.target.name]: false }))

    if (event.target.name == 'pickup_window1') {
      if (event.target.value) {
        const disableslot = windows.filter((obj) => obj.id !== event.target.value);
        setWindows2(disableslot);
        props.setPickup({ ...props.pickup, pickup_window1: event.target.value });

        let data = {
          session: ReactSession.get('session'),
          pickup_window_1: event.target.value
        };
        // localStorage.setItem("pickupwindow1",event.target.value);

        props.setLoading(true);
        addPickUp(data).then((res) => {
          getPickupSlots(ReactSession.get('session')).then((res) => {
            // console.log(props)
            // console.log(res)
            props.setPickupslot1(res?.data?.results?.[0]?.pickup_window_1);
            props.setLoading(false);
          });
        });
      }
    }
    if (event.target.name == 'pickup_window2') {
      if (event.target.value) {
        const disableslots = windows.filter((obj) => obj.id != event.target.value);
        setWindows1(disableslots);
        props.setPickup({ ...props.pickup, pickup_window2: event.target.value });

        let data = {
          session: ReactSession.get('session'),
          pickup_window_2: event.target.value
        };
        // localStorage.setItem("pickupwindow2",event.target.value);
        props.setLoading(true);

        addPickUp(data).then((res) => {
          getPickupSlots(ReactSession.get('session')).then((res) => {
            // console.log(props)
            // console.log(res)
            props.setPickupslot2(res?.data?.results?.[0]?.pickup_window_2);
            props.setLoading(false);
          });
        });
      }
    }

    if (event.target.name == 'apt_number') {
      if (event.target.value) {
        // localStorage.setItem("pickupapt",event.target.value);
      }
    }

    if (event.target.name == 'extra_work') {
      // alert('123')
      if (event.target.value) {
        let data = {
          session: ReactSession.get('session'),
          extra_work: event.target.value
        };
        // localStorage.setItem("extrawork2",event.target.value)

        props.setLoading(true);
        addPickUp(data).then((res) => {
          // getTotal(ReactSession.get('session'))
          // .then((newtotal) => {
          //     // console.log(newtotal)
          //     props.setTotal(newtotal?.data?.session_cart_total);
          //   })
          getTotalCart(ReactSession.get('session')).then((res) => {
            // console.log(res);
            const getboxcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 1;
            });
            if (getboxcart) {
              props.setBox(getboxcart[0]);
            }
            const getpackingcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 2;
            });

            if (getpackingcart) {
              props.setPackings(getpackingcart);
            }
            const getmovingcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 3;
            });
            if (getmovingcart) {
              props.setMovings(getmovingcart);
            }

            props.setSelectedRental(getboxcart[0].rental_int + ' Weeks');
            props.setRentalId(getboxcart[0].rental);
            getTotal(ReactSession.get('session')).then((newtotal) => {
              // console.log(newtotal);
              props.setTotal(newtotal?.data?.session_cart_total);
              //console.log(newtotal)
              props.setStairevevators(newtotal?.data?.extra_cost);
              props.setLoading(false);
            });
          });
        });
      }
    }

    if (event.target.name == 'description') {
      if (event.target.value) {
        // localStorage.setItem("pickupdescription",event.target.value)
      }
    }
  };

  const setaddress = (place) => {
    // const setaddress = () => {
    setNewAddress(place?.formatted_address);


    //Address Logic
    let postal
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes("postal_code"))
        postal = address_element.long_name;
      // if(address_element.types.includes("country"))
      //   country = address_element.short_name;
      // if(address_element.types.includes("locality"))
      //   city = address_element.long_name;
      // if(address_element.types.includes("administrative_area_level_1"))
      //   state = address_element.long_name
    })

    const data = {
      deliveryzipcode: postal
      // deliveryzipcode:  "360002"
    };
    // console.log(delivery)
    dispatch(changePickupFormValidation({ pickup_address: true }))
    checkZipCode(data).then((res) => {
      if (res?.data?.success === true) {
        setError(true);
        setMsg1(res?.data?.message_2);
        dispatch(changePickupFormValidation({ no_error: true }))
        setMsg2('');
      } else {
        setError(true);
        setMsg2(res?.data?.message_2);
        dispatch(changePickupFormValidation({ no_error: false }))
        setMsg1('');
      }
    });
  };

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      //  console.log(pickup)
      let data = {
        session: ReactSession.get('session'),
        pickup_date: props.getPickupdate,
        pickup_address: getNewAddress,
        latitude: props.pickup?.latitude,
        longitude: props.pickup?.longitude,
        apt_number: props.pickup?.apt_number,
        description: props.pickup?.description,
        pickup_window_1: props.pickup?.pickup_window1,
        pickup_window_2: props.pickup?.pickup_window2,
        extra_work: props.pickup?.extra_work
      };
      addPickUp(data).then((res) => {
        step5Btn();
      });

      step5Btn();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const callApi = () => {
    rentalsPeriods()
      .then((res) => {
        setRentalList(res?.data);
      })
      .catch((e) => console.log(e));

    getDeliverWindows()
      .then((res) => {
        setWindows(res?.data.results);
        setWindows1(res?.data.results);
        setWindows2(res?.data.results);
      })
      .catch((e) => console.log(e));

    getDeliverWindows()
      .then((res) => {
        setWindows2(res?.data.results);
      })
      .catch((e) => console.log(e));

    getExtraWork()
      .then((res) => {
        setExtraWork(res?.data);
      })
      .catch((e) => console.log(e));
  };

  React.useEffect(() => {
    callApi();
  }, []);

  const formateddate = moment(props.getPickupdate).format('dddd');
  const monthformatdate = moment(props.getPickupdate).format('MMMM D, YYYY');

  return (
    <>
      <div className="step5" id="step5">
        <h2 className="bg-primary text-white text-center py-2">Step 5: Pickup Details</h2>
        <div className="row">
          <div className="container px-5 my-4">
            {/* <div className="row py-4"> */}

            <form>
              <br></br>
              <div className="pickupdiv">
                <div className="pickupdetail">
                  <h5>Pickup Details</h5>
                </div>
                <div className="pickupdate">
                  <h5>
                    Your pick up date is:{' '}
                    <span style={{ color: '#007BFF' }}>
                      {
                        formateddate == "Invalid date" ?
                          <Loading /> :
                          `${formateddate} ${monthformatdate}`
                      }
                      ,
                    </span>
                  </h5>
                  <br />
                  <p>After placing the order, contact us if you need to change the pickup date</p>
                </div>
              </div>
              {/* <label >Pickup Date:</label> */}
              <hr />
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Pick Up Window 1</label>
                  <select
                    className="form-control"
                    name="pickup_window1"
                    value={props.pickup.pickup_window1}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select a time slot</option>
                    {windows1.map((obj, i) => {
                      return (
                        <option
                          key={i}
                          value={obj.id}>{`${obj.start_time} - ${obj.end_time}`}</option>
                      );
                    })}
                  </select>
                  {validator.current.message(
                    'Pickup Window1',
                    props.pickup.pickup_window1,
                    'required',
                    {
                      className: 'text-danger'
                    }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Pick Up Window 2</label>
                  <select
                    className="form-control"
                    name="pickup_window2"
                    value={props.pickup.delivery_window2}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select a time slot</option>
                    {windows2
                      ?.filter((e) => e.id !== parseInt(props.pickup.pickup_window1))
                      .map((obj, i) => {
                        return (
                          <option
                            key={i}
                            value={obj.id}>{`${obj.start_time} - ${obj.end_time}`}</option>
                        );
                      })}
                  </select>

                  {validator.current.message(
                    'Pickup Window2',
                    props.pickup.pickup_window2,
                    'required',
                    {
                      className: 'text-danger'
                    }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Pick Up Address</label>

                  <Autocomplete
                    className="form-control"
                    name="pickup_address"
                    apiKey={key}
                    onPlaceSelected={(place) => {
                      setaddress(place);
                    }}
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'us' }
                    }}
                  />
                  {isError ? <p style={{ color: 'green' }}>{errorMsg1} </p> : ''}
                  {isError ? <p style={{ color: 'red' }}>{errorMsg2} </p> : ''}
                  {validator.current.message(
                    'Pick Up Address',
                    props.pickup.pickup_address,
                    'not_in',
                    {
                      className: 'text-danger'
                    }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label>Apt</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apt"
                    name="apt_number"
                    value={props.pickup.apt}
                    onChange={(e) => handleChange(e)}
                    required
                  />

                  {validator.current.message('apt', props.pickup.apt_number, 'not_in', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-12 mt-3">
                  {/* <label className={styles.required}>Do you have stairs or an elevator?</label> */}
                  <select
                    className="form-control"
                    name="extra_work"
                    value={props.pickup.extra_work}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select an option</option>;
                    {extra_work.results?.map((obj, i) => {
                      return (
                        <option key={i} value={obj.id}>{`${obj.title} ($${obj.price})`}</option>
                      );
                    })}
                  </select>

                  {validator.current.message(
                    'stairs or an elevator',
                    props.pickup.extra_work,
                    'required',
                    { className: 'text-danger' }
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label>Is there anything else we should know?</label>
                  <textarea
                    className="form-control"
                    name="description"
                    // value={localStorage.getItem("pickupdescription") ? localStorage.getItem("pickupdescription") : pickup.description}
                    value={props.pickup.description}
                    onChange={(e) => handleChange(e)}></textarea>
                </div>
              </div>
            </form>
            {/* </div> */}

            <div className="row">
              <div className="col-6">
                <div className="text-left mt-4">
                  <button className="btn btn-dark step5BackBtn" onClick={step5BackBtn}>
                    Previous Step
                  </button>
                </div>
              </div>
              <div className="col-6">
                <div className="text-right mt-4">
                  <button
                    className="btn btn-dark step5Btn"
                    // onClick={step5Btn}
                    onClick={(e) => {
                      step5Btn();
                      handleDeliverySubmit(e);
                    }}
                    disabled={disableSubmit}>
                    Step 6: Personal & Payment Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Step5.propTypes = {
  delivery: PropTypes.object,
  setDelivery: PropTypes.func,
  setMovings: PropTypes.func,
  setPackings: PropTypes.func,
  setBox: PropTypes.func,
  pickup: PropTypes.any,
  setPickup: PropTypes.func,
  setRentalId: PropTypes.func,
  rentalid: PropTypes.any,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  select_rental: PropTypes.any,
  setTotal: PropTypes.func,
  setSelectedRental: PropTypes.func,
  setStairevevators: PropTypes.func,
  getStairevevators: PropTypes.any,
  spinner: PropTypes.bool,
  setSpinner: PropTypes.func,
  getPickupdate: PropTypes.any,
  setPickupdate: PropTypes.func,
  category: PropTypes.any,
  setCategory: PropTypes.func,
  address: PropTypes.any,
  setAddress: PropTypes.func,
  getDeliverydate: PropTypes.any,
  setDeliverydate: PropTypes.func,
  pickupslot1: PropTypes.array,
  setPickupslot1: PropTypes.func,
  pickupslot2: PropTypes.array,
  setPickupslot2: PropTypes.func
};
