import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.css';
import { Button, Collapse, message } from 'antd';
import Account from './Account';
import Inventory from './Inventory';
import { getCustomerAddress, getExtraWork } from '../../../data/API';
import ComingSoon from '../../ComingSoon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useInventory from '../../../hooks/useInventory';
import { resetStore } from '../store/actionCreators';
import { useDispatch } from 'react-redux';

const Dashboard = (props) => {
  const [searchParams] = useSearchParams();
  const tabId = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState('account');
  const [customerData, setCustomerData] = useState(null);
  const [extraWork, setExtraWork] = useState(null);
  const navigate = useNavigate();
  const { inventoryList } = useInventory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(tabId) {
      if(tabId == "account") {
        dispatch(resetStore())
      }
      setActiveTab(tabId)
    }
  }, [tabId])

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
    getUserData();
    getExtarworkInfo();

    // dispatch(resetStore())
  }, []);

  const updateURL = (value) => {
    setActiveTab(value)
    const currentURL = window.location.pathname;
    const separator = currentURL.includes('?') ? '&' : '?';

    const updatedURL = currentURL + separator + 'tab=' + value;
    navigate(updatedURL);
  }

  const getUserData = async () => {
    let response = await getCustomerAddress();

    if(response?.data?.success) {
      setCustomerData(response?.data.data)
    }
  }

  const getExtarworkInfo = async () => {
    try {
      let response = await getExtraWork();
      if (response?.data) {
        setExtraWork(response?.data.results)
      }
    } catch (err) {
      return message.error('error fetching extra work info windows', err);
    }
  }

  return (
    <Container fluid={true} className="p-0">
      <Row className="align-items-center justify-content-center top-bar">
        <Col
          xxl={3}
          xl={3}
          lg={4}
          md={6}
          sm={6}
          xs={6}
          className={`h-100 ${
            activeTab == 'account' ? 'bar-active' : ''
          } p-0 text-center tab-item d-flex justify-content-center align-items-center cursor-pointer`}
          onClick={() => updateURL('account')}>
          <p className="mb-0 text-white tab-title">
            {' '}
            <i className="fa fa-user-circle mr-2"></i> Account
          </p>
        </Col>
        <Col
          xxl={3}
          xl={3}
          lg={4}
          md={6}
          sm={6}
          xs={6}
          className={`h-100 ${
            activeTab == 'inventory' ? 'bar-active' : ''
          } p-0 text-center tab-item d-flex justify-content-center align-items-center cursor-pointer`}
          onClick={() => updateURL('inventory')}>
          <p className="mb-0 text-white tab-title">
            {' '}
            <i className="fas fa-sitemap mr-2"></i> Inventory
          </p>
        </Col>
      </Row>

      <div className='tab-content'>
        { activeTab == 'account' ? <Account customerData={customerData} getUserData={getUserData} extraWork={extraWork} /> : <Inventory inventoryList={inventoryList} />} {/*<ComingSoon /> */}
      </div>

      <div className='need-more py-4'>
        <Row className='justify-content-center align-items-center'>
          <Col xxl={4} xl={4} lg={5} md={6} sm={12} xs={12} className="text-center" >
            <p className='mb-0 pb-1 need-more-title fw-600 py-4'>Need to store more?</p>
            <Button type='primary' size='large' className='fw-600 more-btn' onClick={() => navigate("/storage-box/buying-box")}>Order more boxes</Button>

            <p className='fw-400 redirect-to pt-3 cursor-pointer' onClick={() => navigate("/packages")}>Or check out our moving boxes rental service</p>
          </Col>
        </Row>
      </div>
      
    </Container>
  );
};

export default Dashboard;

Dashboard.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
