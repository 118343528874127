import { combineReducers } from 'redux';

import { reducer as BoxPackageReducer } from "../views/BoxPackages/store/index";
import { reducer as PackageReducer } from "../views/Packages/store/index";
import { reducer as StorageReducer } from "../views/StorageBox/store/index";
import { authReducer as AuthReducer } from "../views/StorageBox/store/index";
import { reducer as GlobalReducer} from "../views/layout/store/index";
import { reducer as BlogReducer} from "../views/Blog/store/index";


import {REHYDRATE, RESET_STATE} from './action';

const appReducer = combineReducers({
  boxPackage: BoxPackageReducer,
  package: PackageReducer,
  storage: StorageReducer,
  auth: AuthReducer,
  global:GlobalReducer,
    blog:BlogReducer

});


const initialState = appReducer({}, {})

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    return state;
  }
  if(action.type === REHYDRATE) {
    return state
  }

  return appReducer(state, action);
};

export default rootReducer;