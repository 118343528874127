import React, {useState, useRef, useEffect} from 'react';
import BlogCard from './components/BlogCard';
import {getAllBlogs} from "../../data/API";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setBlogsData} from "./store/actionCreators";
import PaginationComponent from "../Pagination";
export default function Blog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages,setTotalPages] = useState(0);
  const limit =4 ;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {blogs ,total} = useSelector((state) => state.blog);
  const  fetchBlogs = async(page,limit) => {
    try {
      const {data} = await getAllBlogs(page,limit);
      if(data?.success){
        dispatch(setBlogsData(data?.data))
      }else{
        dispatch({ type: 'RESET_REDUX_STORE' });
        navigate("/404")
      }
    } catch (error) {
      dispatch({ type: 'RESET_REDUX_STORE' });
      navigate('/404');
    }
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true)
    fetchBlogs(currentPage,limit);
  }, [currentPage]);

  useEffect(() => {
    setTotalPages( Math.ceil(total / limit))
  },[total])
  return (
    <>
      <section className="hero blog position-relative">
        <div className="container">
          <div className="text-center">
            <h1
              className="text-white">
              <p>BlueBox Blog</p>
            </h1>
          </div>
        </div>
      </section>

      <section className="blogsModule">
        <div className="container">
          <div className="row">
            {!loading && blogs.length > 0 && blogs?.map((blog,index) =>
              (<div className="col-md-6" key={index}>
                    <BlogCard item ={blog}/>
              </div>)
            )}

          </div>
          {loading &&<div className="d-flex justify-content-center mt-20"><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div><div className="spinner-grow text-primary mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div></div>}
        <div className="d-flex justify-content-end">
          <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
          />
        </div>
        </div>
      </section>
    </>
  );
}
