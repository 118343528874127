import { useState, useEffect, useRef } from 'react';
import MasterForm from './components/MasterForm';
import { getBoxPackages, clearCart } from '../../data/API';
import Tabs from './components/Tabs';
import uuid from 'react-uuid';
import { ReactSession } from 'react-client-session';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory, setRentalPeriod } from './store/actionCreators';

// ReactSession.setStoreType('localStorage');

// if (ReactSession.get('session')) {
//   const sessionid = ReactSession.get('session');
//   ReactSession.set('session', sessionid);
// } else {
//   const sessionid = uuid();
//   ReactSession.set('session', sessionid);
// }

export default function MovingBox(props) {
  const tabRef = useRef(null);

  const [box, setBox] = useState({});
  const [packings, setPackings] = useState([]);
  const [movings, setMovings] = useState([]);
  const [total, setTotal] = useState([]);

  const [header, setHeader] = useState(true);
  const [rentalid, setRentalId] = useState('781ce70c-ba18-46b1-b585-57dda9f3c778');
  const [getDeliverydate, setDeliverydate] = useState(['']);

  // const [category, setCategory] = useState('Home');
  const [products, setProducts] = useState([]);
  const [sub_category, setSubCate] = useState('Box Packages');
  const [select_rental, setSelectedRental] = useState(null);
  const [delivetslot1, setDelivetslot1] = useState([]);
  const [delivetslot2, setDelivetslot2] = useState([]);
  const [pickupslot1, setPickupslot1] = useState([]);
  const [pickupslot2, setPickupslot2] = useState([]);
  const [tax, setTax] = useState('');
  const [card, setCard] = useState('');
  const [orderid, setOrderid] = useState('');
  // const [getNewAddress, setNewAddress] = useState([]);
  const category = useSelector(state => state.boxPackage.category);
  const rental_period = useSelector(state => state.boxPackage.rental_box.rental_period);
  const dispatch = useDispatch();

  const [address, setAddress] = useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    state: '',
    postal: ''
  });

  const setnewcategory = (newcat) => {
    dispatch(setCategory(newcat));
  };

  useEffect(()=>{
    localStorage.setItem('tab', category);

    getBoxPackages(category, 'Box Packages', `${rental_period=="1 Week"?1:2}`)
      .then((res) => {
        clearCart(ReactSession.get('session'));
        setBox('');
        setProducts(res?.data);
        setSelectedRental(res.data.results[0].period);
        dispatch(setRentalPeriod(res.data.results[0].period))
      })
      .catch((e) => console.log(e));
    
  }, [category])

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(true);
    props.setshowHideinnerFooter(false);
  }, []);

  return (
    <>
      <Tabs tabRef={tabRef} />
      <section className="show_btn" id="show_btn">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5">
              <h2>Select Your Package</h2>
              <p>All packages include everything you need for an easy, stress-free move.</p>
            </div>
            <div className="col-md-12">
              <button
                className={`rounded-right ${category === 'Home' ? 'active' : ''} homebtn`}
                id="homebtn"
                value="Home"
                onClick={(e) => setnewcategory('Home')}>
                HOME
              </button>
              <button
                className={`rounded-left ${category === 'Office' ? 'active' : ''} officebtn`}
                id="officebtn"
                value="Office"
                onClick={(e) => setnewcategory('Office')}>
                OFFICE
              </button>
            </div>
          </div>
        </div>
      </section>
      <MasterForm
        tabRef={tabRef}
        box={box} setBox={setBox}
        packings={packings} packing_products={packings} setPackings={setPackings}
        moving_products={movings} movings={movings} setMovings={setMovings}
        total={total} setTotal={setTotal}
        rentalid={rentalid} setRentalId={setRentalId}
        category={category} setCategory={setCategory}
        products={products} setProducts={setProducts}
        sub_category={sub_category} setSubCate={setSubCate}
        select_rental={select_rental} setSelectedRental={setSelectedRental}
        address={address} setAddress={setAddress}
        header={header} setHeader={setHeader}
        tax={tax} setTax={setTax}
        delivetslot1={delivetslot1} setDelivetslot1={setDelivetslot1}
        delivetslot2={delivetslot2} setDelivetslot2={setDelivetslot2}
        getDeliverydate={getDeliverydate} setDeliverydate={setDeliverydate}
        pickupslot1={pickupslot1} setPickupslot1={setPickupslot1}
        pickupslot2={pickupslot2} setPickupslot2={setPickupslot2}
        card={card} setCard={setCard}
        orderid={orderid} setOrderid={setOrderid}
        // getNewAddress={getNewAddress}
        // setNewAddress={setNewAddress}
      />
    </>
  );
}

MovingBox.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func,
  showHideHeader: PropTypes.any,
  showHideFooter: PropTypes.any,
  showHideinnerFooter: PropTypes.any
};
