import { useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import PaymentApp from '../../payment';
import { ReactSession } from 'react-client-session';
import { getCoupon, getTotal, getHdyfu } from '../../../data/API';
import PropTypes from 'prop-types';
import styles from "./styles.module.css"

export default function Step6(props) {
  const [hdyfu, sethdyfu] = useState([]);

  const validator = useRef(new SimpleReactValidator({}));

  // validators: {
  //   ip: {  // name the rule
  //     message: 'The :attribute must be a valid IP address and must be :values.',
  //     rule: (val, params, validator) => {
  //       return validator.helpers.testRegex(val,/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i) && params.indexOf(val) === -1
  //     },
  //     messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
  //     required: true  // optional
  //   }
  // }

  const {
    delivery_detail,
    pickup_detail,
    total,
    getDiscountprice,
    address,
    card,
    setCard,
    orderid,
    setOrderid,
    details,
    setDetails,
    pickupdetails,
    setPickupdetails,
    personaldetails,
    setPersonal,
    setPreview,
    preview,
    setOrderComplete
  } = props;
  const [personal, setPersonalDetails] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
    secondary_phone_number: '',
    company_name: '',
    voucher_code: '',
    hdyfu: '',
    session: ReactSession.get('session')
  });
  const [isError, setError] = useState(false);
  const [errorMsg1, setMsg1] = useState('');
  const [errorMsg2, setMsg2] = useState('');

  const handleChange = (event) => {
    setPersonalDetails({
      ...personal,
      [event.target.name]: event.target.value
    });

    
  };

  const handleCoupon = (event) =>{
    if (
      validator.current.fieldValid('first_name') &&
      validator.current.fieldValid('last_name') &&
      validator.current.fieldValid('email') &&
      validator.current.fieldValid('phone')
    ) {
        if (event.target.value) {
          setPersonalDetails({
            ...personal,
            voucher_code: event.target.value
          });

          const coupon = event.target.value;
          const email = personal.email_address;

          getCoupon(ReactSession.get('session'), coupon, email).then((res) => {

            if (res?.data?.success === 'true') {
              setError(false);
              setMsg2('');
              props.setTotal(res?.data?.discounted_session_total);
              props.setDiscountprice(res?.data?.discount);
              setMsg1(res?.data?.msg);
              setMsg2('');
            } else {
              setError(true);
              setMsg2(res?.data?.msg);
              setMsg1('');
              props.setDiscountprice(0);
            }
          });
        }
    } else {
      setError(true);
      setMsg2('Please enter all details to apply coupon');
    }
  }

  const handleCouponChange = () =>{
    if (
      validator.current.fieldValid('first_name') &&
      validator.current.fieldValid('last_name') &&
      validator.current.fieldValid('email') &&
      validator.current.fieldValid('phone')
    ){
      setPersonalDetails({
        ...personal,
        voucher_code: event.target.value
      });
    }
    else {
      setError(true);
      setMsg2('Please enter all details to apply coupon');
    }
  }

  const callApi = () => {
    getHdyfu()
      .then((res) => {
        sethdyfu(res.data);
      })
      .catch((e) => console.log(e));
  };
 
  useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      <div className="step6" id="step6">
        <h2 className="bg-primary text-white text-center py-2">
          Step 6: Personal and Payment Details
        </h2>
        <div className="row">
          <div className="container px-5 my-4">
            <div className="row py-4">
              <h5>Add Personal Details</h5>
              <hr />
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>First Name</label>

                  <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    placeholder=""
                    name="first_name"
                    autoComplete="off"
                    value={personal.first_name}
                    onChange={(e) => {handleChange(e); validator.current.showMessageFor('first_name')}}
                    onBlur={() => validator.current.showMessageFor('first_name')}
                  />
                  {validator.current.message('first_name', personal.first_name, 'required', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-control"
                    placeholder=""
                    name="last_name"
                    autoComplete="off"
                    value={personal.last_name}
                    onChange={(e) => {handleChange(e); validator.current.showMessageFor('last_name')}}
                    onBlur={() => validator.current.showMessageFor('last_name')}
                  />
                  {validator.current.message('last_name', personal.last_name, 'required', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Email Address</label>
                  <input
                    type="email"
                    id="email_address"
                    className="form-control"
                    placeholder=""
                    name="email_address"
                    autoComplete="off"
                    value={personal.email_address}
                    onChange={(e) => {handleChange(e); validator.current.showMessageFor('email')}}
                    onBlur={() => validator.current.showMessageFor('email')}
                  />
                  {validator.current.message('email', personal.email_address, 'required|email', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>
                    Mobile Number
                    <span className="detailsnote">
                      {' '}
                      (Notifications will be sent to this number)
                    </span>
                  </label>
                  <input
                    type="text"
                    id="phone_number"
                    className="form-control"
                    placeholder=""
                    name="phone_number"
                    autoComplete="off"
                    maxLength={10}
                    value={personal.phone_number}
                    onChange={(e) => {handleChange(e); validator.current.showMessageFor('phone')}}
                    onBlur={() => validator.current.showMessageFor('phone')}
                  />
                  {validator.current.message('phone', personal.phone_number, 'required|phone', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-6 mt-3">
                  <label>Secondary Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    maxLength={10}
                    name="secondary_phone_number"
                    value={personal.secondary_phone_number}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {localStorage.getItem('tab') == 'Office' ? (
                  <div className="col-md-6 mt-3">
                    <label className={styles.required}>Company Name (Office Orders Only)</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder=""
                      name="company_name"
                      value={personal.company_name}
                      onChange={(e) => {handleChange(e); validator.current.showMessageFor('company_name')}}
                      onBlur={() => validator.current.showMessageFor('company_name')}
                    />

                    {validator.current.message('company_name', personal.company_name, 'required', {
                      className: 'text-danger'
                    })}
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-6 mt-3">
                  <div className="buttonIn">
                    <label>If you have a coupon code, enter it below</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Coupon Code"
                      name="voucher_code"
                      autoComplete="off"
                      value={personal.voucher_code}
                      onChange={handleCouponChange}
                      onBlur={(e) => handleCoupon(e)}
                    />
                  </div>
                  {personal.voucher_code.length>0 && isError ? <p style={{ color: 'green' }}>{errorMsg1} </p> : ''}
                  {personal.voucher_code.length>0 && isError ? <p style={{ color: 'red' }}>{errorMsg2} </p> : ''}
                </div>

                <div className="col-md-6 mt-3">
                  <label className={styles.required}>How did you hear about us?</label>
                  <select
                    className="form-control"
                    name="hdyfu"
                    value={personal.hdyfu}
                    onChange={(e) => {handleChange(e); validator.current.showMessageFor('above')}}
                    onBlur={() => validator.current.showMessageFor('above')}
                  >
                    <option value="">Select Type</option>
                    {hdyfu.results?.map((obj, i) => {
                      return (
                        <option key={i} value={obj.id}>
                          {obj.title}
                        </option>
                      );
                    })}
                  </select>
                  {validator.current.message('above', personal.hdyfu, 'required', {
                    className: 'text-danger'
                  })}
                </div>
              </div>
              <br></br>

              <h5 style={{ marginTop: '20px' }}>Enter Payment Details</h5>
              <hr />

              <PaymentApp
                delivery_detail={delivery_detail}
                pickup_detail={pickup_detail}
                personal={personal}
                total={total}
                discount={getDiscountprice}
                address={address}
                card={card} setCard={setCard}
                orderid={orderid} setOrderid={setOrderid}
                details={details} setDetails={setDetails}
                previewloader={props.previewloader} setPreviewloader={props.setPreviewloader}
                pickupdetails={pickupdetails} setPickupdetails={setPickupdetails}
                personaldetails={personaldetails} setPersonal={setPersonal}
                preview={preview} setPreview={setPreview}
                validator={validator}
                setOrderComplete={setOrderComplete}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Step6.propTypes = {
  delivery_detail: PropTypes.any,
  pickup_detail: PropTypes.object,
  total: PropTypes.any,
  setTotal: PropTypes.func,
  getDiscountprice: PropTypes.any,
  setDiscountprice: PropTypes.func,
  category: PropTypes.any,
  setCategory: PropTypes.func,
  address: PropTypes.any,
  setAddress: PropTypes.func,
  card: PropTypes.any,
  setPreviewloader: PropTypes.func,
  previewloader: PropTypes.any,
  setCard: PropTypes.func,
  orderid: PropTypes.any,
  setOrderid: PropTypes.func,
  details: PropTypes.any,
  setDetails: PropTypes.func,
  pickupdetails: PropTypes.any,
  setPickupdetails: PropTypes.func,
  personaldetails: PropTypes.any,
  setPersonal: PropTypes.func,
  setPreview: PropTypes.func,
  preview: PropTypes.any,
  setOrderComplete: PropTypes.func
};
