import React from 'react';
import loader from '../loader/newloader.gif';

const Previewloader = () => (
  <div className="Previewloader">
    <div id="cover-spin"></div>
    {/* <img src={loader} alt="loading..." width='100' height='100'/> */}
  </div>
);

export default Previewloader;
