import React, { useEffect, useState } from 'react';
import OrderSummary from './OrderSummary';
import { Col, Row } from 'react-bootstrap';
import { Button, message } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import * as $ from 'jquery';
import 'react-spring-bottom-sheet/dist/style.css';
import { BottomSheet } from 'react-spring-bottom-sheet';
import DeliveryDetails from './DeliveryDetails';
import PaymentInfo from './PaymentInfo';
import PropTypes from 'prop-types';
import { getCustomer, getDeliverWindows, getExtraWork } from '../../../../data/API';
import useGetPrice from '../../../../hooks/useGetPrice';

const ReqDeliveryDetails = () => {
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false);
  const [deliveryWindows, setDeliveryWindows] = useState([]);
  const [extraWork, setExtraWork] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const { boxPrices } = useGetPrice()

  const [pageType, setPageType] = useState('delivery');

  const [stepRef, setStepRef] = useState(0);

  const getDeliverySlots = async () => {
    try {
      let response = await getDeliverWindows();
      if (response?.data) {
        setDeliveryWindows(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching delivery windows', err);
    }
  };

  const getExtarworkInfo = async () => {
    try {
      let response = await getExtraWork();
      if (response?.data) {
        setExtraWork(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching extra work info windows', err);
    }
  };

  const getCustomerData = async () => {
    try {
      let response = await getCustomer();
      if (response?.data) {
        setCustomerInfo(response?.data?.data);
      }
    } catch (err) {
      return message.error('error fetching customer data', err);
    }
  };

  useEffect(() => {
    getDeliverySlots()
    getExtarworkInfo()
    getCustomerData()
  }, []);

  const showOrderSummary = () => {
    setOrderSummaryOpen(true);
    $('.ant-card-body').trigger('click');
  };

  const hideOrderSummary = () => {
    setOrderSummaryOpen(false);
  };

  const steps = {
    delivery: <DeliveryDetails boxPrices={boxPrices} deliveryWindows={deliveryWindows} extraWork={extraWork} customerInfo={customerInfo} />,
    payment: <PaymentInfo />
  };

  return (
    <div className="m-0">
      <Row className="row d-flex fex-row">
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <section className="showTabs pb-0 mb-4 pl-3 pr-3 ">
            <div className="container">
              <div className="row d-flex justify-content-end">
                <div className="col-lg-11 p-0 ">{steps[pageType]}</div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 p-0 order-summary mb-10">
          <section className="showTabs pb-0 order-summary-section">
            <div className="container order-summary-container pr-0">
              <div className="row d-flex mr-0">
                <div className="col-lg-11 p-0 bg-white summary-card order-summary-desktop">
                  <OrderSummary boxPrices={boxPrices} setOrderSummaryOpen={setOrderSummaryOpen} deliveryWindows={deliveryWindows} extraWork={extraWork}/>
                </div>
                <div className="order-summary-mt">
                  <Button
                    icon={<UpOutlined />}
                    onClick={showOrderSummary}
                    className="btn  bg-theme-secondary order-summary-mt-btn "
                    disabled={false}>
                    Order Summary
                  </Button>

                  <BottomSheet
                    open={orderSummaryOpen}
                    blocking={false}
                    data-body-scroll-lock-ignore="true">
                    <div className="order-summary-mt-header" onClick={hideOrderSummary}>
                      <p className="mb-0 ml-4 pt-3 pb-3 title">
                        {' '}
                        <i className="fa fa-chevron-down mr-2"></i> Order Summary
                      </p>
                    </div>
                    <OrderSummary boxPrices={boxPrices} setOrderSummaryOpen={setOrderSummaryOpen} deliveryWindows={deliveryWindows} extraWork={extraWork}/>
                  </BottomSheet>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Row>
    </div>
  );
};

export default ReqDeliveryDetails;

ReqDeliveryDetails.propTypes = {};
