import { ClockCircleOutlined } from '@ant-design/icons';
import { Image, Timeline } from 'antd';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './index.css';
import FreeImg from '../../../../assets/images/free_delivery_circle.png';

const HiwSteps = () => {
  return (
    <Container className="py-5 signup-form-bg" fluid={true}>
      <Row className="justify-content-center">
        <Col xxl={7} xl={7} lg={7} md={8} sm={10} xs={11}>
          <div className="hiw-steps-content">
            <p className="mb-0 color-primary hiw-steps-header fw-600">How it works</p>
            <p className="s-color-black hiw-steps-sub-header fw-400 head-width">
              Gone are the days of renting a storage unit and lugging things back and forth. We’ll
              do that for you.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xxl={5} xl={5} lg={6} md={8} sm={10} xs={11} className="py-5 hiw-timeline">
          <ul className="timeline-items">
            <li>
              <p className='mb-0 color-primary hiw-timeline-header fw-600'>Step 1: Request Storage Boxes</p>
              <p className='s-color-black hiw-timeline-sub-header fw-400'>We’ll provide free delivery of our clean, plastic storage boxes right to your door.</p>
            </li>
            <li className='second'>
              <p className='mb-0 color-primary hiw-timeline-header fw-600'>Step 2: Pack Your Stuff</p>
              <p className='s-color-black hiw-timeline-sub-header fw-400'>Pack your items into the boxes. Think of us as an extension of your home or office.</p>
            </li>
            <li className='third'>
              <p className='mb-0 color-primary hiw-timeline-header fw-600'>Step 3: Schedule a Pick-Up</p>
              <p className='s-color-black hiw-timeline-sub-header fw-400'>We’ll pick up your boxes and our driver will take an overhead photo of each open box for you to inventory once uploaded to your account.</p>
            </li>
            <li className="end">
              <p className='mb-0 color-primary hiw-timeline-header fw-600'>Step 4: Deliver</p>
              <p className='s-color-black hiw-timeline-sub-header fw-400'>Login to your online account to request a box delivery, and get your stuff back when you need it.</p>
            </li>
          </ul>
        </Col>
      </Row>
      
    </Container>
  );
};

export default HiwSteps;
