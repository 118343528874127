import React, { useState, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  rentalsPeriods,
  getBoxPackages,
  addtoCart,
  getCart,
  getTotal,
  getnewRental,
  getTotalCart,
  getDeliverSlots,
  getPickupSlots
} from '../../../data/API';
import { ReactSession } from 'react-client-session';
import Mainloader from './Mainloader';
import { useDispatch, useSelector } from 'react-redux';
import { setRentalId, setRentalPeriod } from '../store/actionCreators';

function step1Btn(tabRef) {
  window.scrollTo({
    top: tabRef.current.offsetTop - 100,
    behavior: 'smooth'
  });
  document.getElementById('hideme').style.display = 'block';
  document.getElementById('step1').style.display = 'none';
  document.getElementById('step2').style.display = 'block';
  document.getElementById('step2').classList.add += ' disabled';
  document.getElementById('show_btn').style.display = 'none';
  //  document.getElementById("orderpreview").style.display = "none";

  document.getElementById('st1li').classList.remove('current');
  document.getElementById('st1li').classList.add('complete');
  document.getElementById('tabsSection').classList.add('newstp');
  document.getElementById('st2').classList.add('active');
  document.getElementById('st2li').classList.add('current');
}


export default function Step1(props) {
  // console.log(props)
  const [rentals, setRentalList] = useState([]);
  const [is_selected, setSelect] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [selected, setSelected] = useState();

  const session = ReactSession.get('session');
  const selected_rental_period = useSelector(state => state.boxPackage.rental_box.rental_period);
  const dispatch = useDispatch();

  const gotcart = async (session) => {
    getTotal(session)
      .then((res) => {

        setHidden(false);
        props.setTotal(res?.data?.session_cart_total);

        let totalsum = Number(res?.data?.session_cart_total) + Number(props.box.cart_price);
        props.setTotal(totalsum);

        props.setTax(res?.data?.tax);
        props.setStairevevators(res?.data?.extra_cost);

        getDeliverSlots(ReactSession.get('session')).then((res) => {
          if (res?.data?.count > 0 || res?.data?.results?.length > 0) {
            props.setDelivetslot1(res?.data?.results?.[0]?.delivery_window_1);
            props.setDelivetslot2(res?.data?.results?.[0]?.delivery_window_2);
            props.setDeliverydate(res?.data?.results?.[0]?.delivery_date);
          }
        });
        getPickupSlots(ReactSession.get('session')).then((res) => {
          if (res?.data?.count > 0) {
            props.setPickupslot1(res?.data?.results?.[0]?.pickup_window_1);
            props.setPickupslot2(res?.data?.results?.[0]?.pickup_window_2);
            props.setPickupdate(res?.data?.results[res?.data?.results?.length - 1]?.pickup_date);
          }
        });
      })
      .catch((e) => console.log(e));

    // if( props.setTotal > 0) {
    getTotalCart(session)
      .then((res) => {
        let rental1 = '';
        let rentalid1 = '';

        if (res.data.results.length > 0) {
          rental1 = res.data.results[res.data.results.length - 1].rental_int + ' Weeks';
          rentalid1 = res.data.results[res.data.results.length - 1].rental;
          props.setRentalId(rentalid1);
          props.setDelivery({ ...props.delivery, rental: rentalid1 });
        } else {
          rental1 = selected_rental_period;
          // rentalid1 = rentals.results[1].id;
        }

        // getBoxProducts(rental1);
        props.setSelectedRental(rental1);

        const getboxcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 1;
        });
        if (getboxcart.length > 0) {
          props.setBox(getboxcart[0]);
          props.setRentalId(getboxcart[0].rental);
          setSelected(getboxcart[0].product.id);
          setSelect(true);
          // 
          // setHidden(true);
        }
        const getpackingcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 2;
        });

        if (getpackingcart) {
          props.setPackings(getpackingcart);
          // 
          // setHidden(true);
        }
        const getmovingcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 3;
        });
        if (getmovingcart) {
          props.setMovings(getmovingcart);

          setHidden(true);
        }
      })
      .catch((e) => console.log(e));
    // }
  };

  //Get the rental periods
  const getRentals = () => {
    setHidden(false);
    rentalsPeriods()
      .then((res) => {
        setRentalList(res?.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setHidden(false);
    if (props.category && props.sub_category && selected_rental_period)
      getBoxPackages(props.category, props.sub_category, selected_rental_period)
        .then((res) => {
          props.setProducts(res?.data);
          setHidden(true);
        })
        .catch((e) => console.log(e));
  }, [selected_rental_period])


  // const getBoxProducts = async (rental = selected_rental_period) => {
  //   console.log(props.category, props.sub_category, rental)
  //   setHidden(false);
  //   getBoxPackages(props.category, props.sub_category, rental)
  //     .then((res) => {
  //       props.setProducts(res?.data);
  //       setHidden(true);
  //     })
  //     .catch((e) => console.log(e));
  // };

  function selectRentals(e, obj, rental) {
    let data = {
      session: ReactSession.get('session'),
      rental: obj.id
    };

    props.setSelectedRental(rental);
    dispatch(setRentalPeriod(rental));
    dispatch(setRentalId(obj.id));
    props.setRentalId(obj.id);
    // getBoxProducts(rental);
    props.setDelivery({ ...props.delivery, rental: obj.id });

    getnewRental(data).then((res) => {
      getTotalCart(ReactSession.get('session')).then((res) => {
        const getboxcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 1;
        });
        if (getboxcart) {
          props.setBox(getboxcart[0]);
        }
        const getpackingcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 2;
        });

        if (getpackingcart) {
          props.setPackings(getpackingcart);
        }
        const getmovingcart = res?.data?.results.filter((obj) => {
          return obj?.product?.product_sub_category === 3;
        });
        if (getmovingcart) {
          props.setMovings(getmovingcart);
        }

        getTotal(session).then((newtotal) => {
          props.setTotal(newtotal?.data?.session_cart_total);
        });
      });
    });
    // getBoxProducts(rental);
  }

  const handlePackageClick = (obj) => {
    //console.log(obj);
    let data = {
      product: obj.id,
      quantity: obj.quantity,
      session: session,
      cart_main_category: 2,
      cart_sub_category: obj.product_sub_category,
      rental: obj.period_id
    };
    // props.setLoading(true);
    setSelect(true);
    addtoCart(data)
      .then((res) => {
        // console.log(res)
        getCart(data.cart_sub_category, session).then((newdata) => {
          props.setBox(newdata.data?.results[0]);
          setSelected(obj?.id);
          getTotal(ReactSession.get('session')).then((res) => {
            props.setTotal(res?.data?.session_cart_total);
          });
        });
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  React.useEffect(() => {
    props.setMainloader(true);
    Promise.all([getRentals(), gotcart(session)]).then(() => {
      props.setMainloader(false);
    });
  }, []);
  return (
    <>
      {props.mainloader ? <Mainloader /> : ' '}

      <div className="step1" id="step1">
        <h2 className="bg-primary text-white text-center py-2">
          Step 1: Rental Period and Box Package
        </h2>
        <div className="row">
          <div className="col-2">
            <b>
              <p className="fs-14 text-center ms-xl-1">
                Select <br /> Rental Period
              </p>
            </b>
          </div>
          <div className="col-10 m-auto">
            <div className="pagination">
              {rentals.results?.map((obj) => {
                return (
                  <button
                    key={obj.id}
                    className={
                      obj.period === selected_rental_period
                        ? 'btn btn-outline-primary fs-14 p-1 active'
                        : 'btn btn-outline-primary fs-14 p-1'
                    }
                    onClick={(e) => selectRentals(e, obj, obj.period)}>
                    {obj.period}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="container px-5 my-4">
            <div className="row py-4">
              {props.products.results?.map((obj, i) => {
                return (
                  <div key={obj.id} className="col-md-4 p-0 pkg">
                    <div className="card">
                      <div className="card-header bg-danger">
                        <div className="text-center">
                          <h3 className="text-white">{obj.title}</h3>
                          <p className="text-white">{obj.total_boxes} Boxes</p>
                        </div>
                      </div>
                      <div className="bins">
                        <div className="round_box">
                          <h5>{obj.total_boxes}</h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="text-center">
                          <h2>
                            <span className="top fs-14 crs">$</span>
                            {obj.price}
                            <span className="middle fs-14">/ {obj.period}</span>
                          </h2>
                          <ul className="mt-3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: obj.description
                              }}></div>
                            <div className="d-flex justify-content-center">
                              <div className="radio-toolbar">
                                <input
                                  type="radio"
                                  id={obj.id}
                                  name="radio"
                                  value="ADD PACKAGE"
                                  onClick={() => handlePackageClick(obj)}
                                  style={{
                                    display: 'none',
                                    padding: '0 0 0 0',
                                    marginLeft: '2rem'
                                  }}
                                />

                                <label className="btn btn-danger mt-3" htmlFor={obj.id}>
                                  {selected === obj.id ? 'Selected' : 'Add Package'}
                                </label>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="text-right mt-4">
              <button
                className="btn btn-dark step1Btn"
                onClick={() => step1Btn(props.tabRef)}
                disabled={is_selected ? false : true}>
                Step 2: Select Packing Supplies
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Step1.propTypes = {
  tabRef: PropTypes.any,
  setBox: PropTypes.func,
  setPackings: PropTypes.func,
  packings: PropTypes.array,
  moving_products: PropTypes.any,
  setMovings: PropTypes.func,
  setTotal: PropTypes.func,
  box: PropTypes.any,
  setRentalId: PropTypes.func,
  rentalid: PropTypes.string,
  loading: PropTypes.any,
  setLoading: PropTypes.func,
  mainloader: PropTypes.any,
  setMainloader: PropTypes.any,
  spinner: PropTypes.any,
  setSpinner: PropTypes.func,
  select_rental: PropTypes.any,
  setSelectedRental: PropTypes.func,
  setStairevevators: PropTypes.func,
  getStairevevators: PropTypes.any,
  category: PropTypes.any,
  setCategory: PropTypes.func,
  products: PropTypes.any,
  setProducts: PropTypes.func,
  sub_category: PropTypes.any,
  setSubCate: PropTypes.func,
  tax: PropTypes.any,
  setTax: PropTypes.func,
  delivery: PropTypes.object,
  setDelivery: PropTypes.func,
  header: PropTypes.any,
  setHeader: PropTypes.func,
  getDeliverydate: PropTypes.any,
  setDeliverydate: PropTypes.func,
  getPickupdate: PropTypes.any,
  setPickupdate: PropTypes.func,
  delivetslot1: PropTypes.any,
  setDelivetslot1: PropTypes.func,
  delivetslot2: PropTypes.any,
  setDelivetslot2: PropTypes.func,
  setPickupslot1: PropTypes.func,
  pickupslot1: PropTypes.any,
  setPickupslot2: PropTypes.func,
  pickupslot2: PropTypes.any
};
