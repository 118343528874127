import { Image } from 'antd';
import React from 'react';
import BoxImg from '../../../../assets/images/box2.png';
import './index.css';

const HiwHeader = () => {
  return (
    <div 
        className='hiw-header'
    >
        <div className='d-flex justify-content-center'>
            <div className='header-content'>
                <p className='text-white text-sub-header mb-0'>Welcome to</p>
                <p className='text-white text-header mb-0'>BlueBox storage on demand!</p>
            </div>
            <div className='header-image'>
                <Image 
                    className='main-image'
                    src={BoxImg}
                    preview={false}
                    alt="storage_header_image"
                />
            </div>
        </div>
    </div>
  )
}

export default HiwHeader;
