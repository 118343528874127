import * as actionTypes from './constants';


const defaultStateValues = () => {
    return {
        user: {}
    }

}

const defaultState = defaultStateValues();

export default (state = defaultState, action) => {
    switch (action.type) {

        case actionTypes.RESET_AUTH_STORE:
            return defaultStateValues();

        case actionTypes.SET_CUSTOMER:
            return { ...state, user: action.data };



        default:
            return state;
    }
}
