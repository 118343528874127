import React, { useEffect } from 'react';
import { ReactSession } from 'react-client-session';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Index from '../index';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Orderpreview from './Orderpreview';
import Cart from './Cart';

// ReactSession.setStoreType('localStorage');

// if (ReactSession.get('session')) {
//   const sessionid = ReactSession.get('session');
//   ReactSession.set('session', sessionid);
// } else {
//   const sessionid = uuid();
//   ReactSession.set('session', sessionid);
// }

export default function MasterForm(props) {
  // const [box, setBox] = React.useState({});
  // const [packings, setPackings] = React.useState([]);
  // const [movings, setMovings] = React.useState([]);
  // const [total, setTotal] = React.useState([]);
  // const [rentalid, setRentalId] = React.useState("be7f2421-2168-45ce-ab4e-d5ed5f68ab69");

  const [loading, setLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [mainloader, setMainloader] = React.useState(false);
  const [previewloader, setPreviewloader] = React.useState(false);
  const [getDiscountprice, setDiscountprice] = React.useState([0]);
  const [getStairevevators, setStairevevators] = React.useState([0]);
  const [getPickupdate, setPickupdate] = React.useState(['']);
  const [details, setDetails] = React.useState([]);
  const [pickupdetails, setPickupdetails] = React.useState([]);
  const [personaldetails, setPersonal] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  const [orderComplete, setOrderComplete] = React.useState(false);

  //const [getDeliverydate, setDeliverydate] = React.useState();

  const [delivery, setDelivery] = React.useState({
    delivery_address: '',
    delivery_date: '',
    latitude: '',
    longitude: '',
    apt_number: '',
    description: '',
    delivery_window1: '',
    delivery_window2: '',
    extra_work: '',
    rental: '',
    rentalid: ''
  });
  const [pickup, setPickup] = React.useState({
    pickup_address: '',
    pickup_date: null,
    latitude: '',
    longitude: '',
    apt_number: '',
    description: '',
    pickup_window1: '',
    pickup_window2: '',
    extra_work: '',
    rental: ''
  });
  
  return (
    <>
      <section className="showTabs pb-5">
        <div className="container">
          <div className={`row ${orderComplete? "d-flex justify-content-center": ""} `}>
            <div className="col-lg-9 bg-white p-0">
              <Step1
                tabRef={props.tabRef}
                box={props.box} setBox={props.setBox}
                packings={props.packings} setPackings={props.setPackings}
                moving_products={props.movings} setMovings={props.setMovings}
                setTotal={props.setTotal}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                mainloader={mainloader} setMainloader={setMainloader}
                spinner={spinner} setSpinner={setSpinner}
                select_rental={props.select_rental} setSelectedRental={props.setSelectedRental}
                getStairevevators={getStairevevators} setStairevevators={setStairevevators}
                category={props.category} setCategory={props.setCategory}
                products={props.products} setProducts={props.setProducts}
                sub_category={props.sub_category} setSubCate={props.setSubCate}
                tax={props.tax} setTax={props.setTax}
                delivery={delivery} setDelivery={setDelivery}
                header={props.header} setHeader={props.setHeader}
                getDeliverydate={props.getDeliverydate} setDeliverydate={props.setDeliverydate}
                getPickupdate={getPickupdate} setPickupdate={setPickupdate}
                delivetslot1={props.delivetslot1} setDelivetslot1={props.setDelivetslot1}
                delivetslot2={props.delivetslot2} setDelivetslot2={props.setDelivetslot2}
                setPickupslot1={props.setPickupslot1} pickupslot1={props.pickupslot1}
                setPickupslot2={props.setPickupslot2} pickupslot2={props.pickupslot2}
              />
              <Step2
                tabRef={props.tabRef}
                packings={props.packings} setPackings={props.setPackings}
                setTotal={props.setTotal}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                select_rental={props.select_rental} setSelectedRental={props.setSelectedRental}
                category={props.category} setCategory={props.setCategory}
                tax={props.tax} setTax={props.setTax} 
              />
              <Step3
                tabRef={props.tabRef}
                movings={props.movings} setMovings={props.setMovings}
                setTotal={props.setTotal}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                select_rental={props.select_rental} setSelectedRental={props.setSelectedRental}
                category={props.category} setCategory={props.setCategory} 
              />
              <Step4
                tabRef={props.tabRef} 
                delivery={delivery} setDelivery={setDelivery}
                setMovings={props.setMovings}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                setPackings={props.setPackings}
                loading={loading} setLoading={setLoading}
                select_rental={props.select_rental} setSelectedRental={props.setSelectedRental}
                setBox={props.setBox}
                setTotal={props.setTotal}
                getStairevevators={getStairevevators} setStairevevators={setStairevevators}
                setProducts={props.setProducts}
                sub_category={props.sub_category}
                spinner={spinner} setSpinner={setSpinner}
                getPickupdate={getPickupdate} setPickupdate={setPickupdate}
                category={props.category} setCategory={props.setCategory}
                address={props.address} setAddress={props.setAddress}
                getDeliverydate={props.getDeliverydate} setDeliverydate={props.setDeliverydate}
                delivetslot1={props.delivetslot1} setDelivetslot1={props.setDelivetslot1}
                delivetslot2={props.delivetslot2} setDelivetslot2={props.setDelivetslot2}
                details={details} setDetails={setDetails}
                // getNewAddress={props.getNewAddress}
                // setNewAddress={props.setNewAddress}
              />
              <Step5
                tabRef={props.tabRef}
                setDelivery={setDelivery}
                setMovings={props.setMovings}
                setPackings={props.setPackings}
                setBox={props.setBox}
                pickup={pickup} setPickup={setPickup}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                select_rental={props.select_rental} setSelectedRental={props.setSelectedRental}
                setTotal={props.setTotal}
                getStairevevators={getStairevevators} setStairevevators={setStairevevators}
                spinner={spinner} setSpinner={setSpinner}
                getPickupdate={getPickupdate} setPickupdate={setPickupdate}
                category={props.category} setCategory={props.setCategory}
                address={props.address} setAddress={props.setAddress}
                getDeliverydate={props.getDeliverydate} setDeliverydate={props.setDeliverydate}
                pickupslot1={props.pickupslot1} setPickupslot1={props.setPickupslot1}
                pickupslot2={props.pickupslot2} setPickupslot2={props.setPickupslot2}
                delivery={delivery}
              />
              <Step6
                tabRef={props.tabRef}
                delivery_detail={delivery}
                pickup_detail={pickup}
                total={props.total} setTotal={props.setTotal}
                getDiscountprice={getDiscountprice} setDiscountprice={setDiscountprice}
                category={props.category} setCategory={props.setCategory}
                address={props.address} setAddress={props.setAddress}
                card={props.card} setCard={props.setCard}
                previewloader={previewloader} setPreviewloader={setPreviewloader}
                orderid={props.orderid} setOrderid={props.setOrderid}
                details={details} setDetails={setDetails}
                pickupdetails={pickupdetails} setPickupdetails={setPickupdetails}
                setPersonal={setPersonal} personaldetails={personaldetails}
                preview={preview} setPreview={setPreview}
                setOrderComplete={setOrderComplete}
              />
              <Orderpreview 
                orderComplete={orderComplete}
                setOrderComplete={setOrderComplete}
                box={props.box}
                packing_products={props.packings}
                moving_products={props.movings} setMovings={props.setMovings}
                packings={props.packings} setPackings={props.setPackings}
                total={props.total} setTotal={props.setTotal}
                setBox={props.setBox}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                previewloader={previewloader} setPreviewloader={setPreviewloader}
                getDiscountprice={getDiscountprice} setDiscountprice={setDiscountprice}
                getStairevevators={getStairevevators} setStairevevators={setStairevevators}
                category={props.category} setCategory={props.setCategory}
                getPickupdate={getPickupdate} setPickupdate={setPickupdate}
                getDeliverydate={props.getDeliverydate} setDeliverydate={props.setDeliverydate}
                tax={props.tax} setTax={props.setTax}
                delivetslot1={props.delivetslot1} setDelivetslot1={props.setDelivetslot1}
                delivetslot2={props.delivetslot2} setDelivetslot2={props.setDelivetslot2}
                pickupslot1={props.pickupslot1} setPickupslot1={props.setPickupslot1}
                pickupslot2={props.pickupslot2} setPickupslot2={props.setPickupslot2}
                card={props.card} setCard={props.setCard}
                orderid={props.orderid} setOrderid={props.setOrderid}
                details={details} setDetails={setDetails}
                pickupdetails={pickupdetails} setPickupdetails={setPickupdetails}
                personaldetails={personaldetails} setPersonal={setPersonal}
                preview={preview} setPreview={setPreview}
              />
            </div>
            {
              !orderComplete &&
              <Cart
                box={props.box} setBox={props.setBox}
                packing_products={props.packings} packings={props.packings} setPackings={props.setPackings}
                moving_products={props.movings} setMovings={props.setMovings}
                total={props.total} setTotal={props.setTotal}
                rentalid={props.rentalid} setRentalId={props.setRentalId}
                loading={loading} setLoading={setLoading}
                setSelectedRental={props.setSelectedRental}
                getDiscountprice={getDiscountprice} setDiscountprice={setDiscountprice}
                getStairevevators={getStairevevators} setStairevevators={setStairevevators}
                category={props.category} setCategory={props.setCategory}
                getPickupdate={getPickupdate} setPickupdate={setPickupdate}
                getDeliverydate={props.getDeliverydate} setDeliverydate={props.setDeliverydate}
                tax={props.tax} setTax={props.setTax}
                delivetslot1={props.delivetslot1} setDelivetslot1={props.setDelivetslot1}
                delivetslot2={props.delivetslot2} setDelivetslot2={props.setDelivetslot2}
                pickupslot1={props.pickupslot1} setPickupslot1={props.setPickupslot1}
                pickupslot2={props.pickupslot2} setPickupslot2={props.setPickupslot2}
                card={props.card} setCard={props.setCard}
              />
            }

          </div>
        </div>
      </section>
    </>
  );
}

MasterForm.propTypes = {
  tabRef: PropTypes.any,
  setBox: PropTypes.func,
  setPackings: PropTypes.func,
  packings: PropTypes.any,
  moving_products: PropTypes.any,
  setMovings: PropTypes.func,
  movings: PropTypes.any,
  setTotal: PropTypes.func,
  total: PropTypes.any,
  packing_products: PropTypes.any,
  box: PropTypes.any,
  setRentalId: PropTypes.func,
  rentalid: PropTypes.string,
  category: PropTypes.any,
  setCategory: PropTypes.any,
  products: PropTypes.any,
  setProducts: PropTypes.func,
  sub_category: PropTypes.any,
  setSubCate: PropTypes.any,
  select_rental: PropTypes.any,
  setSelectedRental: PropTypes.any,
  address: PropTypes.any,
  setAddress: PropTypes.func,
  header: PropTypes.any,
  setHeader: PropTypes.func,
  tax: PropTypes.any,
  setTax: PropTypes.func,
  delivetslot1: PropTypes.any,
  setDelivetslot1: PropTypes.any,
  delivetslot2: PropTypes.any,
  setDelivetslot2: PropTypes.any,
  getDeliverydate: PropTypes.any,
  setDeliverydate: PropTypes.func,
  pickupslot1: PropTypes.any,
  setPickupslot1: PropTypes.func,
  pickupslot2: PropTypes.any,
  setPickupslot2: PropTypes.func,
  card: PropTypes.any,
  setCard: PropTypes.func,
  orderid: PropTypes.any,
  setOrderid: PropTypes.func
};
