import './index.css';
import { useNavigate } from 'react-router-dom';

const ComingSoon = (props) => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <>
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 d-block title-404">Coming Soon</span>
              <div className="mb-4 lead">
                <span className="color-secondary tittle-1">Page Under Contruction! </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
