import React, { useEffect, useState } from 'react';
import { getTerms } from '../../data/API';
import {Helmet} from "react-helmet";
// import SimpleReactValidator from "simple-react-validator";


export default function index(props) {
  props.setFooteroffice(true);
  props.setshowHideFooter(false);
  const [terms, setterms] = useState({});

  const callApi = () => {
    getTerms().then((res) => {
      setterms(res.data);
    });
  };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms and conditions | BlueBox</title>
        <meta name="description" content="These terms and conditions apply to the use of our websites, use of our web or mobile applications and/or use of our products and/or services" />
        <meta name="keywords" content="bluebox terms and conditions" />
      </Helmet>
      <link rel="stylesheet" type="text/css" href="../../public/style/style.css" />

      <div className="terms">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <h1>Terms & Conditions</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="termsrow terms-cond">
        <div className="columns small-12 large-10 large-offset-1 end" id="page-content">
          <div className="container">
            {terms.results?.map((obj, i) => {
              return (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: obj.content
                  }}></div>
              );
            })} 
          </div>
        </div>
      </div>
    </>
  );
}
