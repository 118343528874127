import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from "antd";
import { currentURL, facebookShareURL, twitterShareURL, whatsappShareURL } from "../../../utils/socialLinks";
const storage_url = process.env.REACT_APP_DIGITALOCEAN_STORAGE || "https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend";

const BlogCard = (blog) => {
  const navigate = useNavigate();
  let { item } = blog;
  item = {
    ...item,
    created_on: new Date(item?.created_on),
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  return (
    <div className="blogCard">
      <div className="blogCard-img">
        <img src={`${storage_url}/${item?.blog_image}`} alt="" className="img-fluid" />
      </div>
      <div className="blogCard-content">
        <h4 className="blogCard-title">
          <Link to="/">{item?.blog_title}</Link>
        </h4>
        <h6 className="blogCard-date">
          {item?.created_on
            ?.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
            .replace(/,/g, '')}
        </h6>
        <p className="blogCard-text">{item?.blog_short_description}</p>
        <div className="blogCard-footer">
          <div className="blogCard-shareBox">
            <h6 className="blogCard-shareBox-title">Share</h6>
            <div className="blogCard-shareBox-links">
              <a href={`${facebookShareURL}/blog/${item?.id}`} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href={`${twitterShareURL}/blog/${item?.id}`} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-x-twitter"></i>
              </a>
              <a href={`${whatsappShareURL}/blog/${item?.id}`} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i>
              </a>
              <Popover
                content="Link Copied"
                trigger="click"
                open={copySuccess}
              >
                <a href={`${currentURL}/blog/${item?.id}`} onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(`${currentURL}/blog/${item?.id}`)
                }} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-link"></i>
                </a>
              </Popover>
            </div>
          </div>
          <button
            onClick={() => {
              navigate(`/blog/${item?.id}`);
            }}
            className="blogCard-btn"
          >
            Keep Reading
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
