import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import './index.css';
import { Button, Form, Input, Select, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { createCustomer, getHdyfu } from '../../../../data/API';

const Register = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [hearingSources, setHearingSources] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
    getHearingSources();
  }, []);

  const getHearingSources = async () => {
    const response = await getHdyfu();

    if (response?.data?.results) {
      setHearingSources(response?.data?.results);
    } else {
      return message.error('error while fetching hearing sources');
    }
  };

  const onSubmit = async () => {
    let formValues = await form.validateFields();

    setFormSubmitted(true);

    try {
      let customerResponse = await createCustomer(formValues);

      if (customerResponse.data?.success) {
        message.success(
          'Registered Successfully, An email was sent to your email for verification...!'
        );
        navigate('/storage-box/email/confirmation');
        setFormSubmitted(false);
      } else {
        message.error('Unable to register, please try again...!');
        setFormSubmitted(false);
      }
    } catch (err) {
      message.error('Unable to register, please try again...!');
      setFormSubmitted(false);
    }
  };

  return (
    <>
      <Container fluid={true} className="p-0 m-0">
        <div className="signup-header px-3">
          <div className="signup-header-content">
            <p className="header">Create an account</p>
            <p className="sub-text">
              This is needed to request boxes and manage your inventory. You’ll also <br /> be able
              to manage your orders and subscriptions
            </p>
          </div>
        </div>
      </Container>

      <Container fluid={true} className="signup-form-bg py-5">
        <Row>
          <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <div className="signup-form px-2">
              <Form
                form={form}
                layout="vertical"
                // onFinish={onFinish}
                autoComplete="off">
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: 'Please enter the name' }]}>
                      <Input size={'large'} placeholder="" className="signup-form-input" />
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <Form.Item
                      name="email"
                      label="Email"
                      validateTrigger="onBlur"
                      rules={[
                        { required: true, message: 'Please input the email address' },
                        { type: 'email', message: 'Please enter a valid email address' }
                      ]}
                      validateStatus={showValidation ? 'success' : ''}>
                      <Input
                        size={'large'}
                        type="text"
                        placeholder=""
                        className="signup-form-input"
                        onChange={() => {
                          setShowValidation(true);
                          let el = document.getElementById('email_address_help');
                          if (el) {
                            el.classList.add('d-none');
                          }
                        }}
                        onBlur={() => {
                          setShowValidation(false);
                          let el = document.getElementById('email_address_help');
                          if (el) {
                            el.classList.remove('d-none');
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your password!'
                          }
                        ]}>
                        <Input
                          size={'large'}
                          type={!showPassword ? 'password' : 'text'}
                          className="signup-form-input bluebox-placeholder"
                        />
                      </Form.Item>
                      <div className="toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        label="Confirm Password"
                        name="cnf_password"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!'
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('The two passwords that you entered do not match!')
                              );
                            }
                          })
                        ]}>
                        <Input
                          size={'large'}
                          type={!showCnfPassword ? 'password' : 'text'}
                          className="signup-form-input bluebox-placeholder"
                        />
                      </Form.Item>
                      <div
                        className="toggle-icon"
                        onClick={() => setShowCnfPassword(!showCnfPassword)}>
                        {showCnfPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}></Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <Form.Item
                      name="hdyfu_id"
                      label="How did you hear about us?"
                      rules={[{ required: true, message: 'Please input how you hear about us?' }]}>
                      <Select size="large">
                        {hearingSources?.map((obj, index) => {
                          return (
                            <Select.Option key={`source_${index}`} value={obj?.id}>
                              {obj.title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}></Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="text-right pb-0">
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={onSubmit}
                        className="signup-register-btn"
                        loading={isFormSubmitted}>
                        Register
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}></Col>
        </Row>
        <div className="signup-form-divider">
          <div className="divider"></div>
        </div>
        <div className="signup-footer pt-3">
          <p className="mb-0 sub-text">
            Already have an account?{' '}
            <span className="navigate-link " onClick={() => navigate('/storage-box/login')}>
              Login Here
            </span>
          </p>
        </div>
      </Container>
    </>
  );
};

export default Register;

Register.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
