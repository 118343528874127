import { useEffect, useRef, useState } from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './views/layout/Header';
import Footer from './views/layout/Footer';
import ScrollToTop from './scrollToTop';
import { getStoreStatus, getStoreStatusMessage, getStoreTiming } from './data/API';
import AppRoutes from './routes';
import PasswordPrompt from './views/layout/PasswordPrompt';
import { useSelector } from 'react-redux';
import { Accessibility } from 'accessibility';

export default function App() {
  const [showHideHeader, setShowHideHeader] = useState(true);
  const [showHideFooter, setshowHideFooter] = useState(true);
  const [showHideinnerFooter, setshowHideinnerFooter] = useState(false);
  const [showFooteroffice, setFooteroffice] = useState(false);
  const [showZipModal, setshowZipModal] = useState(false);
  const [storeStatus, setStoreStatus] = useState(null);
  const [storeStatusMessage, setstoreStatusMessage] = useState(null);
  const [supressHeaderOptions, setSuppressHeaderOptions] = useState(false);
  const { isAuthorized } = useSelector((state) => state.global);
  const currentHostName = process.env.REACT_APP_HOST_URI;
  const hostname = window.location.hostname;
  const setHeader = () => {
    if (window.location.pathname == '/packages') setSuppressHeaderOptions(true);
    else setSuppressHeaderOptions(false);
  };
  const options = {
    icon: {
      position: {
        bottom: {
          size: 150,
          units: 'px'
        },
        right: {
          size: 33,
          units: 'px'
        },
        type: 'fixed'
      }
    }
  };

  useEffect(() => {
    window.addEventListener(
      'load',
      function () {
        new Accessibility(options);
      },
      false
    );
  }, []);

  useEffect(() => {
    setHeader();

    let mounted = true;
    (async function () {
      let status = null;
      await getStoreStatus().then((res) => {
        if (mounted) {
          status = res.data.results[0].status;
        }
      });

      await getStoreStatusMessage().then((res) => {
        if (mounted) setstoreStatusMessage(res.data.results[0].message);
      });

      await getStoreTiming().then((res) => {
        if (mounted) {
          const start = Date.parse(res.data.results[0].From);
          const end = Date.parse(res.data.results[0].To);

          if (start <= Date.now() && Date.now() <= end) {
            setStoreStatus('close');
          } else {
            setStoreStatus(status);
          }
        }
      });
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const requireAuth = () => {
    if (!localStorage.getItem('zipcode')) {
      // window.location.replace('/');
    } else {
      let hours = 24;
      let saved = localStorage.getItem('zipcode');
      let newsaved = JSON.parse(saved);
      if (saved && new Date().getTime() - newsaved.expiry > hours * 60 * 60 * 1000) {
        let keysToRemove = [
          'zipcode',
          'deliverydate',
          'deliverywindow1',
          'deliverywindow2',
          'deliveryaddress',
          'apt',
          'extrawork1',
          'pickupwindow1',
          'pickupwindow2',
          'pickupapt',
          'extrawork2',
          'pickupdescription',
          'fname',
          'lname',
          'email',
          'phone',
          'sphone',
          'voucher_code',
          'hdyfu',
          'company'
        ];

        for (var key of keysToRemove) {
          localStorage.removeItem(key);
        }
      }
    }
  };
  const zipCodeSection = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 100,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {!isAuthorized && hostname === currentHostName ? (
        <PasswordPrompt />
      ) : (
        <div className="App">
          <Toaster position="top-center" reverseOrder={false} />
          <Router>
            <ScrollToTop />

            <div className="row p-0 m-0 header-bg">
              {showHideHeader && window.location.pathname !== '/404' ? (
                <Header
                  showZipModal={showZipModal}
                  zipCodeSection={zipCodeSection}
                  scrollToSection={scrollToSection}
                  showHideHeader={showHideHeader}
                  setShowHideHeader={setShowHideHeader}
                  showHideFooter={showHideFooter}
                  setshowHideFooter={setshowHideFooter}
                  showHideinnerFooter={showHideinnerFooter}
                  setshowHideinnerFooter={setshowHideinnerFooter}
                  storeStatus={storeStatus}
                  storeStatusMessage={storeStatusMessage}
                  supressHeaderOptions={supressHeaderOptions}
                />
              ) : null}
            </div>
            <div className="content app-content" style={{ position: 'relative' }}>
              <AppRoutes
                setshowZipModal={setshowZipModal}
                zipCodeSection={zipCodeSection}
                scrollToSection={scrollToSection}
                showFooteroffice={showFooteroffice}
                setFooteroffice={setFooteroffice}
                setShowHideHeader={setShowHideHeader}
                showHideFooter={showHideFooter}
                setshowHideFooter={setshowHideFooter}
                showHideinnerFooter={showHideinnerFooter}
                storeStatus={storeStatus}
                storeStatusMessage={storeStatusMessage}
                showHideHeader={showHideHeader}
                setshowHideinnerFooter={setshowHideinnerFooter}
                onEnter={requireAuth()}
                setSuppressHeaderOptions={setSuppressHeaderOptions}
              />
            </div>

            {window.location.pathname !== '/404' ? (
              <div
                className={`footer ${
                  window.location.pathname === '/packages' && window.innerWidth < 991.98
                    ? 'd-none'
                    : ''
                }`}>
                {/* {showHideinnerFooter && <Innerfooter />} */}
                <Footer />
                {/* {showFooteroffice && <Footeroffice />} */}
              </div>
            ) : null}
          </Router>
        </div>
      )}
    </>
  );
}

// export default App;
