import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Space,
  Spin,
  Tooltip
} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.css';
import PropTypes from 'prop-types';

import { ReactSession } from 'react-client-session';
import { setCustomerAddress, setPickupDetails, setStepCount } from '../store/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPickUp,
  checkZipCode,
  getDeliverWindows,
  getExtraWork,
  getPickUp
} from '../../../data/API';
import moment from 'moment';
import GoogleAutoComplete from 'react-google-autocomplete';
import { handleEventClick } from "../index";
const content = (
  <div className="box-popover">
    <p>A flight of stairs is seven or more stairs/steps leading in any one given direction.
      If you’re unsure you’ll need stair service at time of checkout but end up requiring it, we will apply the appropriate charge(s) to the payment method used to place the order once delivery and/or pick up has been completed. </p>
  </div>
);

const PickupDetails = (props) => {
  const dispatch = useDispatch();
  const session = ReactSession.get('session');
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const { isParentNextStepClicked, setParentNextStepClicked } = props;

  const currentStep = useSelector((state) => state.package.currentStep);

  const [form] = Form.useForm();
  const [timingOne, setTimingOne] = useState(null);
  const [timingTwo, setTimingTwo] = useState(null);
  const [pickupDate, setPickupDate] = useState('');
  const [deliveryWindows, setDeliveryWindows] = useState([]);
  const [extraWork, setExtraWork] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [deliveryAddressError, setDeliveryAddressError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loadingPickupDate, setLoadingPickupDate] = useState(false);

  const pickupInfo = useSelector((state) => state.package.pickupDetails.pickupInfo);

  useEffect(() => {
    if (pickupInfo?.pickup_address) setDeliveryAddress(pickupInfo?.pickup_address);

    if (pickupInfo && Object.keys(pickupInfo).length > 0) {
      form.setFieldsValue({
        pickupDate: moment(pickupInfo?.pickup_date),
        apt: pickupInfo?.apt_number,
        description: pickupInfo?.description,
        pickupWindow1: pickupInfo?.window_1,
        pickupWindow2: pickupInfo?.window_2,
        extraWork: pickupInfo?.extra_work
      });

      setTimingOne(pickupInfo?.window_1);
      setTimingTwo(pickupInfo?.window_2);

      // incase user chnages the date after pickup date is given
      if (pickupInfo && pickupInfo?.pickup_date) {
        setPickupDate(pickupInfo?.pickup_date);
        setLoadingPickupDate(true);
      }
    }
  }, [pickupInfo]);

  useEffect(() => {
    if (pickupInfo && !('pickup_date' in pickupInfo)) getDate();
    if (pickupInfo && pickupInfo.pickup_date == null) getDate();
    getDeliverySlots();
    getExtarworkInfo();

    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  const getDate = async () => {
    try {
      let date = await getPickUp(session);
      if (
        date &&
        date?.data &&
        date?.data?.results?.length > 0 &&
        date?.data?.results[0]['pickup_date']
      ) {
        setPickupDate(date?.data?.results[0]['pickup_date']);
        setLoadingPickupDate(true);
      }
    } catch (err) {
      return message.error('Errro occured while getting your pickup date', err);
    }
  };

  const getDeliverySlots = async () => {
    try {
      let response = await getDeliverWindows();
      if (response?.data) {
        setDeliveryWindows(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching delivery windows', err);
    }
  };

  const getExtarworkInfo = async () => {
    try {
      let response = await getExtraWork();
      if (response?.data) {
        setExtraWork(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching extra info', err);
    }
  };

  const onFinish = (val) => {
    message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const handleTimingOneChange = (evt) => {
    setTimingOne(evt);

    if (evt == timingTwo) {
      setTimingTwo('');
    }
  };

  const handleTimingTwoChange = (evt) => {
    setTimingTwo(evt);
  };

  const setAddress = async (place) => {
    setDeliveryAddress(place?.formatted_address);

    let postal, country, city, state;
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes('postal_code')) postal = address_element.long_name;
      if (address_element.types.includes('country')) country = address_element.short_name;
      if (address_element.types.includes('locality')) city = address_element.long_name;
      if (address_element.types.includes('administrative_area_level_1'))
        state = address_element.long_name;
    });

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    dispatch(setCustomerAddress(address));

    let zipCodeRes = await checkZipCode({ deliveryzipcode: postal ? postal : '781006' });

    if (zipCodeRes?.data?.success === true) {
      setZipCodeError(null);
    } else {
      setZipCodeError(zipCodeRes?.data?.message_2);
    }
  };

  const onStairsSelected = async (value) => {
    let formValues = await form.validateFields();
    try {
      let data = {
        extra_work: value,
        session: session,
        pickup_date: moment(pickupDate).format('YYYY-MM-DD'),
        pickup_address: deliveryAddress,
        pickup_window_1: formValues?.pickupWindow1,
        pickup_window_2: formValues?.pickupWindow2
      };

      if (formValues?.apt) {
        data['apt_number'] = formValues.apt;
      }

      if (formValues?.description) {
        data['description'] = formValues.description;
      }

      let pickupDetail = await addPickUp(data);

      if (pickupDetail?.data?.id) {
        pickupDetail['data']['window_1'] = formValues['pickupWindow1'];
        pickupDetail['data']['window_2'] = formValues['pickupWindow2'];
        dispatch(setPickupDetails(pickupDetail?.data));
      }
    } catch (err) {
      return message.error('Error while adding delivery info', err);
    }
  };

  const onSubmit = async () => {
    try {
      setIsFormSubmitted(true);

      let formValues = await form.validateFields();

      if (formValues.errorFields?.length) {
        setIsFormSubmitted(false);
        return;
      }

      if (zipCodeError) {
        setIsFormSubmitted(false);
        return;
      }

      if (!deliveryAddress) {
        setIsFormSubmitted(false);
        setDeliveryAddressError('Please select delivery address');
        return;
      }

      let data = {
        session: session,
        pickup_date: moment(pickupDate).format('YYYY-MM-DD'),
        pickup_address: deliveryAddress,
        // latitude: props.pickup?.latitude,
        // longitude: props.pickup?.longitude,
        pickup_window_1: formValues?.pickupWindow1,
        pickup_window_2: formValues?.pickupWindow2,
        extra_work: formValues?.extraWork
      };

      if (formValues?.apt) {
        data['apt_number'] = formValues.apt;
      }

      if (formValues?.description) {
        data['description'] = formValues.description;
      }

      let pickupDetail = await addPickUp(data);

      if (pickupDetail?.data?.id) {
        pickupDetail['data']['window_1'] = formValues['pickupWindow1'];
        pickupDetail['data']['window_2'] = formValues['pickupWindow2'];
        dispatch(setPickupDetails(pickupDetail?.data));
        dispatch(setStepCount(currentStep + 1));
      }
    } catch (err) {
      setIsFormSubmitted(false);
      return message.error(err);
    }
  };

  const onAddresschange = (e) => {
    if (deliveryAddress && e.target.value == 0) {
      setDeliveryAddress('');
      setDeliveryAddressError('Please Select Delivery Address');
    }
  };

  useEffect(() => {
    if (isParentNextStepClicked) {
      setParentNextStepClicked(false);
      setTimeout(() => {
        onSubmit();
      }, 0);
    }
  }, [isParentNextStepClicked]);

  return (
    <div>
      <div className="steps" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title mb-0 ">
          Step 5: Pick Up Details
        </h2>
        <Card bordered={false} className="w-100 pl-3 pr-3 pt-3">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <div className="pickup-info mb-4">
              <Spin tip="getting your pickup date..." spinning={!loadingPickupDate}>
                <p className="mb-2 text-left fs-16 fw-500">Your pickup date is:</p>
                {pickupDate ? (
                  <p className="mb-2 text-left fs-20 color-primary fw-600">
                    {moment(pickupDate).format('dddd MMMM DD, YYYY')}
                  </p>
                ) : (
                  ''
                )}
                <p className="mb-2 text-left fs-12 fw-500">
                  After placing the order, contact us if you need to change the pickup date
                </p>
              </Spin>
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                pickupWindow1: null,
                pickupWindow2: null,
                rentalPeriod: '2weeks'
              }}
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="pickupWindow1"
                    label="Pick Up Window 1"
                    rules={[{ required: true, message: 'Please choose a pick up window 1' }]}>
                    <Select onClick={handleEventClick}
                      size="large"
                      placeholder="Select a time slot"
                      value={timingOne}
                      onChange={handleTimingOneChange}>
                      {deliveryWindows?.map((options, index) => {
                        return (
                          <Select.Option key={`timing_one_${index}`} value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="pickupWindow2"
                    label="Pick Up Window 2"
                    rules={[{ required: true, message: 'Please choose a pick up window 2' }]}>
                    <Select onClick={handleEventClick}
                      size="large"
                      placeholder="Select a time slot"
                      onChange={handleTimingTwoChange}
                      value={timingTwo}
                      disabled={!timingOne}>
                      {deliveryWindows?.map((options, index) => {
                        if (options?.id != timingOne) {
                          return (
                            <Select.Option key={`timing_two_${index}`} value={options?.id}>
                              {options?.start_time} - {options?.end_time}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className="mandatory-field"
                    name="deliveryAddress"
                    label="Pickup Address"
                  // rules={[{ required: true, message: 'Please input the delivery address' }]}
                  >
                    <GoogleAutoComplete
                      className={
                        deliveryAddressError && !deliveryAddress
                          ? 'form-control delivery-address-field-error '
                          : 'form-control'
                      }
                      apiKey={apiKey}
                      onPlaceSelected={(place) => {
                        setAddress(place);
                      }}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                      defaultValue={deliveryAddress}
                      onChange={(e) => {
                        onAddresschange(e);
                      }}
                    />
                    <p className="mb-0 ant-form-item-explain-error">
                      {zipCodeError ? zipCodeError : null}
                    </p>
                    <p className="mb-0 ant-form-item-explain-error">
                      {deliveryAddressError && !deliveryAddress ? deliveryAddressError : null}
                    </p>
                    <p className="mb-0 text-success">
                      {!zipCodeError && deliveryAddress ? 'Services available in this area' : null}
                    </p>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="apt" label="Apt. #" rules={[{ required: false }]}>
                    {/* <Input size="large" placeholder="Apt" /> */}
                    <InputNumber
                      className="w-100"
                      placeholder="Apt."
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="extraWork"
                    className='tool-tip'
                    label={
                      <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "10px" }}>
                        <span>Do you have stairs or an elevator? </span>
                        <Popover content={content}>
                          <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                        </Popover>
                      </div>}
                    rules={[{ required: true, message: 'Please select an option from below' }]}>
                    <Select size="large" onChange={onStairsSelected} onClick={handleEventClick}>
                      {extraWork?.map((obj, index) => {
                        return (
                          <Select.Option
                            className="mobile-wide-option"
                            key={`extarwork_${index}`}
                            value={obj?.id}>{`${obj.title} ($${obj.price})`}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="description"
                    label="Is there anything else we should know?"
                    rules={[{ required: false }]}>
                    <TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>
      <div className="row mt-4 p-2">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={prevStep}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
            size={'large'}
            loading={isFormSubmitted}>
            {!isFormSubmitted ? 'Next Step' : 'Submitting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

PickupDetails.propTypes = {
  isParentNextStepClicked: PropTypes.any,
  tabref: PropTypes.any,
  setParentNextStepClicked: PropTypes.func
};

export default PickupDetails;
