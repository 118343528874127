import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentCard from '../../ContentCard';

const EmailConfirmed = (props) => {
    
    useEffect(() => {
        props.setShowHideHeader(true);
        props.setshowHideFooter(false);
        props.setshowHideinnerFooter(false);
    }, []);
    
    return (
        <ContentCard type={"emailConfirmed"} />
    );

};

export default EmailConfirmed;

EmailConfirmed.propTypes = {
    setShowHideHeader: PropTypes.func,
    setshowHideFooter: PropTypes.func,
    setshowHideinnerFooter: PropTypes.func
};