import * as actionTypes from './constants';

const defaultStateValues = () => {
  return {
    blogs: [],
    total:0,
  };
};

const defaultState = defaultStateValues();

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_BLOGS:
      return { ...state, blogs: action.data?.blogs,total: action?.data?.total };

    case actionTypes.RESET_REDUX_STORE:
      return defaultStateValues();

    default:
      return state;
  }
};
