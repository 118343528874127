import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { checkZipCode, getLocations, validateStorageZipCode } from '../../data/API';
import PropTypes from 'prop-types';
import RenderIf from '../../Utility/RenderIf';
import { Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setPincode } from '../StorageBox/store/actionCreators';
import { setRentalId, setRentalPeriod } from '../BoxPackages/store/actionCreators';
import { getToken } from '../../utils/token';
import {Helmet} from "react-helmet";

export default function Index(props) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [errorMsg, setMsg] = React.useState('');
  const [locations, setLocation] = React.useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const loginToken = getToken();

  const handleZipCode = async () => {
    setIsFormSubmitted(true);

    let formValues = await form.validateFields();

    if (formValues.errorFields?.length > 0) {
      console.log('Entring');
      setIsFormSubmitted(false);
      return;
    }

    if (formValues?.service_type && formValues.service_type == 'STORAGE_ON_DEMAND') {
      let payload = {
        deliveryZipCode: formValues.delivery_zipcode
      };

      let validateRes = await validateStorageZipCode(payload);

      if (validateRes?.data?.data?.serviceExist) {
        setIsFormSubmitted(false);
        dispatch(setPincode(formValues.delivery_zipcode));
        if (loginToken) {
          navigate('/storage-box/buying-box');
        } else {
          navigate('/storage-box/login');
        }
      } else {
        setError(true);
        setMsg('Service not available in this area.');
        setIsFormSubmitted(false);
      }
    } else {
      let obj = {
        pickup_zipcode: formValues.pickup_zipcode,
        delivery_zipcode: formValues.delivery_zipcode
      };
      checkZipCode(obj)
        .then((res) => {
          if (res?.data?.success) {
            setIsFormSubmitted(false);
            navigate('/packages');
            props.setShowHideHeader(false);
            props.setshowHideFooter(false);
            props.setshowHideinnerFooter(true);
          } else {
            setError(true);
            setMsg('Service not available in this area.');
            setIsFormSubmitted(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsFormSubmitted(false);
        });
    }
  };

  const callApi = () => {
    getLocations()
      .then((res) => {
        setLocation(res.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      <Helmet>
        <title>Free Delivery in the San Francisco Bay Area | BlueBox</title>
        <meta name="description" content="Reusable moving box rentals with free delivery & pick-up. Storage on-demand with initial free delivery. We operate in the San Francisco Bay Area and beyond" />
        <meta name="keywords" content="bluebox service area, moving boxes free delivery, moving boxes free delivery San francisco, moving boxes free delivery San Jose, moving boxes free delivery Oakland" />
      </Helmet>
      <section className="hero move-location position-relative location ">
        <div className="container">
          <div id="locations-content" className="ng-scope">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-10 col-xs-12 w-100">
                <div className="order-box locationform">
                  {isLoading ? (
                    <div className="text-center">
                      <div className=" mt-5">
                        <Spinner animation="border" size={'lg'} role="status" variant="primary">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="pincode-form">
                    <div className="modal-header">
                      <img src="img/logo.png" className="img-fluid" alt="" />
                      <h5>{props.storeStatus === 'open' ? 'Moving or need more storage?' : <></>}</h5>
                    </div>
                    <div className="modal-body">
                      <RenderIf isTrue={!props.storeStatus || !props.storeStatusMessage}>
                        <div className="row d-flex justify-content-center">
                          <Spinner animation="border" size={'lg'} role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={!!props.storeStatus && !!props.storeStatusMessage}>
                        <RenderIf isTrue={props.storeStatus === 'close'}>
                          <div className="row d-flex justify-content-center align-center text-center">
                            <h3>{props.storeStatusMessage}</h3>
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={props.storeStatus === 'open'}>
                          <div className="row content-row">
                            {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}

                            <Form
                              form={form}
                              layout="vertical"
                              autoComplete="off"
                              style={{ position: 'relative', zIndex: 0, width: '100%' }}
                              onFinish={handleZipCode}
                              initialValues={{
                                service_type: 'RENT_MOVING_BOXES'
                              }}>
                              <Row>
                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="px-3">
                                  <Form.Item
                                    label="Service"
                                    name="service_type"
                                    rules={[
                                      { required: true, message: 'Please select an service' }
                                    ]}>
                                    <Select
                                      size="large"
                                      style={{ width: '100%' }}
                                      placeholder="Select an option">
                                      <Select.Option value={'RENT_MOVING_BOXES'}>
                                        Rent Moving Boxes
                                      </Select.Option>
                                      <Select.Option value={'STORAGE_ON_DEMAND'}>
                                        Storage On-Demand
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.service_type !== currentValues.service_type
                                  }>
                                  {({ getFieldValue }) =>
                                    getFieldValue('service_type') !== 'STORAGE_ON_DEMAND' ? (
                                      <Col
                                        xxl={12}
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="px-3">
                                        <Form.Item
                                          label="Zip code of your current address?"
                                          name="pickup_zipcode"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please enter the current zipcode'
                                            }
                                          ]}>
                                          <Input placeholder="Enter Zip code" size="large" />
                                        </Form.Item>
                                      </Col>
                                    ) : null
                                  }
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.service_type !== currentValues.service_type
                                    }>
                                  {({ getFieldValue }) =>
                                      getFieldValue('service_type') !== 'STORAGE_ON_DEMAND' ? (
                                          <Col
                                              xxl={12}
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="px-3">
                                            <Form.Item
                                                label="Zip code of your new address?"
                                                name="delivery_zipcode"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please enter the new zipcode'
                                                  }
                                                ]}>
                                              <Input placeholder="Enter Zip code" size="large" />
                                            </Form.Item>
                                          </Col>
                                      ) : <Col
                                          xxl={12}
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className="px-3">
                                        <Form.Item
                                            label="Zip Code where you’d like storage boxes delivered?"
                                            name="delivery_zipcode"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please enter the zipcode'
                                              }
                                            ]}>
                                          <Input placeholder="Enter Zip code" size="large" />
                                        </Form.Item>
                                      </Col>
                                  }
                                </Form.Item>

                                {/*<Col*/}
                                {/*  xxl={12}*/}
                                {/*  xl={12}*/}
                                {/*  lg={12}*/}
                                {/*  md={12}*/}
                                {/*  sm={12}*/}
                                {/*  xs={12}*/}
                                {/*  className={`px-3`}>*/}
                                {/*  <Form.Item*/}
                                {/*    label="Zip code of your new address?"*/}
                                {/*    name="delivery_zipcode"*/}
                                {/*    rules={[*/}
                                {/*      {*/}
                                {/*        required: true,*/}
                                {/*        message: 'Please enter the current zipcode'*/}
                                {/*      }*/}
                                {/*    ]}>*/}
                                {/*    <Input placeholder="Enter Zip code" size="large" />*/}
                                {/*  </Form.Item>*/}
                                {/*</Col>*/}

                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className={`px-3`}>
                                  <Form.Item>
                                    <button
                                      type="submit"
                                      className="btn continue-zip-code-btn w-100 mt-3"
                                      disabled={isFormSubmitted}>
                                      {isFormSubmitted ? 'Processing...' : 'Continue'}
                                    </button>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </RenderIf>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="locationiframe p-0 mb-3">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=12kcjTorPJsZapSyYTn6pryBGaOazmiPn"
          width="640"
          height="480"
          title="location_one"></iframe>
      </section>
      <section className="areas_cities">
        <div className="container">
          <h2 className="font-weight-bold">
            We deliver to cities in the San Francisco Bay Area and beyond
          </h2>
          <div className="line bg-primary"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="row mt-2">
                {locations.results?.map((obj, i) => {
                  return (
                    <div
                      key={i}
                      className="col-md-3 p-1 cityname aos-init aos-animate"
                      >
                      {obj.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Index.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
