// export const CHANGE_DELIVERY_FORM_VALIDATION = 'CHANGE_DELIVERY_FORM_VALIDATION';
// export const CHANGE_PICKUP_FORM_VALIDATION = 'CHANGE_PICKUP_FORM_VALIDATION';
// export const SET_RENTAL_ID = 'SET_RENTAL_ID';

// New Code
export const RESET_REDUX_STORE = 'RESET_REDUX_STORE';
export const SET_RENTAL_PERIOD = 'SET_RENTAL_PERIOD';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_PACKAGE = 'SET_PACKAGE';
export const SET_PACKAGE_INDEX = 'SET_PACKAGE_INDEX';
export const SET_STEP_COUNT = 'SET_STEP_COUNT';
export const SET_PACKAGING_PRODUCTS = 'SET_PACKAGING_PRODUCTS';
export const SET_MOVING_SUPPLIES_PRODUCTS = 'SET_MOVING_SUPPLIES_PRODUCTS';
export const SET_DELIVERY_INFO = 'SET_DELIVERY_INFO';
export const SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS';
export const SET_ORDER_ID = 'SET_ORDER_ID';

export const SET_PICKUP_DETAIL = 'SET_PICKUP_DETAIL';
export const SET_CHANGE_PACKAGE = 'SET_CHANGE_PACKAGE';
export const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';
export const SET_DISCOUNT = 'SET_DISCOUNT';
