import React, { useEffect, useState } from 'react';
import { Collapse, Button, Card, InputNumber, message } from 'antd';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from "lodash"
import PropTypes from 'prop-types';
import './index.css';

const OrderSummary = (props) => {
  const extraWorkId = useSelector(state => state.storage.extraWorkId);
  const { deliveryWindows, extraWork } = props;

  const { deliveryOrder } = useSelector((state) => state.storage);

  let extraCost = 0;

  if (extraWorkId) {
    let extraWorkObj = _.find(extraWork, { id: extraWorkId });

    if (extraWorkObj) {
      extraCost = Number(extraWorkObj.price);
    }
  }

  const deliveryDateString = moment(deliveryOrder?.deliveryDetails?.delivery_date).format('MMM DD, YYYY (dddd)');
  const deliveryWindow1 = _.find(deliveryWindows, { id: deliveryOrder?.deliveryDetails?.delivery_window_1_id })
  const deliveryWindow2 = _.find(deliveryWindows, { id: deliveryOrder?.deliveryDetails?.delivery_window_2_id })

  return (
    <>
      <Card title="Order Summary" className="order-summary-card">
        <Row className="ml-0 mr-0">
          <Col xxl={12} xl={12} lg={12} sm={12} xs={12}>
            <div className="summary-panel-content pl-0 pr-0 order-package">
              <p
                className="text-left d-flex justify-content-between align-items-start mb-1 package-desc"
                key={'package_value'}>
                <span>Delivery boxes {deliveryOrder.requestedBoxes.map((obj) => obj?.box_id)?.toString()}</span>
                {props?.boxPrices?.length > 0 ? <span><p className='mb-0 text-center'>${Number(props?.boxPrices[1]?.price)}</p></span> : '' }
              </p>
            </div>
          </Col>
        </Row>

        <Row className="ml-0 mr-0 highlight-package ">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="summary-panel-content pl-0 pr-0 order-package ">
              <p className="text-left mb-1 fw-600 package-title" key="package">
                Delivery Date & Time
              </p>

              {
                deliveryOrder?.deliveryDetails?.delivery_date ?
                  (
                    <>
                      <p className="mb-0">{deliveryDateString}</p>
                      <p className="mb-0">{deliveryWindow1?.start_time}-{deliveryWindow1?.end_time}</p>
                      <p className="mb-0">{deliveryWindow2?.start_time}-{deliveryWindow2?.end_time}</p>
                    </>
                  ) :
                  (
                    <>
                      <p className="mb-0">No date selected</p>
                    </>
                  )
              }
            </div>
          </Col>
        </Row>

        {
          extraCost > 0 &&
          < Row className="ml-0 mr-0">
            <Col xxl={12} xl={12} lg={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package">
                <p
                  className="text-left d-flex justify-content-between align-items-center mb-1 package-desc"
                  key={'package_value'}>
                  {
                    extraCost > 0 ? (
                      <>
                        <>
                          <span>Stairs/Elevators</span>
                          <span>${extraCost}</span>
                        </>
                      </>
                    ) :
                      (
                        <>

                        </>
                      )
                  }
                </p>
              </div>
            </Col>
          </Row>
        }

        <div className="summary-footer  bg-theme-secondary  mb-0 d-flex">
          <div className='col-6 fw-600'>
            Total Cost
          </div>
          <div className='col-6 fw-600 text-right'>
            ${deliveryOrder?.requestedBoxes?.length > 0 ? 29 + (extraCost ? extraCost : 0) : 0}
          </div>
        </div>
      </Card>
    </>
  );
};

export default OrderSummary;


OrderSummary.propTypes = {
  deliveryWindows: PropTypes.any,
  extraWork: PropTypes.any,
  boxPrices: PropTypes.any
};