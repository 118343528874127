import * as actionTypes from './constants';

const defaultStateValues = () => {
  return {
    password: "",
    isAuthorized :false,
  };
};

const defaultState = defaultStateValues();

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH:
      return { ...state, password: action.data?.password,isAuthorized: true };

    case actionTypes.RESET_REDUX_STORE:
      return defaultStateValues();

    default:
      return state;
  }
};
