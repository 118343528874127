import React from 'react';
import { getAbout } from '../../data/API';
import {Helmet} from "react-helmet";

export default function index(props) {
  const [about, setabout] = React.useState({});
  props.setFooteroffice(true);
  props.setshowHideFooter(false);
  const callApi = () => {
    getAbout().then((res) => {
      setabout(res.data);
    });
  };
  React.useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      <Helmet>
      <title>A green and convenient solution for your next move | BlueBox</title>
      <meta name="description" content="We deliver clean, reusable, tree-free boxes and recyclable and/or biodegradable moving supplies directly to your door. Who says green can’t be convenient?" />
      <meta name="keywords" content="about bluebox, reusable moving boxes San francisco, reusable moving boxes San Jose, reusable moving boxes Oakland" />
      </Helmet>
      <section className="hero move-hero-about position-relative ">
        <div className="container">
          {/* <div className="text-center">
                        <h1 className="text-white" data-aos="fade-down" data-aos-delay="0" data-aos-duration="1000">
                           About Us
                        </h1>
                       
                        <button className="btn btn-primary px-4 mt-2" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1000"  >Order Now</button>
                    </div> */}
          <div className="about_icon">
            <h1>PRODUCING ONE TON OF CARDBOARD REQUIRES</h1>
            <div className="row">
              <div className="col-md-4">
                <img src="img/trees.png" alt="" />
                <h4>17</h4> <p>Trees</p>
              </div>
              <div className="col-md-4">
                <img src="img/oil.png" alt="" />
                <h4>79</h4>{' '}
                <p>
                  Gallons
                  <br />
                  of Oil
                </p>
              </div>
              <div className="col-md-4">
                <img src="img/water.png" alt="" />
                <h4>7000</h4>
                <p>
                  Gallons of <br /> Water
                </p>
              </div>
              <div className="aftricn">
                <p>In the US, over 17,000 tons of cardboard end up in landfills each year</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="termsrow terms-cond">
        <div className="columns small-12 large-10 large-offset-1 end" id="page-content">
          <div className="container">
            {about.results?.map((obj, i) => {
              return (
                <>
                  <h2>
                    <strong
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: obj.heading
                      }}></strong>
                  </h2>
                  <br />
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: obj.content
                    }}></div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
