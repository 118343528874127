import React, { useState } from 'react';
import ZipCode from '../Home/ZipModal';
import PropTypes from 'prop-types';

import { rentalsPeriods, getBoxPackages, getPackage, getHomeMoving } from '../../data/API';
import {Helmet} from "react-helmet";

export default function HomeMoving(props) {
  const [openZipModal, setZipModal] = React.useState(false);
  const [homeMoving, sethomeMoving] = React.useState({});

  const hideZipM = () => {
    setZipModal(false);
  };

  const showZipModal = () => {
    setZipModal(true);
  };

  const [rentalList, setRentalList] = useState([]);
  const [products1, setProducts1] = useState([]);
  const [rental2, setRental2] = useState([]);
  const [rental3, setRental3] = useState([]);
  const [rental4, setRental4] = useState([]);
  const [rental5, setRental5] = useState([]);
  const [rental6, setRental6] = useState([]);

  const [toggleModal, setToggleModal] = useState(true);

  async function getrentalprice(id) {
    await getPackage('Home', 'Box', id).then((res) => {
      if (id === 2) {
        setRental2(res?.data?.results);
      }
      if (id === 3) {
        setRental3(res?.data?.results);
      }
      if (id === 4) {
        setRental4(res?.data?.results);
      }
      if (id === 5) {
        setRental5(res?.data?.results);
      }
      if (id === 6) {
        setRental6(res?.data?.results);
      }
    });
  }
  const callApi = () => {
    getHomeMoving().then((res) => {
      sethomeMoving(res.data);
    });
  };

  const getRentals = () => {
    rentalsPeriods()
      .then((res) => {
        setRentalList(res?.data);

        res?.data.results?.map((obj) => {
          if (obj.sort_by > 1) {
            getrentalprice(obj.sort_by);
          }
        });
      })
      .catch((e) => console.log(e));
  };

  const getBoxProducts = () => {
    getBoxPackages('Home', 'Box Packges', '1')
      .then((res) => {
        setProducts1(res?.data);
      })
      .catch((e) => console.log(e));
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }
  React.useEffect(() => {
    getRentals();
    callApi();
    getBoxProducts();
  }, []);
  return (
    <>
      <Helmet>
        <title>Home Moving Box Rentals | BlueBox</title>
        <meta name="description" content="Free delivery and pick up of clean, reusable moving boxes to residences in the San Francisco Bay Area and beyond. Simplify your moving experience with us" />
        <meta name="keywords" content="home moving box rentals, home moving san francisco, home moving san jose, home moving oakland" />
      </Helmet>
      <ZipCode
        showModal={openZipModal}
        hideModal={hideZipM}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        showHideHeader={props.showHideHeader}
        setShowHideHeader={props.setShowHideHeader}
        showHideFooter={props.showHideFooter}
        setshowHideFooter={props.setshowHideFooter}
        showHideinnerFooter={props.showHideinnerFooter}
        setshowHideinnerFooter={props.setshowHideinnerFooter}
        storeStatus={props.storeStatus}
        storeStatusMessage={props.storeStatusMessage}
      />
      <section className="hero move-home position-relative">
        <div className="container">
          <div className="text-center">
            <h1
              className="text-white aos-init aos-animate"

             >
              <span className="font-weight-bold"> Home Moving Box Rentals</span>
            </h1>
            <button
              className="btn btn-primary px-4 mt-2 aos-init aos-animate"
              onClick={() => {
                showZipModal();
                setToggleModal(true);
              }}>
              Order Now
            </button>
          </div>
        </div>
      </section>

      <section className="packages">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {homeMoving.results?.map((obj, i) => {
                return (
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: obj.content
                    }}></div>
                );
              })}
            </div>
          </div>
          <div className="cardbox">
            <div className="row">
              <div className="col-md-12">
                <img src="img/box.png" alt="" />
              </div>
              <div id="mydiv">
                <img src="img/productmobile2.png" className="image_mobile" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

HomeMoving.propTypes = {
  showHideHeader: PropTypes.any,
  setShowHideHeader: PropTypes.any,
  showHideFooter: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  showHideinnerFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  showZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any,
  supressHeaderOptions: PropTypes.any
};
