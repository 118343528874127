import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSession } from 'react-client-session';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

import { Card, Form, Input, message, Select, Checkbox, Button, Spin, Alert } from 'antd';
import {
  ArrowLeftOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Col, Row } from 'react-bootstrap';
import './index.css';
import { setOrderId, setPersonalData } from '../store/actionCreators';
import PropTypes from 'prop-types';

import {
  getHdyfu,
  addPersonal,
  addOrder,
  placeOrder,
  stripeCheckout,
  getValidateCoupon
} from '../../../data/API';
import { setStepCount, setDiscount } from '../store/actionCreators';
import { useNavigate } from 'react-router-dom';
import { handleEventClick } from '../index';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
    }
  ]
};

const CheckoutForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const session = ReactSession.get('session');

  const currentStep = useSelector((state) => state.package.currentStep);
  const { customerAddress } = useSelector((state) => state.package);
  const deliveryInfo = useSelector((state) => state.package.deliveryDetails.deliveryInfo);
  const discount = useSelector((state) => state.package.discount);
  const [form] = Form.useForm();
  const [hearingSources, setHearingSources] = useState([]);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [validMessage, setValidMessage] = useState('');
  const [status, setStatus] = useState('');
  const { isParentPayClicked, setParentPayClicked } = props;

  const personalData = useSelector((state) => state.package.personalData);
  const packageData = useSelector((state) => state.package);
  const formRef = useRef(null);

  const handleValueChange = (changedValues, allValues) => {
    if ('email_address' in changedValues) {
      localStorage.setItem('email', changedValues?.email_address);
    }
  };
  const onFinish = (val) => {
    dispatch(setPersonalData(val));
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const getHearingSources = async () => {
    const response = await getHdyfu();

    if (response?.data?.results) {
      setHearingSources(response?.data?.results);
    } else {
      return message.error('error while fetching hearing sources');
    }
  };

  // Init Invocations
  useEffect(() => {
    getHearingSources();
    if (discount) {
      form.setFieldsValue({ voucher_code: discount?.code });
      setValidMessage('You have successfully applied the promotional code to the order');
      setStatus('success');
    } else {
      form.setFieldsValue({ voucher_code: null });
    }
    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (isParentPayClicked) {
      handleClick();
    }
  }, [isParentPayClicked]);
  useEffect(() => {
    return async () => {
      setIsFormSubmitted(false);
    };
  }, []);

  const handleClick = async (event) => {
    localStorage.removeItem('email');
    const stripe = await stripePromise;
    let formValues = await form.validateFields();

    if (formValues.errorFields?.length) {
      return;
    }
    if (!formValues?.termsAndCondition) {
      message.error('Please correct fields in red below then submit form.');
      return;
    }

    setIsFormSubmitted(true);

    let data = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      email_address: formValues.email_address,
      phone_number: formValues.phone_number
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', ''),
      hdyfu: formValues.hdyfu,
      session: session
    };

    if (formValues?.secondary_phone_number) {
      data['secondary_phone_number'] = formValues.secondary_phone_number
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '');
      data['voucher_code'] = formValues.voucher_code;
    }
    formRef.current.submit();

    let orderdetails = {
      session: session,
      delivery_date: deliveryInfo.delivery_date,
      voucher: discount ? discount.code : ''
    };

    let personalDetailsRes = await addPersonal(data);

    if (personalDetailsRes?.data) {
      let orderRes = await addOrder(orderdetails);
      let total = Number(orderRes?.data?.total);
      let totalamount = localStorage.getItem('total');
      const session = await stripeCheckout({
        payment: { billing_details: { email: formValues.email_address } },
        session: orderRes?.data?.order_id,
        amount: total !== null ? Number(total) : totalamount,
        discount: 0
      });
      setIsFormSubmitted(false);
      const result = await stripe.redirectToCheckout({
        sessionId: session.data.sessionId
      });
    }
  };

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const changeAgreeTerms = (e) => {
    setAgreeTerms(e.target.checked);
  };

  const validateMobileNumber = (e) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNum = value.replace(/[^\d]/g, '');
    const phoneLen = phoneNum.length;

    if (phoneLen < 4) return phoneNum;

    if (phoneLen < 7) {
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }

    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  };

  const handlePrimary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      phone_number: formattedPhone
    });
  };

  const handleSecondary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      secondary_phone_number: formattedPhone
    });
  };

  useEffect(() => {
    localStorage.setItem('package', JSON.stringify(packageData));
    return async () => {
      if (isParentPayClicked) {
        localStorage.removeItem('email');
        localStorage.removeItem('package');
      }
    };
  }, []);
  const test = () => {
    getValidateCoupon(form.getFieldValue('voucher_code'))
      .then((response) => {
        setValidMessage('You have successfully applied the promotional code to the order');
        setStatus('success');
        dispatch(setDiscount(response.data));
      })
      .catch((err) => {
        let status = err.response.status;
        if (status == 404) {
          setValidMessage('The coupon code you entered is not valid.');
        } else if (status == 400) {
          setValidMessage('The coupon code you have entered has expired.');
        } else {
          setValidMessage('Network connnection. Please try again');
        }
        setStatus('error');
        dispatch(setDiscount(null));
      });
  };
  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 6: Personal & Payment Details
        </h2>
        <Card bordered={false} className="w-100 p-3">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <div className="mb-4">
              <p className="mb-2 text-left fs-20 fw-500">Personal Details</p>
            </div>
            <Form
              onValuesChange={handleValueChange}
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={personalData}
              style={{ position: 'relative', zIndex: 0 }}
              ref={formRef}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true, message: 'Please input the first name' }]}>
                    <Input size="large" placeholder="" />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please input the last name' }]}>
                    <Input size="large" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="email_address"
                    label="Email Address"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the email address' },
                      { type: 'email', message: 'Please enter a valid email address' }
                    ]}
                    validateStatus={showValidation ? 'success' : ''}>
                    <Input
                      size="large"
                      placeholder=""
                      onChange={() => {
                        setShowValidation(true);
                        let el = document.getElementById('email_address_help');
                        if (el) {
                          el.classList.add('d-none');
                        }
                      }}
                      onBlur={() => {
                        setShowValidation(false);
                        let el = document.getElementById('email_address_help');
                        if (el) {
                          el.classList.remove('d-none');
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="phone_number"
                    label="Mobile Number"
                    validateTrigger="onBlur"
                    extra="(Used for delivery notifications)"
                    rules={[
                      { required: true, message: 'Please input the mobile number', min: 14 }
                    ]}>
                    <Input
                      size="large"
                      placeholder=""
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => handlePrimary(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="secondary_phone_number"
                    label="Secondary phone number"
                    validateTrigger="onBlur"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue('secondary_phone_number') &&
                            getFieldValue('secondary_phone_number').length != 14
                          ) {
                            return Promise.reject('Please enter valid number'); // The validator should always return a promise on both success and error
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <Input
                      size="large"
                      placeholder=""
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => handleSecondary(e)}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
                  <Form.Item
                    name="voucher_code"
                    label="If you have a coupon, enter it below"
                    validateStatus={status}>
                    <Input size="large" placeholder="" />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0 apply-button" xxl={2} xl={2} lg={2} md={2} sm={4} xs={4}>
                  <Form.Item name="voucher_code_apply" label=" ">
                    <Button size="large" block onClick={test}>
                      Apply
                    </Button>
                  </Form.Item>
                </Col>

                {/* {status === 'success' && (
                  <Alert
                      message={message}
                      type={status}
                      showIcon
                      icon={<InfoCircleOutlined />}
                  />
                )} */}
              </Row>
              {validMessage.length > 0 && (
                <Row>
                  <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={0} xs={0}></Col>
                  <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Alert
                      message={validMessage}
                      type={status}
                      showIcon
                      icon={status === 'success' ? <InfoCircleOutlined /> : <CloseCircleOutlined />}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="hdyfu"
                    label="How did you hear about us?"
                    rules={[{ required: true, message: 'Please input how you hear about us?' }]}>
                    <Select size="large" onClick={handleEventClick}>
                      {hearingSources?.map((obj, index) => {
                        return (
                          <Select.Option key={`source_${index}`} value={obj?.id}>
                            {obj.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  className="px-3 py-0 d-flex justify-content-center align-items-center"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}>
                  <Form.Item
                    className="mb-0"
                    name="termsAndCondition"
                    valuePropName="checked"
                    rules={[{ required: true, message: 'Please accept the terms and condition' }]}>
                    <Checkbox
                      className="fs-15"
                      value={agreeTerms}
                      onChange={(e) => {
                        changeAgreeTerms(e);
                      }}>
                      I agree to bluebox terms of service
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10 p-2">
        <div className="col d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={prevStep}
            size={'large'}>
            {/* <img src='img/Previous.png' /> */}
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-submit-order"
            icon={<CreditCardOutlined />}
            onClick={handleClick}
            size={'large'}
            loading={isFormSubmitted}>
            {!isFormSubmitted ? 'Pay & Order' : 'Redirecting...'}
          </Button>
        </div>
      </div>
    </div>
  );
};

CheckoutForm.propTypes = {
  isParentNextStepClicked: PropTypes.any,
  tabref: PropTypes.any,
  isParentPayClicked: PropTypes.any,
  setParentPayClicked: PropTypes.func
};

const PersonalPayDetails = (props) => {
  return (
    <>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          tabref={props.tabref}
          isParentPayClicked={props.isParentPayClicked}
          setParentPayClicked={props.setParentPayClicked}
        />
      </Elements>
    </>
  );
};

PersonalPayDetails.propTypes = {
  isParentNextStepClicked: PropTypes.any,
  tabref: PropTypes.any,
  isParentPayClicked: PropTypes.any,
  setParentPayClicked: PropTypes.func
};

export default PersonalPayDetails;
