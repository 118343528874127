import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Space,
  Spin,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import GoogleAutoComplete from 'react-google-autocomplete';
import { ReactSession } from 'react-client-session';
import moment from 'moment';
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'react-bootstrap';
import './index.css';

import {
  rentalsPeriods,
  addtoCart,
  getDeliverWindows,
  getExtraWork,
  addDelivery,
  checkZipCode,
  getUnavaliableDays,
  getBlockedDays
} from '../../../data/API';
import {
  setDeliveryInfo,
  setStepCount,
  setCustomerAddress,
  setPickupDetails
} from '../store/actionCreators';
import { handleEventClick } from '../index';
const content = (
  <div className="box-popover">
    <p>A flight of stairs is seven or more stairs/steps leading in any one given direction.
      If you’re unsure you’ll need stair service at time of checkout but end up requiring it, we will apply the appropriate charge(s) to the payment method used to place the order once delivery and/or pick up has been completed. </p>
  </div>
);
const DeliveryDetails = (props) => {
  const dispatch = useDispatch();

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const session = ReactSession.get('session');

  const currentStep = useSelector((state) => state.package.currentStep);
  const { selectedRentalPeriod } = useSelector((state) => state.package.choosePackage);
  const deliveryInfo = useSelector((state) => state.package.deliveryDetails.deliveryInfo);
  const pickupInfo = useSelector((state) => state.package.pickupDetails.pickupInfo);

  const [form] = Form.useForm();
  const [timingOne, setTimingOne] = useState(null);
  const [timingTwo, setTimingTwo] = useState(null);
  const [rentalPeriods, setRentalPeriods] = useState([]);
  const [deliveryWindows1, setDeliveryWindows1] = useState([]);
  const [deliveryWindows2, setDeliveryWindows2] = useState([]);
  const [extraWork, setExtraWork] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [deliveryAddressError, setDeliveryAddressError] = useState(null);
  const [unavaliableDates, setUnavaliableDates] = useState(null);
  // const [intialFormValues, setIntialFormValues] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [disableDays, setDisableDays] = useState([]);
  const { isParentNextStepClicked, setParentNextStepClicked } = props;

  const intialFormValues = {
    delivery_date: deliveryInfo?.delivery_date ? dayjs(deliveryInfo?.delivery_date) : '',
    delivery_window_1: deliveryInfo?.window_1 ? deliveryInfo?.window_1 : '',
    delivery_window_2: deliveryInfo?.window_2 ? deliveryInfo?.window_2 : '',
    apt_number: deliveryInfo?.apt_number ? deliveryInfo?.apt_number : '',
    extra_work: deliveryInfo?.extra_work ? deliveryInfo?.extra_work : '',
    description: deliveryInfo?.description ? deliveryInfo?.description : ''
  };

  const onFinish = (val) => {
    message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const handleTimingOneChange = (evt) => {
    setTimingOne(evt);

    if (evt == timingTwo) {
      setTimingTwo(null);
    }
  };

  const handleTimingTwoChange = (evt) => {
    setTimingTwo(evt);
  };

  const getRentalPeriods = async () => {
    try {
      let rentalPeriodsRes = await rentalsPeriods();

      if (rentalPeriodsRes?.data) {
        setRentalPeriods(rentalPeriodsRes?.data.results);
      }
    } catch (err) {
      return message.error('error fetching rental periods', err);
    }
  };

  const getDeliverySlots = async () => {
    try {
      let response = await getDeliverWindows();
      if (response?.data) {
        setDeliveryWindows1(response?.data.results);
        setDeliveryWindows2(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching delivery windows', err);
    }
  };

  const getExtarworkInfo = async () => {
    try {
      let response = await getExtraWork();
      if (response?.data) {
        setExtraWork(response?.data.results);
      }
    } catch (err) {
      return message.error('error fetching extra work info windows', err);
    }
  };

  const onStairsSelected = async (value) => {
    let formValues = await form.validateFields();
    try {
      let data = {
        extra_work: value,
        session: session,
        delivery_date: moment(formValues.delivery_date?.$d).format('YYYY-MM-DD'),
        rental: selectedRentalPeriod.id,
        delivery_address: deliveryAddress,
        delivery_window_1: formValues?.delivery_window_1,
        delivery_window_2: formValues?.delivery_window_2
      };

      if (formValues?.apt_number) {
        data['apt_number'] = formValues.apt_number;
      }

      if (formValues?.description) {
        data['description'] = formValues.description;
      }

      let deliveryRes = await addDelivery(data);

      if (deliveryRes?.data?.id) {
        deliveryRes['data']['window_1'] = formValues['delivery_window_1'];
        deliveryRes['data']['window_2'] = formValues['delivery_window_2'];
        dispatch(setDeliveryInfo(deliveryRes?.data));
      }
    } catch (err) {
      return message.error('Error while adding delivery info', err);
    }
  };

  const fetchUnavaliableDates = async () => {
    try {
      getUnavaliableDays().then((res) => {
        setUnavaliableDates(res.data.results);
      });
    } catch (err) {
      return message.error('Error Fetching Blocked Dates', err);
    }
  };

  useEffect(() => {
    if (deliveryInfo?.delivery_address) setDeliveryAddress(deliveryInfo?.delivery_address);
  }, [deliveryInfo]);

  useEffect(() => {
    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  // Init Invocations
  useEffect(() => {
    getRentalPeriods();
    getDeliverySlots();
    getExtarworkInfo();
    fetchUnavaliableDates();
  }, []);

  const setAddress = async (place) => {
    setDeliveryAddress(place?.formatted_address);

    let postal, country, city, state;
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes('postal_code')) postal = address_element.long_name;
      if (address_element.types.includes('country')) country = address_element.short_name;
      if (address_element.types.includes('locality')) city = address_element.long_name;
      if (address_element.types.includes('administrative_area_level_1'))
        state = address_element.long_name;
    });

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    dispatch(setCustomerAddress(address));

    let zipCodeRes = await checkZipCode({ deliveryzipcode: postal ? postal : '781006' });

    if (zipCodeRes?.data?.success === true) {
      setZipCodeError(null);
    } else {
      setZipCodeError(zipCodeRes?.data?.message_2);
    }
  };

  const onSubmit = async () => {
    try {
      setIsFormSubmitted(true);

      if (!deliveryAddress) {
        setDeliveryAddressError('Please select delivery address');
      }

      let formValues = await form.validateFields();

      if (formValues.errorFields?.length) {
        setIsFormSubmitted(false);
        return;
      }

      if (zipCodeError) {
        setIsFormSubmitted(false);
        return;
      }

      if (!deliveryAddress) {
        setIsFormSubmitted(false);
        setDeliveryAddressError('Please select delivery address');
        return;
      }

      if (
        deliveryInfo &&
        deliveryInfo?.delivery_date &&
        moment(formValues.delivery_date?.$d).format('YYYY-MM-DD') !==
        moment(deliveryInfo?.delivery_date).format('YYYY-MM-DD')
      ) {
        if (pickupInfo) {
          pickupInfo['pickup_date'] = null;
          dispatch(setPickupDetails(pickupInfo));
        }
      }

      let data = {
        session: session,
        delivery_date: moment(formValues.delivery_date?.$d).format('YYYY-MM-DD'),
        rental: selectedRentalPeriod.id,
        delivery_address: deliveryAddress,
        // latitude: props.delivery?.latitude,
        // longitude: props.delivery?.longitude,
        delivery_window_1: formValues?.delivery_window_1,
        delivery_window_2: formValues?.delivery_window_2,
        extra_work: formValues?.extra_work
      };

      if (formValues?.apt_number) {
        data['apt_number'] = formValues.apt_number;
      }

      if (formValues?.description) {
        data['description'] = formValues.description;
      }

      let deliveryRes = await addDelivery(data);

      if (deliveryRes?.data?.id) {
        deliveryRes['data']['window_1'] = formValues['delivery_window_1'];
        deliveryRes['data']['window_2'] = formValues['delivery_window_2'];
        dispatch(setDeliveryInfo(deliveryRes?.data));
        dispatch(setStepCount(currentStep + 1));
      }
    } catch (err) {
      setIsFormSubmitted(false);
      return message.error(err);
    }
  };

  const onAddresschange = (e) => {
    if (deliveryAddress && e.target.value == 0) {
      setDeliveryAddress('');
      setDeliveryAddressError('Please Select Delivery Address');
    }
  };
  const blockedDays = () => {
    getBlockedDays().then((res) => {
      const blockedDaysArray = res?.data?.results?.map((obj) => obj.day) || [];
      setDisableDays(blockedDaysArray);
    });
  };
  useEffect(() => {
    blockedDays();
  }, []);

  useEffect(() => {
    if (isParentNextStepClicked) {
      setParentNextStepClicked(false);
      onSubmit();
    }
  }, [isParentNextStepClicked]);

  const disableDate = (current) => {
    const todayDate = moment();
    const threePM = moment().set({ hour: 15, minute: 0, second: 0 }); // Set the time to 3:00 PM PST

    return (
      todayDate >= current.$d ||
      (current.$d === moment(todayDate).add(1, 'days') && todayDate.isAfter(threePM)) ||
      (moment(todayDate).add(1, 'days').format('MM-DD-YYYY') ===
        moment(current.$d).format('MM-DD-YYYY') &&
        todayDate.isAfter(threePM)) ||
      disableDays.includes(moment(current.$d).format('dddd')) ||
      unavaliableDates.some(
        (date) => moment(date.date).format('MM-DD-YYYY') === moment(current.$d).format('MM-DD-YYYY')
      )
    );
  };

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 4: Delivery Details
        </h2>
        <Card bordered={false} className="w-100 pl-3 pr-3 pt-3">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={intialFormValues}
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_date"
                    label="Delivery Date"
                    rules={[{ required: true, message: 'Please choose a delivery date' }]}>
                    <DatePicker
                      className="w-100"
                      size="large"
                      format="MM-DD-YYYY"
                      disabledDate={disableDate}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Form.Item>
                </Col>
                {/* <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Form.Item name="rental" label="Rental Period" rules={[{ required: true, message: "Please select a rental period" }]}>
                      <Select size="large" defaultValue={selectedRentalPeriod.period}>

                        {
                          rentalPeriods.map((obj) => {
                            return (<Select.Option value={obj.id} key={obj.id}>{obj.period}</Select.Option>)
                          })
                        }

                      </Select>
                    </Form.Item>
                  </Col> */}
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_1"
                    label="Delivery Window 1"
                    rules={[{ required: true, message: 'Please choose a delivery window 1' }]}>
                    <Select
                      onClick={handleEventClick}
                      size="large"
                      placeholder="Select a time slot"
                      value={timingOne}
                      onChange={handleTimingOneChange}>
                      {deliveryWindows1?.map((options, index) => {
                        return (
                          <Select.Option
                            key={`timing_one_${index}`}
                            hidden={options?.id == timingTwo}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_2"
                    label="Delivery Window 2"
                    rules={[{ required: true, message: 'Please choose a delivery window 2' }]}>
                    <Select
                      onClick={handleEventClick}
                      size="large"
                      placeholder="Select a time slot"
                      onChange={handleTimingTwoChange}
                      value={timingTwo}
                      disabled={!timingOne}>
                      {deliveryWindows2?.map((options, index) => {
                        return (
                          <Select.Option
                            hidden={options?.id == timingOne}
                            key={`timing_two_${index}`}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className="mandatory-field"
                    name="delivery_address"
                    label="Delivery Address">
                    <GoogleAutoComplete
                      className={
                        deliveryAddressError && !deliveryAddress
                          ? 'form-control delivery-address-field-error '
                          : 'form-control'
                      }
                      apiKey={apiKey}
                      onPlaceSelected={(place) => {
                        setAddress(place);
                      }}
                      onChange={(e) => {
                        onAddresschange(e);
                      }}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                      defaultValue={deliveryInfo?.delivery_address}
                    />
                    <p className="mb-0 ant-form-item-explain-error">
                      {zipCodeError ? zipCodeError : null}
                    </p>
                    <p className="mb-0 ant-form-item-explain-error">
                      {deliveryAddressError && !deliveryAddress ? deliveryAddressError : ''}
                    </p>
                    <p className="mb-0 text-success">
                      {!zipCodeError && deliveryAddress ? 'Services available in this area' : null}
                    </p>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="apt_number" label="Apt. #" rules={[{ required: false }]}>
                    {/* <Input size="large" placeholder="Apt" /> */}
                    <InputNumber
                      className="w-100"
                      placeholder="Apt."
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className='tool-tip'
                    name="extra_work"
                    size="large"
                    label={
                      <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "10px" }}>
                        <span>Do you have stairs or an elevator? </span>
                        <Popover content={content}>
                          <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                        </Popover>
                      </div>}
                    rules={[{ required: true, message: 'Please select an option from below' }]}>
                    <Select size="large" onClick={handleEventClick} onChange={onStairsSelected}>
                      {extraWork?.map((obj, index) => {
                        return (
                          <Select.Option
                            className="mobile-wide-option"
                            key={`extarwork_${index}`}
                            value={obj?.id}>{`${obj.title} ($${obj.price})`}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="description"
                    label="Is there anything else we should know?"
                    rules={[{ required: false }]}>
                    <TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10 p-2">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={prevStep}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
            loading={isFormSubmitted}
            size={'large'}>
            {!isFormSubmitted ? 'Next Step' : 'Submitting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

DeliveryDetails.propTypes = {
  isParentNextStepClicked: PropTypes.any,
  tabref: PropTypes.any,
  setParentNextStepClicked: PropTypes.func
};

export default DeliveryDetails;
