import React, { useEffect, useState } from 'react';
import { storageBoxPrices } from '../data/API';
import { message } from 'antd';

const useGetPrice = () => {
  const [boxPrices, setBoxPrices] = useState(null);

  const getPrices = async () => {
    try {
      let response = await storageBoxPrices();

      if (response?.data) {
        setBoxPrices(response?.data?.results)
      }
    } catch (error) {
      return message.error('Error fetching Inventory', error);
    }
  };

  useEffect(() => {
    getPrices();
  }, []);

  return {
    boxPrices
  };
};

export default useGetPrice;
