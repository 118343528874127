import { message } from 'antd';
import { useEffect, useState } from 'react';
import { getInventory } from '../data/API';
import { getMyData } from '../utils/token';

const useInventory = () => {
  const [inventoryList, setInventoryList] = useState([]);
  let user = getMyData();

  const getCustomerInventory = async () => {
    try {
        let response = await getInventory(user?.id)
        
        if(response?.data) {
            const inventory = response?.data;
            setInventoryList(inventory?.data);
        }
    } catch (error) {
        return message.error("Error fetching Inventory",error)
    }
  };

  useEffect(() => {
    getCustomerInventory()
  }, [])

  return {
    inventoryList
  };
};

export default useInventory;
