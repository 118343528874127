import * as actionTypes from './constants';

//Form validation Actions
export const changeDeliveryFormValidation = (payload) => ({
  type: actionTypes.CHANGE_DELIVERY_FORM_VALIDATION,
  data: payload
});

export const changePickupFormValidation = (payload) => ({
    type: actionTypes.CHANGE_PICKUP_FORM_VALIDATION,
    data: payload
});

//Category Action
export const setCategory = (payload) => ({
  type: actionTypes.SET_CATEGORY,
  data: payload
});

//Rental Actions
export const setRentalId = (payload) => ({
  type: actionTypes.SET_RENTAL_ID,
  data: payload
});

export const setRentalPeriod = (payload) => ({
  type: actionTypes.SET_RENTAL_PERIOD,
  data: payload
});


