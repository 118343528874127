import React from 'react';
import ZipCode from '../Home/ZipModal';
import { getSelectMovings } from '../../data/API';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";

export default function Index({ storeStatus, storeStatusMessage }) {
  const [openZipModal, setZipModal] = React.useState(false);

  const hideZipM = () => {
    setZipModal(false);
  };

  const showZipModal = () => {
    setZipModal(true);
  };

  const [toggleModal, setToggleModal] = React.useState(true);
  const [movingProductss, setMovingProductss] = React.useState([]);

  const callAPI = () => {
    getSelectMovings('Home', 'Moving Supplies')
      .then((res) => {
        setMovingProductss(res.data);

        //console.log(moving_products);
      })
      .catch((e) => console.log(e));
  };

  React.useEffect(() => {
    callAPI();
  }, []);
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }
  return (
    <>
        <Helmet>
            <title>Moving Supplies | BlueBox</title>
            <meta name="description" content="We rent moving supplies in the San Francisco Bay Area, customized to fit your blue boxes. Everything you need for an easy, stress-free move!" />
            <meta name="keywords" content="moving supplies, moving supplies San francisco, moving supplies San Jose, moving supplies Oakland" />
        </Helmet>
      <ZipCode
        showModal={openZipModal}
        hideModal={hideZipM}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
      <section className="hero move-movingsupply position-relative">
        <div className="container">
          <div className="text-center">
            <h1
              className="text-white aos-init aos-animate"
            >
              <span className="font-weight-bold">Moving supplies</span>
            </h1>
            <button
              className="btn btn-primary px-4 mt-2 aos-init aos-animate"
              onClick={() => {
                showZipModal();
                setToggleModal(true);
              }}>
              Order Now
            </button>
          </div>
        </div>
      </section>
      <section className="moving-supplies">
        <h2 className="text-center page-head">Everything you need for an easy, stress-free move</h2>

        {movingProductss?.results?.map((moving) => {
          //  console.log(moving)

          return (
            <>
              <div className="row">
                <div className="small-12 large-10 large-offset-1 end" id="supplies-list">
                  <div className="product">
                    <div className="row">
                      <div className="columns small-12 medium-6 large-4 product-image">
                        <img src={moving.image} alt="" style={{ maxHeight: 280 }} />
                      </div>
                      <div className=" text-center columns small-12 medium-6 large-8">
                        <h3>{moving.title}</h3>
                        <span className="product-price">
                          ${moving.price} {moving.unit}
                        </span>
                        <div className="product-description">
                          <ul className="mt-3 checklist">
                            <div className="checklist-list"
                              dangerouslySetInnerHTML={{
                                __html: moving.description
                              }}></div>
                          </ul>
                        </div>
                        <div
                          className="row order-now-btn"
                          style={{
                            justifyContent: 'center'
                          }}>
                          <a
                            className="btn btn-dark mx-2 "
                            onClick={() => {
                              showZipModal();
                              setToggleModal(true);
                            }}>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
}

Index.propTypes = {
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
