import { Button, Checkbox, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getStorageTerms, updateCustomer } from '../../../../data/API';

import './index.css';

const StoragePolicy = () => {
  const [termslist, setTermsList] = useState([]);
  const [content, setContent] = useState('');
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { customer } = useSelector((state) => state.storage);

  const [agreedTerms, setAgreedTerms] = useState(false);

  useEffect(() => {
    (async () => {
      const content = await getStorageTerms();
      setContent(content.data.results[0].content);
    })();
  }, []);

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    setTermsList(Array.from(tempElement.getElementsByTagName('li')).map((li) => li.innerText));
  }, [content]);

  const onSubmit = async () => {
    if (agreedTerms) {
      let response = await updateCustomer({ agreedTerms: true, email: customer.email });

      if (response?.data?.success) {
        navigate('/storage-box/buying-box');
      } else {
        message.error('Something went wrong...!');
      }
    } else {
      message.error('Please agree to BlueBox Terms of Service');
    }
  };

  const onAgreedTerms = async (e) => {
    setAgreedTerms(e.target.checked);
  };
  return (
    <Container className="py-5 storage-bg" fluid={true}>
      <Row className="justify-content-center">
        <Col xxl={7} xl={7} lg={7} md={8} sm={10} xs={11} className="p-0">
          <div className="storage-content">
            {/* <p className="mb-0 s-color-black sp-header fw-600">Storage Policy</p> */}

            <div className="storage-policy-terms w-100 my-2 px-3 py-2">
              {termslist.map((item, index) => (
                <p className="policy-content fw-400" key={index}>
                  {item}
                </p>
              ))}
              {/* <p className="policy-content fw-400">Storage boxes are $12/box/month.</p>
              <p className="policy-content fw-400">
                There is no minimum number of boxes you can store with us—you can store only one if
                you choose. However, there is a three month storage minimum.{' '}
              </p>
              <p className="policy-content fw-400">
                You can cancel anytime within the three month period, but you will be charged
                upfront for the first three months of storage then month-to-month starting month
                four. Your card will be charged monthly (every 30 days) at the beginning of the
                fourth month and so on as long as you continue storage service.{' '}
              </p>
              <p className="policy-content fw-400">
                If you’d like to have any items returned, just login to your online account and
                request the box you’d like delivered. Please note, for security purposes, we will
                only return
              </p> */}
            </div>

            <div className="storage-agree-terms py-3">
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                  agree: false
                }}>
                <Form.Item
                  name="agree"
                  rules={[{ required: true, message: 'Please agree terms and conditions' }]}
                  noStyle>
                  <Checkbox
                    className="agree-lable"
                    onChange={(e) => {
                      onAgreedTerms(e);
                    }}>
                    I agree to{' '}
                    <span onClick={() => navigate('/Terms')} className="agree-term">
                      Bluebox terms
                    </span>{' '}
                    of service
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <div className="d-flex align-items-center justify-content-end py-3">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="signup-register-btn"
                      block={window.innerWidth < 425.98}
                      onClick={onSubmit}>
                      Request boxes
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StoragePolicy;
