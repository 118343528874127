export const setToken = (token) => {
    localStorage.setItem("token", token)
};

export const getToken = () => {
    return localStorage.getItem("token");
};

export const setMyData = (data) => {
    localStorage.setItem("authData", JSON.stringify(data));
};

export const getMyData = () => {
    return JSON.parse(localStorage.getItem("authData"))
}

export const clearUser = () => {
    localStorage.clear()
}