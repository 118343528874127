import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

// eslint-disable-next-line react/prop-types
const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page) => {
        if (page !== currentPage) {
            onPageChange(page);
        }
    };

    const renderPageItems = () => {
        const items = [];

        // Previous Page
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            > <i className="fas fa-arrow-alt-circle-left"></i></Pagination.Prev>
        );

        // Page Numbers
        for (let i = 1; i <= totalPages; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    activeLabel={""}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Next Page
        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            > <i className="fas fa-arrow-circle-right"></i></Pagination.Next>
        );

        return items;
    };

    if (totalPages <= 1) {
        // Don't render pagination if there's only one page
        return null;
    }

    return (
        <Pagination>
            {renderPageItems()}
        </Pagination>
    );
};

export default PaginationComponent;
