import * as actionTypes from './constants';

const defaultState = {
    delivery_form_validation : {
        delivery_date : false,
        rental_period : true,
        delivery_window1 : false,
        delivery_window2 : false,
        delivery_address : false,
        extra_work : false,
        no_error: false,
    },
    pickup_form_validation : {
        pickup_window1 : false,
        pickup_window2 : false,
        pickup_address : false,
        extra_work : false,
        no_error: false,
    },
    category: "Home",
    rental_box: {
        rental_id : null,
        rental_period : null
    } 
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case actionTypes.CHANGE_DELIVERY_FORM_VALIDATION:
        return {...state, delivery_form_validation: { ...state.delivery_form_validation, ...action.data}};
    case actionTypes.CHANGE_PICKUP_FORM_VALIDATION:
        return {...state, pickup_form_validation: { ...state.pickup_form_validation, ...action.data}};
    case actionTypes.SET_CATEGORY:
        return {...state, category: action.data};
    case actionTypes.SET_RENTAL_ID:
        return {...state, rental_box: {...state.rental_box, rental_id: action.data}};
    case actionTypes.SET_RENTAL_PERIOD:
        return {...state, rental_box: {...state.rental_box, rental_period: action.data}};
    default:
      return state;
  }
}