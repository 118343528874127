import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Form, Image, Input, message, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '../../../../assets/images/boxfinal.png';
// import './index.css';
import PropTypes from 'prop-types';


const PaymentDetails = ({ tabref }) => {
  const [form] = Form.useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const onFinish = (val) => {
    message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  useEffect(() => {
    window.scrollTo({
      top: tabref?.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, [])

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 4: Payment Details
        </h2>
        <Card bordered={false} className="w-100 px-4 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Row>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <p className='sp-header s-color-black fw-600'>Cost breakdown</p>
                <p className='agree-lable s-color-black fw-400 mb-0'>Pay now: <span className='fw-600 amount'>120$</span>(3 months)</p>
                <p className='agree-lable s-color-black fw-400'>Monthly payment <span className='fw-600 amount'>40$</span>(starting on March 6th)</p>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <p className='sp-header s-color-black fw-600'>Payment Details</p>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  //   initialValues={intialFormValues}
                  style={{ position: 'relative', zIndex: 0 }}>
                  <Form.Item
                    name="name"
                    label="Name on card"
                    rules={[{ required: true, message: 'Please enter the name' }]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                    />
                  </Form.Item>

                  <Form.Item
                    name="card_detail"
                    label="Card details"
                    rules={[{ required: true, message: 'Please enter the card details' }]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                    />
                  </Form.Item>

                  <Form.Item
                    name="zip_code"
                    label="Zip Code"
                    rules={[{ required: true, message: 'Please enter the zipcode' }]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                    />
                  </Form.Item>

                  <p className='policy-content fw-400 s-color-black'>You can update your payment details anytime on Your Account page</p>
                </Form>
              </Col>
            </Row>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={console.log}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            onClick={console.log}
            loading={isFormSubmitted}
            size={'large'}>
            {!isFormSubmitted ? 'Next Step' : 'Submitting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;

PaymentDetails.propTypes = {
  tabref: PropTypes.any
};
