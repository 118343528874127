import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HiwSteps from './Steps';
import HiwHeader from './Header';
import StoragePolicy from './Policy';

const HowItWorks = (props) => {
  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  return (
    <>
      <HiwHeader />
      <HiwSteps />
      <StoragePolicy />
    </>
  );
};

export default HowItWorks;

HowItWorks.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
