import { Button, Checkbox, Image, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row } from 'react-bootstrap';
import BoxFinal from '../../../../assets/images/boxopenfinal.png';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { setRequestedBoxes } from '../../store/actionCreators';

const Inventory = ({ inventoryList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { deliveryOrder } = useSelector((state) => state.storage);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (deliveryOrder && deliveryOrder?.requestedBoxes) {
      setSelected(deliveryOrder?.requestedBoxes?.map((item) => item?.id));
    }
  }, [deliveryOrder]);
  console.log('__i_list', inventoryList);
  const handleSelect = (event) => {
    var updatedList = [...selected];
    if (event.target.checked) {
      updatedList = [...selected, event.target.value];
    } else {
      updatedList.splice(selected.indexOf(event.target.value), 1);
    }
    setSelected(updatedList);
  };

  const handleSingleBox = (id) => {
    let selectedBoxes = inventoryList
      .filter((obj) => {
        return id == obj?.id;
      })
      .map((box) => {
        return {
          storage_inventory_id: box.id,
          box_id: box.box_id,
          box_number: box.storageBox.box_number
        };
      });

    if (selectedBoxes?.length) {
      dispatch(setRequestedBoxes(selectedBoxes));
      navigate('/storage-box/my-account/request-delivery');
    } else {
      message.error('Please select at least one box');
    }
  };

  const handleSubmitBoxes = async () => {
    let selectedBoxes = inventoryList
      .filter((obj) => {
        return selected.some((box) => box == obj?.id);
      })
      .map((box) => {
        return {
          storage_inventory_id: box.id,
          box_id: box.box_id,
          box_number: box.storageBox.box_number
        };
      });

    if (selectedBoxes?.length) {
      dispatch(setRequestedBoxes(selectedBoxes));
      navigate('/storage-box/my-account/request-delivery');
    } else {
      message.error('Please select at least one box');
    }
  };

  return (
    <div className="user-inventory py-3 px-3 s-color-black">
      <Checkbox.Group
        style={{
          width: '100%'
        }}>
        <Container>
          <Row className="align-items-center justify-content-start inv-list">
            {inventoryList.map((item, index) => {
              return (
                <Col
                  xxl={4}
                  xl={4}
                  lg={5}
                  md={6}
                  sm={8}
                  xs={12}
                  className="p-0"
                  key={`inventory_box_${index + 1}`}>
                  <div className="inventory-card p-3 mb-3">
                    <div className="d-flex align-items-center justify-content-end inv-select-box">
                      {selected.some((box) => box == item?.id) ? (
                        <p className="mb-0 px-2 color-secondary inv-checkbox">Selected</p>
                      ) : (
                        <p className="mb-0 px-2 color-gray inv-checkbox">Select</p>
                      )}
                      <Checkbox value={item?.id} onChange={handleSelect}></Checkbox>
                    </div>

                    <div className="d-flex justify-content-start align-items-center">
                      <div className="inventory-img">
                        <Image
                          src={item.content_image ? item.content_image : BoxFinal}
                          preview={false}
                          className="inv-img"
                          alt="inventory_preview"
                        />
                      </div>
                      <div className="inventory-content ml-3">
                        <div className="w-100">
                          <p className="mb-1 inv-box-head fw-600">
                            Box nr {item.storageBox?.box_number}
                          </p>
                          <Button
                            type="primary"
                            size="large"
                            className="inventory-btn mb-2 fw-600 w-100"
                            ghost
                            block>
                            Box content
                          </Button>

                          {item.delivery_order_id ? (
                            <Button 
                              disabled = 'true' 
                              // onClick={() => handleSingleBox(item?.id)}
                              type="primary"
                              size="large"
                              className="inventory-req fw-600 w-100 bg-theme-secondary opacity05"
                              block>
                              Requested to 25.10.2024
                            </Button>
                          ) : (
                            <Button
                              onClick={() => handleSingleBox(item?.id)}
                              type="primary"
                              size="large"
                              className="inventory-req fw-600 w-100 bg-theme-secondary"
                              block>
                              Request Delivery
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          {selected.length > 0 ? (
            <Row>
              <Col className="request-sel">
                <Button
                  type="primary"
                  size="large"
                  className="fw-600 more-btn justify-content-end"
                  onClick={handleSubmitBoxes}>
                  Request Selected
                </Button>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </Container>
      </Checkbox.Group>
    </div>
  );
};

export default Inventory;

Inventory.propTypes = {
  inventoryList: PropTypes.any
};
