import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { validateOrderId, getStorageOrder } from '../../data/API';
import { ReactSession } from 'react-client-session';
import uuid from 'react-uuid';
import { resetStore } from '../StorageBox/store/actionCreators';

export default function index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order_Id = useSelector((state) => state.package.orderId);
  const [isValidOrder, setIsValidOrder] = useState(true);
  let { orderId } = useParams();
  orderId = order_Id ?? orderId;

  const getorderFunc = async (id) => {
    try {
      let orderStatus = await validateOrderId(id);

      if (!orderStatus?.data?.valid) {
        orderStatus = await getStorageOrder(id);
        if (orderStatus == null) {
          navigate('/404');
        }
      } else {
        ReactSession.set('session', uuid());
      }
      dispatch({ type: 'RESET_REDUX_STORE' });
      dispatch(resetStore());
    } catch (error) {
      navigate('/404');
    }
  };

  useEffect(() => {
    getorderFunc(orderId);
  }, [orderId]);

  return (
    orderId && (
      <>
        <section className="order-confirmation-section">
          <img
            src="img/order-confirmation.svg"
            className="img-fluid confirm-img-desktop"
            style={{ width: '100%' }}
          />
          <img
            src="img/order-confirmation-tab.svg"
            className="img-fluid confirm-img-tab"
            style={{ width: '100%' }}
          />
          <img
            src="img/order-confirmation-mobile.svg"
            className="img-fluid confirm-img-mobile"
            style={{ width: '100%' }}
          />

          <p className="confirmation-title">
            Thank you for
            <br /> your order!
          </p>
        </section>

        <div className="confirmation-content">
          <div className="content-box ">
            <p>Your Order Id:</p>
            <p className="order-id color-secondary fw-700">{orderId}</p>

            <p className="page-titles">
              You should receive an email and a text message with all the details of your order.
            </p>
            <p className="page-titles">
              We will send you a text message the day before your delivery and pickup dates
              confirming your drop off and pick up time frames
            </p>
            <p className="page-titles">
              If you have any questions or need to change anything, please contact us by one of the
              methods below:
            </p>

            <div className="row d-flex " style={{ alignItems: 'center' }}>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 mb-3 support-icon align-content-center">
                <img src="img/icon-phone.svg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8 mb-3 support-title">
                <a
                  className="p-0 support-link"
                  style={{ fontWeight: '400' }}
                  href="tel:650-502-9522">
                  650-502-9522
                </a>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 mb-3 support-icon align-content-center">
                <img src="img/icon-email.svg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8 mb-3 support-title">
                <a
                  className="p-0 support-link"
                  style={{ fontWeight: '400' }}
                  href="mailto:info@bluebox.rent">
                  info@bluebox.rent
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
