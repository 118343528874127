import "./index.css"
import { useNavigate } from "react-router-dom";

export default function index(props) {

    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    }

    return (
        <>


            <div className="page-wrap d-flex flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block title-404">404</span>
                            <div className="mb-4 lead">
                                <span className="color-secondary tittle-1">Oops! </span>
                                <span className="color-primary title-2">The page you are looking for was not found.</span></div>
                            <button className="btn  redirect-btn" onClick={goHome}> Go Home</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}