import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Select,
  Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { setStepCount, setPersonalDetails } from '../../store/actionCreators';

import { storageStripeCheckoutSession, placeStorageOrder } from '../../../../data/API';
import { getMyData } from '../../../../utils/token';
import useGetPrice from '../../../../hooks/useGetPrice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

const PersonalDetails = ({
  tabref,
  isParentNextStepClicked,
  setParentNextStepClicked,
  stepRef,
  extraWork,
  customerInfo
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isForFuture, setIsForFuture] = useState(false);
  const { order, customerr } = useSelector((state) => state.storage);
  const [showValidation, setShowValidation] = useState(false);
  const { boxPrices } = useGetPrice();
  const currentStep = useSelector((state) => state.storage.currentStep);
  const { personalDetails } = useSelector((state) => state.storage.order);
  const userInfo = getMyData();

  const customer = JSON.parse(localStorage.getItem('authData'));

  useEffect(() => {
    if (userInfo && Object.keys(personalDetails).length == 0) {
      form.setFieldsValue({
        name: userInfo?.name,
        email: userInfo?.email
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: tabref?.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (personalDetails) {
      form.setFieldsValue({
        name: userInfo ? userInfo?.name : personalDetails?.name ? personalDetails?.name : '',
        email: userInfo ? userInfo?.email : personalDetails?.email ? personalDetails?.email : '',
        mobile: personalDetails?.mobile ? formatPhoneNumber(personalDetails?.mobile) : '',
        secondary_mobile: personalDetails?.secondary_mobile
          ? formatPhoneNumber(personalDetails?.secondary_mobile)
          : '',
        coupon: personalDetails?.coupon ? personalDetails?.coupon : '',
        isForFuture: personalDetails?.isForFuture ? personalDetails?.isForFuture : false
      });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (customerInfo) {
      form.setFieldsValue({
        mobile: formatPhoneNumber(customerInfo?.custormerData?.mobile),
        secondary_mobile: formatPhoneNumber(customerInfo?.custormerData?.secondary_mobile)
      });
    }
  }, [customerInfo]);

  const nextStep = () => {
    dispatch(setStepCount(currentStep + 1));
  };

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 3) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          onSubmit();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  const onSubmit = async () => {
    let formValues = await form.validateFields();

    // formValues["isForFuture"] = isForFuture;

    if (formValues?.mobile) {
      formValues['mobile'] = formValues.mobile
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '');
    }

    if (formValues?.secondary_mobile) {
      formValues['secondary_mobile'] = formValues.secondary_mobile
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '');
    }

    setIsFormSubmitted(true);

    dispatch(setPersonalDetails(formValues));

    let orderObj = {
      boxCount: order.boxCount,
      customer_id: customer.id,
      deliveryDetails: order.deliveryDetails,
      personalDetails: formValues
    };

    let extraCost = 0;

    if (order?.deliveryDetails?.extra_work_id) {
      let extraWorkObj = _.find(extraWork, { id: order.deliveryDetails.extra_work_id });
      if (extraWorkObj) {
        extraCost = Number(extraWorkObj.price);
      }
    }

    orderObj.customer_id = customer.id;
    orderObj.total_amount = order.boxCount * Number(boxPrices[0]?.price) * 3 + extraCost; //BOX_CALCULATION
    orderObj.boxes_amount = order.boxCount * Number(boxPrices[0]?.price) * 3; //BOX_CALCULATION

    orderObj.order_date = new Date();
    let orderResponse = await placeStorageOrder(orderObj);

    // console.log(orderResponse);

    if (orderResponse) {
      let sessionPayload = {
        order_id: orderResponse.data.data.id,
        amount: orderObj.total_amount,
        discount: 0,
        payment: {
          billing_details: {
            email: formValues.email
          }
        }
      };

      let sessionRes = await storageStripeCheckoutSession(sessionPayload);

      if (sessionRes?.data?.success) {
        const stripe = await stripePromise;

        const result = await stripe.redirectToCheckout({
          sessionId: sessionRes.data.data.session
        });

        setIsFormSubmitted(false);

        console.log(result);
      } else {
        message.error('Unable to place order, Please try again...!');
        setIsFormSubmitted(false);
      }
    } else {
      message.error('Unable to place order, Please try again...!');
      setIsFormSubmitted(false);
    }
  };

  const validateMobileNumber = (e) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNum = value.replace(/[^\d]/g, '');
    const phoneLen = phoneNum.length;

    if (phoneLen < 4) return phoneNum;

    if (phoneLen < 7) {
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }

    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  };

  const handlePrimary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      mobile: formattedPhone
    });
    handlePersonalDetailsRedux('mobile', formattedPhone);
  };

  const handleSecondary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      secondary_mobile: formattedPhone
    });
    handlePersonalDetailsRedux('secondary_mobile', formattedPhone);
  };

  const handlePersonalDetailsRedux = (type, e) => {
    switch (type) {
      case 'name':
        form.setFieldsValue({
          name: e.target.value
        });
        break;
      case 'email':
        form.setFieldsValue({
          email: e.target.value
        });
        break;
      case 'mobile':
        form.setFieldsValue({
          mobile: e
        });
        break;
      case 'secondary_mobile':
        form.setFieldsValue({
          secondary_mobile: e
        });
        break;
      case 'coupon':
        form.setFieldsValue({
          coupon: e.target.value
        });
        break;
      case 'isForFuture':
        form.setFieldsValue({
          isForFuture: e.target.checked
        });
        break;
    }

    dispatch(setPersonalDetails(form.getFieldsValue()));
  };

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 3: Personal Details
        </h2>
        <Card bordered={false} className="w-100 px-3 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                isForFuture: personalDetails?.isForFuture ? personalDetails?.isForFuture : false
              }}
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please enter the name' }]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      onChange={(e) => handlePersonalDetailsRedux('name', e)}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the email address' },
                      { type: 'email', message: 'Please enter a valid email address' }
                    ]}
                    validateStatus={showValidation ? 'success' : ''}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      onChange={(e) => {
                        setShowValidation(true);
                        let el = document.getElementById('email_help');
                        if (el) {
                          el.classList.add('d-none');
                        }
                        handlePersonalDetailsRedux('email', e);
                      }}
                      onBlur={() => {
                        setShowValidation(false);
                        let el = document.getElementById('email_help');
                        if (el) {
                          el.classList.remove('d-none');
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="mobile"
                    label="Mobile Number"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the mobile number', min: 14 }
                    ]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => {
                        handlePrimary(e);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="secondary_mobile"
                    label="Secondary phone number"
                    validateTrigger="onBlur"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue('secondary_phone_number') &&
                            getFieldValue('secondary_phone_number').length != 14
                          ) {
                            return Promise.reject('Please enter valid number'); // The validator should always return a promise on both success and error
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => {
                        handleSecondary(e);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="coupon" label="If you have a coupon, enter it below">
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      onChange={(e) => handlePersonalDetailsRedux('coupon', e)}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}></Col>
              </Row> */}
              {/* removing checbox */}
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="isForFuture" noStyle valuePropName="checked">
                    <Checkbox
                      className="agree-lable"
                      onChange={(e) => {
                        handlePersonalDetailsRedux('isForFuture', e);
                      }}>
                      Save my details for future orders
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}></Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={prevStep}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            style={{ maxWidth: '250px' }}
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
            loading={isFormSubmitted}
            size={'large'}>
            {!isFormSubmitted ? 'Continue to payment' : 'Redirecting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

PersonalDetails.propTypes = {
  tabref: PropTypes.any,
  stepRef: PropTypes.any,
  isParentNextStepClicked: PropTypes.any,
  setParentNextStepClicked: PropTypes.any,
  deliveryWindows: PropTypes.any,
  extraWork: PropTypes.any,
  customerInfo: PropTypes.any
};
