import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSession } from 'react-client-session';

import { Col, Row } from 'react-bootstrap';
import { Button, Card, Skeleton, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import * as _ from 'lodash';
import './style.css';
import { getSelectMovings, addtoCart } from '../../../data/API';
import { setStepCount, setPackagingProducts } from '../store/actionCreators';
import moment from 'moment';

export default function index(props) {
  const dispatch = useDispatch();
  const session = ReactSession.get('session');

  const { category } = useSelector((state) => state.package.choosePackage);
  const currentStep = useSelector((state) => state.package.currentStep);
  const { selectedProducts } = useSelector((state) => state.package.packagingSupplies);
  const { selectedRentalPeriod } = useSelector((state) => state.package.choosePackage);

  const [packagingSupplies, setPackagingSupplies] = useState([]);

  const { isParentNextStepClicked, setParentNextStepClicked, stepRef } = props;

  const nextStep = () => {
    dispatch(setStepCount(currentStep + 1));
  };

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 2) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          nextStep();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  useEffect(() => {
    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const getPackagingSupplies = async () => {
    let packagingSuppliesRes = await getSelectMovings(category, 'Packing Supplies');

    if (packagingSuppliesRes?.data?.results) {
      packagingSuppliesRes.data.results.forEach((obj) => {
        obj.description = obj.description
          .replaceAll('</li>', ',')
          .replaceAll('&nbsp;', '')
          .replaceAll('\r\n', '')
          .replaceAll(/<\/?[^>]+(>|$)/g, '')
          .split(',');
      });

      if (selectedProducts.length > 0) {
        await syncProductsWithCart(packagingSuppliesRes?.data?.results);
      } else {
        setPackagingSupplies(packagingSuppliesRes?.data?.results);
      }
    }
  };

  const syncProductsWithCart = async (products) => {
    selectedProducts.map((item) => {
      products?.find((product) => {
        if (product.id == item?.id) {
          product['selectedQuantity'] = item?.quantity;
        }
      });
    });
    setPackagingSupplies([...products]);
  };

  useEffect(() => {
    let data = packagingSupplies;

    if (selectedProducts.length == 0) {
      _.each(data, (item) => {
        item.selectedQuantity = 0;
      });
      setPackagingSupplies([...data]);
    } else {
      data.forEach((item) => {
        let cartProduct = _.find(selectedProducts, { id: item.id });
        if (cartProduct) {
          item['selectedQuantity'] = cartProduct.quantity;
        } else {
          item['selectedQuantity'] = 0;
        }
      });

      setPackagingSupplies([...data]);
    }
  }, [selectedProducts]);

  const addProduct = async (index) => {
    if (packagingSupplies[index].selected) {
      packagingSupplies[index].quantity = packagingSupplies[index].quantity + 1;
    } else {
      packagingSupplies[index].selected = true;
    }
    let existingProdustIndex = _.findIndex(selectedProducts, { id: packagingSupplies[index].id });
    if (existingProdustIndex >= 0) {
      selectedProducts[existingProdustIndex] = packagingSupplies[index];
    } else {
      selectedProducts.push(packagingSupplies[index]);
    }

    let data = {
      product: packagingSupplies[index].id,
      quantity: 1,
      session: session,
      cart_main_category: 2,
      cart_sub_category: packagingSupplies[index].product_sub_category,
      rental: selectedRentalPeriod.id
    };

    let res = await addtoCart(data);

    if (res?.data?.id) {
      dispatch(setPackagingProducts(selectedProducts));
      setPackagingSupplies([...packagingSupplies]);
    } else message.error('Something went wrong while adding to cart. Please try again..!');
  };
  useEffect(() => {
    getPackagingSupplies(false);
  }, [category]);

  return (
    <>
      {/* Step 2 */}
      <div>
        <div className="steps bg-white" id="steps">
          <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
            Step 2: Packing Supplies
          </h2>
          <div className="row pl-2 pr-2">
            <div className="container px-5 my-4">
              {packagingSupplies?.length == 0 ? (
                <div className="row py-4 pt-0">
                  <div className="col-md-4 p-0" key={'1'}>
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0" key={'2'}>
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0" key={'3'}>
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row py-4 pt-0">
                {packagingSupplies.map((obj, index) => {
                  // console.log(obj, 'OBJ');
                  return (
                    <div key={obj.id} className="col-md-4 p-0 pkg d-flex mb-4 z-index-0">
                      <div className="card w-100">
                        <div className="product-header">
                          <p className="text-center mb-0  product-title color-primary fw-700">
                            {obj.title}
                          </p>
                        </div>

                        <div className="card-body">
                          <div className="product-image text-center">
                            <img src={obj.image} />
                          </div>
                          <div className="product-info text-center pl-2 pr-2">
                            <div className="mt-3">
                              <div className="text-left ml-4 mr-3">
                                {obj.description.map((desc, index) => {
                                  return (
                                    <p className="mb-0 fs-14" key={index} hidden={!desc.length}>
                                      <i className="fas fa-check color-primary"></i> {desc}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cardfooter mb-3">
                          <div className="product-price mb-2 mt-2 color-primary font-bold">
                            <p className="text-center mb-0 pt-2 pb-2 fs-20 fw-600">
                              {' '}
                              ${obj.price} {obj.unit}
                            </p>
                          </div>

                          {/* {obj?.stock > 0 ? (
                            <p className="mb-0 fs-14 px-3 py-2">
                              <span className="color-secondary font-bold">Available Stock : </span>
                              {obj?.stock}
                            </p>
                          ) : (
                            ''
                          )} */}
                          {obj?.stock == 0 && obj?.expected_stock_date ? (
                            <p className="mb-0 fs-14 px-3 py-2">
                              <span className="color-secondary font-bold">Available From: </span>
                              {moment(obj?.expected_stock_date).format('DD-MM-YYYY')}
                            </p>
                          ) : (
                            ''
                          )}

                          <div className="text-center mb-3">
                            {obj?.stock > 0 && parseInt(obj?.selectedQuantity ?? 0) < obj?.stock ? (
                              obj?.selectedQuantity ? (
                                <button
                                  className="btn product-added-btn br-5 fs-15  width-90p border-0  text-center "
                                  onClick={() => {
                                    addProduct(index);
                                  }}>
                                  <img src="img/Add-Blue.png" />
                                  <button className="circle">
                                    <p className="mb-0 pb-0">{obj?.selectedQuantity}</p>
                                  </button>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger br-5 fs-15  width-90p border-0 bg-theme-secondary text-center"
                                  onClick={() => {
                                    addProduct(index);
                                  }}>
                                  <img src="img/Add.png" />
                                </button>
                              )
                            ) : (
                              <Button type="primary" danger ghost disabled >
                                OUT OF STOCK
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-10 p-2">
          <div className="col  d-flex justify-content-start align-items-center">
            <Button
              className="btn-previous-step"
              icon={<ArrowLeftOutlined />}
              size={'large'}
              onClick={prevStep}>
              Previous Step
            </Button>
          </div>
          <div className="col  d-flex justify-content-end align-items-center">
            <Button
              className="btn-next-step"
              icon={<ArrowRightOutlined />}
              size={'large'}
              onClick={nextStep}>
              Next Step
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
