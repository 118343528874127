import React, { useState } from 'react';
import offer from '../../assets/images/box_offer.png';
import PropTypes from 'prop-types';
import './Popup.css';
import { Modal, Button } from 'antd';

const Index = ({ isVisible, onClose, popupData }) => {
  return (
    <>
      <Modal open={popupData.length ? isVisible : undefined} onCancel={onClose} footer={null} className="promoModal">
        <div className="promoPopup">
          <div className="promoPopup-img">
            <img src={offer} className="img-fluid" alt="offer-image" />
          </div>
          <div className="promoPopup-content">
            <h5 className="promoPopup-title">{popupData[0]?.description} </h5>
            <p className="promoPopup-text">Just use this promo code at checkout.</p>
            <button className="promoPopup-btn">{popupData[0]?.code}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

Index.propTypes = {
  isVisible: PropTypes.any,
  onClose: PropTypes.any,
  popupData: PropTypes.any,
};

export default Index;
