import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Button, Card, InputNumber, message } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { ReactSession } from 'react-client-session';
import {
  setPackagingProducts,
  setMovingSuppliesProducts,
  setStepCount,
  resetReduxStore,
  setChangePackage
} from '../store/actionCreators';
import RenderIf from '../../../Utility/RenderIf';

import { getTotal, addtoCart, clearCart, getTax } from '../../../data/API';

import './style.css';
import moment from 'moment';

export default function index(props) {
  const dispatch = useDispatch();
  const session = ReactSession.get('session');
  const [executing, setExecuting] = useState(false);

  const { selectedPackage, selectedRentalPeriod } = useSelector(
    (state) => state.package.choosePackage
  );
  const selectedPackagingProducts = useSelector(
    (state) => state.package.packagingSupplies.selectedProducts
  );

  const selectedMovingSuppliesProducts = useSelector(
    (state) => state.package.movingSupplies.selectedProducts
  );

  const discount = useSelector((state) => state.package.discount);
  const deliveryInfo = useSelector((state) => state.package.deliveryDetails.deliveryInfo);
  const pickupInfo = useSelector((state) => state.package.pickupDetails.pickupInfo);

  const deliveryDateString = moment(deliveryInfo?.delivery_date).format('MMM DD, YYYY (dddd)');

  const [totalCartValue, setTotalCartValue] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [extraCost, setExtraCost] = useState(0);
  const [Totalbill, setTotalBill] = useState(0);
  const [tax, setTax] = useState(0)
  const { setOrderSummaryOpen } = props;

useEffect(() => {
    const fetchTax = async () => {
      try {
        const res = await getTax();
        const fetchedTax = res?.data?.results[0]?.tax;
        setTax(fetchedTax);
        localStorage.setItem('tax', fetchedTax);
      } catch (error) {
        console.error('Error fetching tax:', error);
      }
    };

const storedTax = localStorage.getItem('tax');
    if (storedTax) {
      setTax(parseFloat(storedTax));
    } else {
      fetchTax();
    }
  }, []);


useEffect(() => {
    calculateTotal();
  }, [tax, selectedPackage, selectedPackagingProducts, selectedMovingSuppliesProducts, selectedRentalPeriod]);


const calculateTotal = () => {
    let Pakageprice = selectedPackage.price;
    let packingProductPrice = 0;
    let movingsupplyproducttotal = 0;
    selectedPackagingProducts.forEach((element) => {
      if (element) {
        packingProductPrice += element.price * element.quantity;
      }
    });
    selectedMovingSuppliesProducts.forEach((res) => {
      if (res) {
        movingsupplyproducttotal +=
          res.quantity * res.price * Number(selectedRentalPeriod?.period?.split(' ')[0]);
      }
    });

    let Total = Pakageprice + packingProductPrice + movingsupplyproducttotal || 0;
    let SalesTax = ((tax * packingProductPrice) / 100);
    let STax = (Math.round(SalesTax * 100) / 100).toFixed(2);
    setTotalTax(STax);
    let TotalwithTax = SalesTax + Total;
    const totalwithTax = (Math.round(TotalwithTax * 100) / 100).toFixed(2);
    setTotalBill(totalwithTax);
    localStorage.setItem('total', totalwithTax);
  };

  const increaseQuantity = async (index, type) => {
    if (type == 'PACKAGE_SUPPLIES') {
      let tempProduct = { ...selectedPackagingProducts[index] };
      tempProduct.quantity = tempProduct.quantity + 1;

      let cartRes = await addToCart(tempProduct, 'INC');

      if (cartRes?.data?.id) {
        selectedPackagingProducts[index].quantity = selectedPackagingProducts[index].quantity + 1;
        dispatch(setPackagingProducts([...selectedPackagingProducts]));
      } else {
        message.error('Something went wrong while adding product. Please try again...!');
      }
    }
    if (type == 'MOVING_SUPPLIES') {
      let tempProduct = { ...selectedMovingSuppliesProducts[index] };
      tempProduct.quantity = tempProduct.quantity + 1;

      let cartRes = await addToCart(tempProduct, 'INC');

      if (cartRes?.data?.id) {
        selectedMovingSuppliesProducts[index].quantity =
          selectedMovingSuppliesProducts[index].quantity + 1;
        dispatch(setMovingSuppliesProducts([...selectedMovingSuppliesProducts]));
      } else {
        message.error('Something went wrong while adding product. Please try again...!');
      }
    }
  };
  const decreaseQuantity = async (index, type) => {
    if (executing) return;
    setExecuting(true);
    if (type == 'PACKAGE_SUPPLIES') {
      let tempProduct = { ...selectedPackagingProducts[index] };
      if (tempProduct.quantity < 0) return;
      tempProduct.quantity = tempProduct.quantity - 1;

      let cartRes = await addToCart(tempProduct, 'DEC');

      if (cartRes?.data?.id) {
        selectedPackagingProducts[index].quantity = selectedPackagingProducts[index].quantity - 1;
        if (selectedPackagingProducts[index].quantity == 0) {
          selectedPackagingProducts.splice(index, 1);
        }
        dispatch(setPackagingProducts([...selectedPackagingProducts]));
      } else {
        message.error('Something went wrong while adding product. Please try again...!');
      }
    }
    if (type == 'MOVING_SUPPLIES') {
      let tempProduct = { ...selectedMovingSuppliesProducts[index] };
      if (tempProduct.quantity < 0) return;
      tempProduct.quantity = tempProduct.quantity - 1;

      let cartRes = await addToCart(tempProduct, 'DEC');

      if (cartRes?.data?.id) {
        selectedMovingSuppliesProducts[index].quantity =
          selectedMovingSuppliesProducts[index].quantity - 1;
        if (selectedMovingSuppliesProducts[index].quantity == 0) {
          selectedMovingSuppliesProducts.splice(index, 1);
        }
        dispatch(setMovingSuppliesProducts([...selectedMovingSuppliesProducts]));
      } else {
        message.error('Something went wrong while adding product. Please try again...!');
      }
    }
    setExecuting(false);
  };

  const addToCart = async (obj, type) => {
    let data = {
      product: obj.id,
      quantity: type == 'DEC' ? '-1' : '1',
      session: session,
      cart_main_category: 2,
      cart_sub_category: obj.product_sub_category,
      rental: selectedRentalPeriod.id
    };
    return await addtoCart(data);
  };
  const getCartTotal = async () => {
    let cartRes = await getTotal(session);
    if (cartRes?.data) {
      const OrderTotal = cartRes?.data.session_cart_total;
      setTotalCartValue(OrderTotal);
      localStorage.setItem('cartTotal', OrderTotal);
      setExtraCost(cartRes?.data.extra_cost.toFixed(2));
    }
  };
  useEffect(() => {
    getCartTotal();
    calculateTotal();
  }, [
    selectedPackage,
    selectedRentalPeriod,
    selectedPackagingProducts,
    selectedMovingSuppliesProducts,
    deliveryInfo,
    pickupInfo
  ]);

  const changePackage = async () => {
    if (setOrderSummaryOpen) {
      setOrderSummaryOpen(false);
      dispatch(setStepCount(0));
      dispatch(setChangePackage(true));
    }
  };

  return (
    <>
      <Card title="Order Summary" className="order-summary-card">
        <Row className="ml-0 mr-0">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="summary-panel-content pl-0 pr-0 order-package">
              <p
                className="text-left mb-1 fw-600 package-title d-flex align-items-center"
                key="package">
                Package
              </p>
              {selectedPackage?.title && selectedRentalPeriod?.period ? (
                <>
                  <Row className="pr-15p">
                    <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9}>
                      <p className="text-left mb-1  package-desc" key={'package_value'}>
                        {selectedPackage.title} ({selectedRentalPeriod.period})
                      </p>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                      <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                        ${selectedPackage.price}
                      </p>
                    </Col>
                  </Row>
                  {
                    <span className="change-package" onClick={changePackage}>
                      (change package)
                    </span>
                  }
                </>
              ) : (
                <p className="text-left mb-1  package-desc" key={'package_value'}>
                  No package selected
                </p>
              )}
            </div>
          </Col>
        </Row>
        <Row className="ml-0 mr-0">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="summary-panel-content pl-0 pr-0 order-package ">
              <p className="text-left mb-1 fw-600 package-title" key="package_supplies">
                Packing Supplies (Purchased)
              </p>
              {selectedPackagingProducts.length == 0 ? (
                <p className="text-left mb-1  package-desc" key={'package_value'}>
                  No items in your cart{' '}
                </p>
              ) : (
                selectedPackagingProducts.map((item, index) => {
                  return (
                    <Row
                      key={'item_' + index}
                      className="mt-3 order-package-item align-items-center pr-15p">
                      <Col className="pl-1 pr-1 " xxl={5} xl={5} lg={5} md={4} sm={5} xs={4}>
                        <div className="d-flex justify-content-between">
                          <Button
                            className="decrease-quanity d-flex align-items-center justify-content-center"
                            onClick={() => {
                              decreaseQuantity(index, 'PACKAGE_SUPPLIES');
                            }}
                            icon={<DeleteFilled />}
                            size={'medium'}></Button>
                          {/* <InputNumber min={1} max={10} defaultValue={3} style={{ border: 'none' }} /> */}
                          <p className="item-quantity mb-0 d-flex align-items-center pb-0">
                            {item.quantity}
                          </p>
                          <Button
                            className="increase-quanity d-flex align-items-center justify-content-center"
                            disabled={item?.selectedQuantity >= item?.stock}
                            onClick={() => {
                              increaseQuantity(index, 'PACKAGE_SUPPLIES');
                            }}
                            icon={<PlusOutlined />}
                            size={'medium'}></Button>
                        </div>
                      </Col>
                      <Col
                        className="pl-3 pr-1 fs-13 text-center"
                        xxl={5}
                        xl={5}
                        lg={5}
                        md={5}
                        sm={5}
                        xs={6}>
                        {item.title}
                      </Col>
                      <Col
                        className="pl-1 pr-1 mt-0 text-center"
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}>
                        ${item.quantity * item.price}
                      </Col>
                    </Row>
                  );
                })
              )}
            </div>
          </Col>
        </Row>
        <Row className="ml-0 mr-0">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="summary-panel-content pl-0 pr-0 order-package ">
              <p className="text-left mb-1 fw-600 package-title" key="package_supplies">
                Moving Supplies (Rented)
              </p>
              {selectedMovingSuppliesProducts.length == 0 ? (
                <p className="text-left mb-1  package-desc" key={'package_value'}>
                  No items in your cart{' '}
                </p>
              ) : (
                selectedMovingSuppliesProducts.map((item, index) => {
                  return (
                    <Row
                      key={'item_' + index}
                      className="mt-3 order-package-item align-items-center pr-15p">
                      <Col className="pl-1 pr-1 " xxl={5} xl={5} lg={5} md={4} sm={5} xs={4}>
                        <div className="d-flex justify-content-between">
                          <Button
                            className="decrease-quanity d-flex align-items-center justify-content-center"
                            onClick={() => {
                              decreaseQuantity(index, 'MOVING_SUPPLIES');
                            }}
                            icon={<DeleteFilled />}
                            size={'medium'}></Button>
                          {/* <InputNumber min={1} max={10} defaultValue={3} style={{ border: 'none' }} /> */}
                          <p className="item-quantity mb-0 d-flex align-items-center pb-0">
                            {item.quantity}
                          </p>
                          <Button
                            className="increase-quanity d-flex align-items-center justify-content-center"
                            onClick={() => {
                              increaseQuantity(index, 'MOVING_SUPPLIES');
                            }}
                            icon={<PlusOutlined />}
                            size={'medium'}></Button>
                        </div>
                      </Col>
                      <Col
                        className="pl-3 pr-1 fs-13 text-center"
                        xxl={5}
                        xl={5}
                        lg={5}
                        md={5}
                        sm={5}
                        xs={6}>
                        {item.title}
                      </Col>
                      <Col
                        className="pl-1 pr-1 mt-0 text-center"
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}>
                        $
                        {item.quantity *
                          item.price *
                          Number(selectedRentalPeriod?.period?.split(' ')[0])}
                      </Col>
                    </Row>
                  );
                })
              )}
            </div>
          </Col>
        </Row>

        {deliveryInfo.delivery_date ? (
          <Row className="ml-0 mr-0 highlight-package ">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package ">
                <p className="text-left mb-1 fw-600 package-title" key="package">
                  Delivery Date & Time
                </p>
                <p className="mb-0">
                  {deliveryDateString}
                  <br />
                  {deliveryInfo.delivery_window_1.start_time}-
                  {deliveryInfo.delivery_window_1.end_time}
                  <br />
                  {deliveryInfo.delivery_window_2.start_time}-
                  {deliveryInfo.delivery_window_2.end_time}
                </p>
              </div>
            </Col>
          </Row>
        ) : null}
        {pickupInfo.pickup_date ? (
          <Row className="ml-0 mr-0 highlight-package">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package ">
                <p className="text-left mb-1 fw-600 package-title" key="package">
                  Pickup Date & Time
                </p>
                <p className="mb-0">
                  {moment(pickupInfo?.pickup_date).format('MMM DD, YYYY (dddd)')}
                  <br />
                  {pickupInfo.pickup_window_1.start_time}-{pickupInfo.pickup_window_1.end_time}
                  <br />
                  {pickupInfo.pickup_window_2.start_time}-{pickupInfo.pickup_window_2.end_time}
                </p>
              </div>
            </Col>
          </Row>
        ) : null}

        {extraCost && extraCost > 0 ? (
          <Row className="ml-0 mr-0">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package">
                <Row className="pr-15p">
                  <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9}>
                    <p className="text-left mb-1  package-desc" key={'package_value'}>
                      Stair/Elevator
                    </p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                    <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                      ${extraCost}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null}

        {totalTax && totalTax > 0 ? (
          <Row className="ml-0 mr-0">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package">
                <Row className="pr-15p">
                  <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9}>
                    <p className="text-left mb-1  package-desc" key={'package_value'}>
                      Sales Tax
                    </p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                    <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                      ${totalTax}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null}

        {discount && discount.discount > 0 ? (
          <Row className="ml-0 mr-0">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="summary-panel-content pl-0 pr-0 order-package">
                <Row className="pr-15p">
                  <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9}>
                    <p className="text-left mb-1  package-desc" key={'package_value'}>
                      Discount
                    </p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                    <RenderIf isTrue={discount.coupon_type === 'percentage'}>
                      <RenderIf isTrue={discount.max_allowed_discount}>
                        <RenderIf
                          isTrue={
                            ((discount.discount / 100) * totalCartValue).toFixed(2) >
                            discount.max_allowed_discount
                          }>
                          <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                            ${discount.max_allowed_discount}
                          </p>
                        </RenderIf>
                        <RenderIf
                          isTrue={
                            ((discount.discount / 100) * totalCartValue).toFixed(2) <=
                            discount.max_allowed_discount
                          }>
                          <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                            ${((discount.discount / 100) * totalCartValue).toFixed(2)}
                          </p>
                        </RenderIf>
                      </RenderIf>
                      <RenderIf isTrue={!discount.max_allowed_discount}>
                        <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                          ${((discount.discount / 100) * totalCartValue).toFixed(2)}
                        </p>
                      </RenderIf>
                    </RenderIf>
                    <RenderIf isTrue={discount.coupon_type !== 'percentage'}>
                      <p className="text-left mb-1  package-desc" key={'package_value_amount'}>
                        ${discount.discount}
                      </p>
                    </RenderIf>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null}

        <div className="summary-footer  bg-theme-secondary  mb-0 d-flex">
          <div className="col-6 fw-600">Total Cost</div>
          <div className="col-6 fw-600 text-right">
            {/* {discount && discount.discount > 0 ? (
              <>
                <RenderIf isTrue={discount.coupon_type === 'percentage'}>
                  <RenderIf isTrue={discount.max_allowed_discount}>
                    <RenderIf
                      isTrue={
                        ((discount.discount / 100) * totalCartValue).toFixed(2) >
                        discount.max_allowed_discount
                      }>
                      ${totalCartValue - discount.max_allowed_discount}
                    </RenderIf>
                    <RenderIf
                      isTrue={
                        ((discount.discount / 100) * totalCartValue).toFixed(2) <=
                        discount.max_allowed_discount
                      }>
                      ${(totalCartValue - (discount.discount / 100) * totalCartValue).toFixed(2)}
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={!discount.max_allowed_discount}>
                    ${(totalCartValue - (discount.discount / 100) * totalCartValue).toFixed(2)}
                  </RenderIf>
                </RenderIf>
                <RenderIf isTrue={discount.coupon_type !== 'percentage'}>
                  ${totalCartValue - discount.discount}
                </RenderIf>
              </>
            ) : (
              `$${totalCartValue}`
            )} */}

            {discount && discount.discount > 0 ? (
              <>
                <RenderIf isTrue={discount.coupon_type === 'percentage'}>
                  <RenderIf isTrue={discount.max_allowed_discount}>
                    <RenderIf
                      isTrue={
                        ((discount.discount / 100) * Totalbill).toFixed(2) >
                        discount.max_allowed_discount
                      }>
                      ${Totalbill - discount.max_allowed_discount}
                    </RenderIf>
                    <RenderIf
                      isTrue={
                        ((discount.discount / 100) * Totalbill).toFixed(2) <=
                        discount.max_allowed_discount
                      }>
                      ${(Totalbill - (discount.discount / 100) * Totalbill).toFixed(2)}
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={!discount.max_allowed_discount}>
                    ${(Totalbill - (discount.discount / 100) * Totalbill).toFixed(2)}
                  </RenderIf>
                </RenderIf>
                <RenderIf isTrue={discount.coupon_type !== 'percentage'}>
                  ${Totalbill - discount.discount}
                </RenderIf>
              </>
            ) : (
              `$${Totalbill}`
            )}
          </div>
        </div>
      </Card>
    </>
  );
}
