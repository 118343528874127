import React, { useEffect, useState, useRef, Suspense } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';
import ZipCode from './ZipModal';
import PropTypes from 'prop-types';
import RenderIf from '../../Utility/RenderIf';
import { resetState } from '../../store/action';
import { useDispatch } from 'react-redux';
import { clearCart, getDiscount, validateStorageZipCode } from '../../data/API';
import { ReactSession } from 'react-client-session';
import { Helmet } from 'react-helmet';
import Offers from "../Offers";
import {
  getHomeData,
  getTestimonials,
  getContactInfo,
  getWhyUs,
  getLocations,
  checkZipCode,
  saveQuote
} from '../../data/API';

import toast from 'react-hot-toast';
import './index.css';

import SimpleSlider from './slider';
import { Col, Container, Row } from 'react-bootstrap';
import FilledBox from '../../assets/images/filled_box.png';
import Storage from '../../assets/images/storage.png';
import { Button, Form, Image, Input, message, Select } from 'antd';
import uuid from 'react-uuid';
import { setPincode } from '../StorageBox/store/actionCreators';
import { getToken } from '../../utils/token';
const LazyImage = React.lazy(() => import('../../Utility/LazyImage'));

export default function Home(props) {
  const { Option } = Select;

  const [mainloader, setMainloader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMsg, setMsg] = useState('');
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());

  const [home, setHomeData] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [why_us, setWhyUs] = useState([]);
  const [contact_info, setContactInfo] = useState([]);
  const [locations, setLocation] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [popupData, setPopupData] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [form] = Form.useForm();
  const loginToken = getToken();

  const onFinish = (val) => {
    message.success('Submit success!');
  };
  const onFinishFailed = (err) => {
    message.error('Something Went Wrong Try Again!');
  };
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    delivery_date: '',
    pickup_zipcode: '',
    delivery_zipcode: ''
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    const item = {
      value: true,
      expiry: new Date().getTime()
    };
    // console.log(item.expiry);
    localStorage.setItem('zipcode', JSON.stringify(item));
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  const loadiframe = () => {
    window.$('#myModal').modal('show');
    document.getElementById('iframediv').innerHTML =
      " <button type='button' className='close' onClick= window.$('#myModal').modal('hide') > <span aria-hidden='true'>&times;</span> </button>  <iframe src='https://www.google.com/maps/d/embed?mid=1tKoG-65lKUliYMUheHqFHds9xbLieQQ' width='640' height='480'></iframe>";
  };

  const handleQuoteSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      saveQuote(values)
        .then((res) => {
          toast.success('Successfully created!');
          ('Message sent successfully');
        })
        .catch((e) => {
          toast.error('Some error occure,please try again');
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleZipCode = async (values) => {
    if (values?.service_type && values?.service_type == 'STORAGE_ON_DEMAND') {
      if (validator.current.allValid()) {
        setSubmitLoader(true);

        let payload = {
          deliveryZipCode: values.delivery_zipcode
        };

        let validateRes = await validateStorageZipCode(payload);

        if (validateRes?.data?.data?.serviceExist) {
          dispatch(setPincode(values.delivery_zipcode));
          setSubmitLoader(false);

          if (loginToken) {
            navigate('/storage-box/buying-box');
          } else {
            navigate('/storage-box/login');
          }
        } else {
          setSubmitLoader(false);
          message.error('Service not available in this area.');
        }
      } else {
        validator.current.showMessages();
        forceUpdate(1);
        return false;
      }
    } else {
      setMainloader(true);
      if (validator.current.allValid()) {
        setSubmitLoader(true);

        let obj = {
          pickup_zipcode: values.pickup_zipcode,
          delivery_zipcode: values.delivery_zipcode
        };
        checkZipCode(obj)
          .then((res) => {
            if (res?.data?.success) {
              setSubmitLoader(false);
              navigate('/packages');
            } else {
              setSubmitLoader(false);
              message.error(res?.data?.message);
            }
          })
          .catch((e) => {
            setSubmitLoader(false);
            console.log(e);
          });
      } else {
        validator.current.showMessages();
        forceUpdate(1);
        return false;
      }
    }
  };

  useEffect(() => {
    clearCart(ReactSession.get('session'));
    dispatch(resetState());
    ReactSession.set('session', uuid());
    var cancel = false;
    props.setshowZipModal(true);

    getHomeData()
      .then((res) => {
        if (cancel) return;
        setHomeData(res.data);
      })
      .catch((e) => console.log(e));

    getWhyUs()
      .then((res) => {
        if (cancel) return;
        setWhyUs(res.data);
      })
      .catch((e) => console.log(e));

    getTestimonials()
      .then((res) => {
        if (cancel) return;
        setTestimonials(res.data);
      })
      .catch((e) => console.log(e));

    getLocations()
      .then((res) => {
        if (cancel) return;
        setLocation(res.data);
      })
      .catch((e) => console.log(e));

    if (zipcode === '') {
      setZipcode(false);
    }

    return () => {
      cancel = true;
    };
  }, []);

  const [showM, setModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const hideZModal = () => {
    setModal(false);
  };

  const showZModal = () => {
    setModal(true);
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }


  const fetchOffer = async () => {
  try {
    let res = await getDiscount();
    if (res && res.data && res.data.length > 0) {
      setPopupData(res.data);
      if (res.data[0].display_location === "both" || res.data[0].display_location === "home_page") {
        setPopupVisible(true);
      }
    } else {
      console.error('No data available');
    }
  } catch (error) {
    console.error('Error fetching offer:', error);
  }
};


  // const fetchOffer = async () => {
  //   let res = await getDiscount();
  //   setPopupData(res?.data);
  //   if(res?.data[0].display_location == "both" || res?.data[0].display_location == "home_page"){
  //     setPopupVisible(true)
  //   }
  // }
  useEffect(() => {
    fetchOffer()
  }, [])

  return (
    <>
      <Helmet>
        <title>BlueBox: Moving Box Rentals & Storage in San Francisco Bay Area</title>
        <meta
          name="description"
          content="BlueBox provides a greener, cheaper and more convenient moving box rentals and storage on-demand services in the San Francisco Bay Area"
        />
        <meta
          name="keywords"
          content="reusable moving box rentals, storage San Francisco, moving box rentals San Francisco, moving box rentals San Jose, moving box rentals Oakland"
        />
      </Helmet>
      <ZipCode
        showModal={toggleModal}
        hideModal={hideZModal}
        zipcode={zipcode}
        setZipcode={setZipcode}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        storeStatus={props.storeStatus}
        storeStatusMessage={props.storeStatusMessage}
      />
      {/* <!-- hero --> */}
      <Offers isVisible={isPopupVisible}
        onClose={handleClosePopup}
        popupData={popupData} />
      <section className="hero position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12 position-relative home-banner">
              <h1 className="mb-0 font-weight-bold banner-text aos-init aos-animate">
                Reusable Moving Box Rentals and Storage On-Demand in the San Francisco Bay Area
              </h1>

              <h4 className="text-white text-center mt-3">
                Moving? Don’t buy moving boxes. Rent them! Calm the moving chaos by renting reusable
                moving boxes with free delivery and pick up. Need more storage? We pick up your
                items, store them, and bring them back to you anytime you need them.
              </h4>
              <div className="btns mt-4">
                <div
                  className="row d-flex justify-content-center"
                  style={{ width: '100%', padding: 0, margin: 0 }}>
                  <div className="">
                    <button
                      className="btn btn-primary w-100 d-block aos-init aos-animate"
                      // data-toggle="modal"
                      // data-target="#zipModal"
                      onClick={() => {
                        showZModal();
                        setToggleModal(true);
                      }}>
                      Start Your Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <img
                src="img/help.png"
                className="img-fluid position-absolute"
                data-aos="zoom-in"
                data-aos-duration="1500"
                alt="somethinghelping Men"
              />
            </div> */}
          </div>
        </div>
      </section>
      {/* <!-- hero --> */}
      {/* <!-- Modal --> */}
      <ZipCode showModal={showM} hideModal={hideZModal} />

      {/* <!-- We deliver  --> */}
      <Container fluid={true} className="choose-landing-page py-5">
        <Row className="align-items-center justify-content-center text-center">
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <p className="land-head clr-black">
              BlueBox provides a greener, cheaper and more convenient alternative to traditional
              moving and storage services.
            </p>
          </Col>

          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center d-flex justify-content-center pb-3">
            <div className="land-divider"></div>
          </Col>

          <Col xxl={7} xl={7} lg={7} md={8} sm={12} xs={12}>
            <p className="land-sub-head clr-black">
              Rent moving boxes to reduce the stress and chaos of packing and moving and simplify
              your life with our convenient Storage On-Demand services.
            </p>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center pt-4">
          <Col xxl={6} xl={6} lg={6} md={10} sm={12} xs={12}>
            <Row className="align-items-center justify-content-center">
              <Col xxl={4} xl={4} lg={6} md={5} sm={12} xs={12} className="py-2">
                <div className="land-card">
                  <Image
                    src={FilledBox}
                    preview={false}
                    alt="filled_box"
                    className="land-card-image mb-4"
                    width="100"
                    height="100"
                  />

                  <Button
                    type="primary"
                    size="large"
                    className="land-card-btn"
                    onClick={() => navigate('/moving-boxes')}
                    block>
                    Rent Moving Boxes
                  </Button>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={5} sm={12} xs={12} className="py-2">
                <div className="land-card land-card-storage">
                  <Image
                    src={Storage}
                    preview={false}
                    alt="storage"
                    className="land-card-image mb-4"
                    width="100"
                    height="100"
                  />

                  <Button
                    type="primary"
                    size="large"
                    className="land-card-btn"
                    onClick={() => navigate('/storage-boxes')}
                    block>
                    Storage On-Demand
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* About Us */}

      <div className="container-fluid " style={{ background: 'white', paddingTop: '50px' }}>
        <p className="text-center about-us-heading fw-600 d-center about-title underline">
          About Us
        </p>
        <div className="row section-ml">
          <div className="col-6 d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                src="img/AboutUs-section.png"
                alt="About"
                className="about-img-lg"
                width="530"
                height="430"
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="about-img-sm"
                src="img/AboutUs-section-sm.png"
                alt="About"
                width="340"
                height="270"
              />
            </Suspense>
          </div>
          <div className="col-6   d-flex flex-column justify-content-center fs-23 about-text">
            <p>We’re a small startup with a big vision.</p>

            <p>
              The idea for BlueBox was born from the need to become more sustainable as landfills
              continue to get filled and cities continue to grow in size and population.
            </p>

            <p>
              Our goal is to make peoples’ lives easier while reducing our environmental footprint
              on our planet.
            </p>

            <p>
              We believe it’s time to change the outdated concept of “the way things have always
              been done” by replacing it with “the way things should be done” — now and moving into
              the future.
            </p>
            <Button className="about-us-btn" onClick={() => navigate('/about')} block>
              More about us
            </Button>
          </div>
        </div>
        <div className="row section-xs">
          <div className="col-12   d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                src="img/AboutUs-section.png"
                alt="About"
                className="about-img-lg"
                width="530"
                height="430"
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="about-img-sm"
                src="img/AboutUs-section-sm.png"
                alt="About"
                width="340"
                height="270"
              />
            </Suspense>
          </div>
          <div className="col-12   d-flex flex-column justify-content-center fs-23 about-text">
            <p>We’re a small startup with a big vision.</p>

            <p>
              The idea for BlueBox was born from the need to become more sustainable as landfills
              continue to get filled and cities continue to grow in size and population.
            </p>

            <p>
              Our goal is to make peoples’ lives easier while reducing our environmental footprint
              on our planet.
            </p>

            <p>
              We believe it’s time to change the outdated concept of “the way things have always
              been done” by replacing it with “the way things should be done” — now and moving into
              the future.
            </p>
            <Button className="about-us-btn" onClick={() => navigate('/about')} block>
              More about us
            </Button>
          </div>
        </div>
      </div>

      {/* About Us End*/}

      {/* <!-- why choos us --> */}

      <section className="whyChooseUs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="d-center underline">
                Why Choose BlueBox?
              </h1>
            </div>
            <div className="whyChooseUs_banner">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/homepageicons-01.png" alt="" width="100" height="100" />
                  <h4>Free Delivery & Pick Up</h4>
                  <p className="sub-description">
                    We provide free delivery and pick up of our moving boxes throughout the San
                    Francisco Bay Area and beyond
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="img/homepageicons-02.png" alt="" width="100" height="100" />
                  <h4>Timed Deliveries</h4>
                  <p className="sub-description">
                    We offer timed deliveries to ensure boxes arrive when you need them
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="img/homepageicons-03.png" alt="" width="100" height="100" />
                  <h4>Next-Day Delivery</h4>
                  <p className="sub-description">
                    Need boxes in a hurry? We offer next-day delivery. Just order by 3 PM the day
                    before
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="img/homepageicons-04.png" alt="" width="100" height="100" />
                  <h4>Moving Supplies</h4>
                  <p className="sub-description">
                    We sell moving supplies to help you get packing as soon as boxes arrive
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="img/homepageicons-05.png" alt="" width="100" height="100" />
                  <h4>Earth Friendly</h4>
                  <p className="sub-description">
                    Using reusable boxes saves trees and keeps cardboard out of landfills. No dumps,
                    no dumpsters, no waste
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="img/homepageicons-06.png" alt="" width="100" height="100" />
                  <h4>Stress Free</h4>
                  <p className="sub-description">
                    Moving can be stressful. Packing doesn’t have to be. Pack happy!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- why choos us --> */}

      <div className="row">
        <div className="col-12 nature-div d-flex flex-column text-white justify-content-center align-center text-center">
          <div className="pt-5 pb-5">
            <h1 className="fw-700 main-title">We Value Our Community and Environment</h1>
          </div>
          <div className="text-center pl-5 pr-5">
            <h3 className="mb-0 fw-500 sub-title">
              With every BlueBox order, you’re helping to reduce the tons of cardboard headed to
              landfills, thousands of gallons of oil and water used to create cardboard, and let’s
              not forget our beautiful trees.
            </h3>
          </div>
          <div className="pt-5 pb-4">
            <h2 className="fw-600 sub-title-2">Producing one ton of cardboard requires…</h2>
          </div>
          <div>
            <div className="row" style={{ flexWrap: 'unset' }}>
              <div className="col-md-4 fw-500 fs-30">
                <p className="mb-0 bottom-count">17</p>
                <p className="mb-0 bottom-title">Trees</p>
              </div>
              <div className="col-md-4 fw-500 fs-30">
                <p className="mb-0 bottom-count">79</p>
                <p className="mb-0 bottom-title">Gallons of Oil</p>
              </div>
              <div className="col-md-4 fw-500 fs-30">
                <p className="mb-0 bottom-count">7000</p>
                <p className="mb-0 bottom-title">Gallons of Water</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid " style={{ background: 'white', paddingTop: '50px' }}>
        <div className="row section-ml mt-4">
          <div className="col-md-6 col-sm-12 d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="app-section-img-lg"
                src="img/App-section.png"
                alt="App"
                width="505"
                height="545"
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="app-section-img-sm"
                src="img/App-section-sm.png"
                alt="App"
                width="335"
                height="360"
              />
            </Suspense>
          </div>
          <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center fs-23 about-text ">
            <p className="about-us-heading fw-600 d-center underline">
              Download our app
            </p>
            <p className="mt-3 text-left">
              Stress-free moving and storage solutions in the palm of your hand
            </p>
            <div className="row mt-3">
              <a href="#" className="col-md-3  app-download-button">
                <img src="img/GooglePlay.png" alt="App" width="100%" height="100%" />
              </a>
              <a
                href="https://apps.apple.com/us/app/bluebox-rent/id6450204692"
                className="col-md-3  app-download-button">
                <img src="img/AppStore.png" alt="App" width="100%" height="100%" />
              </a>
            </div>
          </div>
        </div>
        <div className="row section-xs " style={{ marginTop: '10px', paddingBottom: '30px' }}>
          <p className="about-us-heading fw-600 d-center underline">
            Download our app
          </p>
          <p className="mt-3 text-center">
            Stress-free moving and storage solutions in the palm of your hand
          </p>

          <div className="col-12  d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage className="app-section-img-lg" src="img/App-section.png" alt="" />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage className="app-section-img-sm" src="img/App-section-sm.png" alt="" />
            </Suspense>
          </div>
          <div className="col-12  d-flex flex-column justify-content-center fs-23 about-text">
            <div className="row mt-3">
              <a href="" className="col-md-6 col-sm-12 app-download-button">
                <img src="img/GooglePlay.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/bluebox-rent/id6450204692"
                className="col-md-6 col-sm-12 app-download-button app-store-btn">
                <img src="img/AppStore.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- beats cardboard box --> */}
      {/* <section className="cardboardBox">
        <div className="container-fluid">
          <div className="cardbox">
            <div className="row">
              <div className="col-md-12">
                <img src="img/new-boxes-desktop1.png" alt="" />
              </div>
              <div id="mydiv">
                <img src="img/new-boxes-mobile1.png" className="image_mobile" alt="" />
                <img src="img/new-boxes-mobile2.png" className="image_mobile" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- beats cardboard box --> */}

      {/* <!-- slider Section --> */}
      <section className="sliderSection py-3 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="sliderSection-heading">
                <h5 className="sliderSection-title">{`Don't take it from us...`}</h5>
                <h2 className="sliderSection-subTitle">
                  {`Here’s what our`}
                  <br /> customers are
                  <br /> saying
                </h2>
              </div>
            </div>
            <div className="col-md-8">
              <div className="slider">
                <SimpleSlider testimonials={testimonials} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- zip code Section --> */}

      <Container fluid={true} className="px-5 how-we-works-land" style={{ paddingTop: '3rem' }}>
        <Row className="align-items-center justify-content-center pt-4">
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="hww-head">Moving or need more storage?</p>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleZipCode}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="pb-0">
                  <Form.Item
                    name="service_type"
                    label="Service"
                    rules={[
                      {
                        required: true
                      }
                    ]}>
                    <Select placeholder="Select an option" size="large">
                      <Option value="moving_box">Rent Moving Boxes</Option>
                      <Option value="STORAGE_ON_DEMAND">Storage On-Demand</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.service_type !== currentValues.service_type
                    }>
                    {({ getFieldValue }) =>
                      getFieldValue('service_type') === 'STORAGE_ON_DEMAND' ? (
                        <Form.Item
                          name="delivery_zipcode"
                          label="Zip Code where you’d like storage boxes delivered"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zip code" />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="pickup_zipcode"
                          label="Zip code of your current address?"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zip code" />
                        </Form.Item>
                      )
                    }
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.service_type !== currentValues.service_type
                    }>
                    {({ getFieldValue }) =>
                      getFieldValue('service_type') === 'moving_box' ? (
                        <Form.Item
                          name="delivery_zipcode"
                          label="Zip code of your new address?"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zipcode" />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={submitLoader}
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="land-card-btn"
                      block>
                      Start Your Order
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* <RenderIf isTrue={props.storeStatus === "open"}>
        <section
          className="hero move-location position-relative location custom "
          style={{
            background: '#FAF9F6'
          }}>
          <div className="container">
            <div id="locations-content" className="ng-scope">
              <div
                className="row align-content-center"
                style={{
                  jstifyContent: 'center'
                }}>
                <div className="columns small-12 large-10 large-offset-1 end" style={{ marginLeft: "15%", marginRight: "15%" }}>
                  <div className="order-box locationform"> */}
      {/* <h2><img width="32" src="img/icon-truck.png" data-interchange="[/assets/img/icon-truck@2x.png, (retina)]" /> Check If We Service Your Area</h2> */}

      {/* <div className="">
                      <div className="">
                        <div className="modal-header">
                          <img src="img/logo.png" className="img-fluid" alt="" />
                          <h5>Where are you moving?</h5> */}
      {/* <button type="button" className="close" onClick={props.hideModal}>
                                                              <span aria-hidden="true">&times;</span>
                                                          </button> */}
      {/* </div>
                        <div className="modal-body">
                          <div className="row">
                            {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                            <div className="col-md-6 p-2">
                              Zip code of your current address?
                              <input
                                type="text"
                                id="ship-address"
                                className="form-control"
                                placeholder="Enter Zip code"
                                name="pickup_zipcode"
                                value={values.pickup_zipcode}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="col-md-6 p-2">
                              Zip code of your new address?
                              <input
                                type="text"
                                id="pickup-address"
                                className="form-control"
                                placeholder="Enter Zip code"
                                name="delivery_zipcode"
                                value={values.delivery_zipcode}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="col-12 text-right">
                              <a
                                href="box-package.html"
                                className="mt-4 btn continue-zip-code-btn"
                                onClick={(e) => handleZipCode(e)}>
                                Continue
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
      {/* </Modal> */}
      {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </RenderIf> */}

      <br />
      {/* <!-- we deliver to this area --> */}
    </>
  );
}
Home.propTypes = {
  setShowHideHeader: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  setshowZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
