import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentCard from '../../ContentCard';

const ConfirmEmail = (props) => {
    
  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  return (
    <ContentCard type={"emailConfirmation"} />
  )
};

export default ConfirmEmail;


ConfirmEmail.propTypes = {
    setShowHideHeader: PropTypes.func,
    setshowHideFooter: PropTypes.func,
    setshowHideinnerFooter: PropTypes.func
};