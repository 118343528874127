import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';
import {
  rentalsPeriods,
  getDeliverWindows,
  getExtraWork,
  addDelivery,
  getnewRental,
  getCart,
  getTotal,
  getDays,
  checkZipCode,
  getTotalCart,
  getPickUp,
  getBoxPackages,
  getDeliverSlots,
  getBlockedDays
} from '../../../data/API';
import { ReactSession } from 'react-client-session';
import { useDispatch, useSelector } from 'react-redux';
import { changeDeliveryFormValidation } from '../store/actionCreators';
// import DayPicker from 'react-day-picker';
// import 'react-day-picker/lib/style.css';

import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import Autocomplete from 'react-google-autocomplete';
import styles from "./styles.module.css"
// import { Spinner } from "./spinner";
import Spinner from './Spinner';

// import { copyFileSync } from "fs";

function step4Btn() {
  document.getElementById('step4').style.display = 'none';
  document.getElementById('step5').style.display = 'block';

  document.getElementById('st4li').classList.remove('current');
  document.getElementById('st4li').classList.add('complete');
  document.getElementById('st5').classList.add('active');
  document.getElementById('st5li').classList.add('current');
}

function step4BackBtn() {
  document.getElementById('step4').style.display = 'none';
  document.getElementById('step3').style.display = 'block';
  document.getElementById('st4').classList.remove('active');
  document.getElementById('st3').classList.add('active');
  document.getElementById('st3li').classList.add('current');

  document.getElementById('st4li').classList.remove('current');
  document.getElementById('st3li').classList.remove('complete');
}
export default function Step4(props) {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(new SimpleReactValidator());
  const [rentals, setRentalList] = React.useState([]);
  // const [value, setvalue] = useState('');
  const [windows, setWindows] = React.useState([]);
  const [windows1, setWindows1] = React.useState([]);
  const [windows2, setWindows2] = React.useState([]);
  const [extra_work, setExtraWork] = React.useState([]);
  const [disableDays, setDisableDays] = React.useState([]);
  // const [getrental, setnewrental] = React.useState([]);
  const [get_days, setDays] = React.useState();
  // const [Day, selectedDay] = React.useState();
  // const [aptvaL, setAptval] = React.useState();
  const [getDisableDate, setDisableDate] = React.useState([]);
  // const [getDisableWeekends, setDisableWeekends] = React.useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const today = moment();
  const key = 'AIzaSyCXbgZmfe_pRq23TGroNN5K29oC0KSbm5Y';
  const [isError, setError] = React.useState(false);
  const [errorMsg1, setMsg1] = React.useState('');
  const [errorMsg2, setMsg2] = React.useState('');
  // const { details } = props;
  const [slot1, setSlot1] = React.useState('');

  const [getNewAddress, setNewAddress] = React.useState(null);
  // const [addressValue, setAddressValue] = useState("");
  // const [selectedAddress, setSelectedAddress] = useState("");

  // const [allFieldsValue, setAllFieldsValue] = useState({
  //   delivery_date : false,
  //   rental_period : true,
  //   delivery_window1 : false,
  //   delivery_window2 : false,
  //   delivery_address : false,
  //   extra_work : false
  // });

  const allFieldsValue = useSelector((state) => state.boxPackage.delivery_form_validation);

  //Variable to validate inputs
  const [disableSumbit, setDisableSubmit] = useState(true);
  const [validatingZipCode, setValidatingZipCode] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setDisableSubmit(!Object.values(allFieldsValue).every((elem) => elem));
  }, [allFieldsValue])

  const setrental = () => {
    props.setDelivery({ ...props.delivery, rental: props.rentalid });
  };

  //handle date change
  const handleChange123 = (date) => {
    const newdate = moment(date).format('YYYY-MM-DD');
    props.setDelivery({ ...props.delivery, delivery_date: newdate });
    let data = {
      session: ReactSession.get('session'),
      delivery_date: newdate
    };
    // localStorage.setItem('deliverydate', newdate)
    props.setLoading(true);

    addDelivery(data).then((res) => {
      getDeliverSlots(ReactSession.get('session')).then((res) => {
        props.setDeliverydate(res?.data?.results?.[0]?.delivery_date);
        props.setLoading(false);
      });
    });
    dispatch(changeDeliveryFormValidation({ delivery_date: true }));
  };

  const handleChange = (event) => {
    props.setDelivery({ ...props.delivery, [event.target.name]: event.target.value });
    if (event.target.value && event.target.value != "")
      dispatch(changeDeliveryFormValidation({ [event.target.name]: true }))
    else
      dispatch(changeDeliveryFormValidation({ [event.target.name]: false }))

    if (event.target.name === 'rental') {
      props.setLoading(true);
      let data = {
        session: ReactSession.get('session'),
        rental: event.target.value
      };
      // console.log(data.rental)
      // return false;
      getnewRental(data).then((res) => {
        // console.log(res);
        getCart('1', ReactSession.get('session')).then((newcartdata) => {
          // console.log(newcartdata);
          // return false;
          props.setBox(newcartdata.data?.results[0]);
          getTotal(ReactSession.get('session')).then((newtotal) => {
            getTotalCart(ReactSession.get('session')).then((res) => {
              // console.log(res);
              //               return false;
              let rental1 = '';
              let rentalid1 = '';

              if (res.data.results.length > 0) {
                // alert("here")
                // console.log(res.data.results[0].rental_int)
                rental1 = res.data.results[0].rental_int + ' Weeks';
                rentalid1 = res.data.results[0].rental;
              } else {
                rental1 = '2 Weeks';
                rentalid1 = '781ce70c-ba18-46b1-b585-57dda9f3c778';
              }
              // console.log("rentalid1");
              // console.log(rentalid1);
              // console.log(rental1);

              getBoxProducts(rental1);
              props.setSelectedRental(rental1);
              props.setRentalId(rentalid1);
              // props.setDelivery({ ...props.delivery, rental: rentalid1 });

              const getboxcart = res?.data?.results.filter((obj) => {
                return obj?.product?.product_sub_category === 1;
              });
              if (getboxcart) {
                props.setBox(getboxcart[0]);
              }
              const getpackingcart = res?.data?.results.filter((obj) => {
                return obj?.product?.product_sub_category === 2;
              });

              if (getpackingcart) {
                props.setPackings(getpackingcart);
              }
              const getmovingcart = res?.data?.results.filter((obj) => {
                return obj?.product?.product_sub_category === 3;
              });
              if (getmovingcart) {
                props.setMovings(getmovingcart);
              }

              getTotal(ReactSession.get('session')).then((newtotal) => {
                props.setTotal(newtotal?.data?.session_cart_total);
                props.setLoading(false);
              });
            });
          });
        });
      });
    }

    if (event.target.name === 'apt') {
      if (event.target.value) {
        // localStorage.setItem("apt",event.target.value);
      }
    }

    if (event.target.name === 'delivery_window1') {
      if (event.target.value) {
        // console.log(event.target.value);
        const disableslot = windows.filter((obj) => obj.id !== event.target.value);
        setWindows2(disableslot);
        props.setDelivery({ ...props.delivery, delivery_window1: event.target.value });
        setSlot1(event.target.value);
        let data = {
          session: ReactSession.get('session'),
          delivery_window_1: event.target.value
        };
        // localStorage.setItem("deliverywindow1", event.target.value)
        props.setLoading(true);

        addDelivery(data).then((res) => {
          getDeliverSlots(ReactSession.get('session')).then((res) => {
            props.setDelivetslot1(res?.data?.results?.[0]?.delivery_window_1);
            props.setLoading(false);
          });
        });
      }
    }
    if (event.target.name === 'delivery_window2') {
      if (event.target.value) {
        const disableslots = windows.filter((obj) => obj.id !== event.target.value);
        setWindows1(disableslots);
        props.setDelivery({ ...props.delivery, delivery_window2: event.target.value });

        let data = {
          session: ReactSession.get('session'),
          delivery_window_2: event.target.value
        };
        // localStorage.setItem("deliverywindow2", event.target.value)
        props.setLoading(true);
        addDelivery(data).then((res) => {
          getDeliverSlots(ReactSession.get('session')).then((res) => {
            props.setDelivetslot2(res?.data?.results?.[0]?.delivery_window_2);
            props.setLoading(false);
          });
        });
      }
    }
    if (event.target.name === 'description') {
      if (event.target.value) {
        // localStorage.setItem("deliverd",event.target.value)
        props.setDelivery({ ...props.delivery, description: event.target.value });
      }
    }
    if (event.target.name === 'extra_work') {
      if (event.target.value) {
        let data = {
          session: ReactSession.get('session'),
          extra_work: event.target.value
        };
        // localStorage.setItem("extrawork1",event.target.value)
        props.setLoading(true);
        addDelivery(data).then((res) => {
          getTotalCart(ReactSession.get('session')).then((res) => {
            // console.log(res);
            const getboxcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 1;
            });
            if (getboxcart) {
              props.setBox(getboxcart[0]);
            }
            const getpackingcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 2;
            });

            if (getpackingcart) {
              props.setPackings(getpackingcart);
            }
            const getmovingcart = res?.data?.results.filter((obj) => {
              return obj?.product?.product_sub_category === 3;
            });
            if (getmovingcart) {
              props.setMovings(getmovingcart);
            }
            // console.log(getboxcart[0]);
            props.setSelectedRental(getboxcart[0].rental_int + ' Weeks');
            props.setRentalId(getboxcart[0].rental);
            getTotal(ReactSession.get('session')).then((newtotal) => {
              // console.log(newtotal);
              props.setTotal(newtotal?.data?.session_cart_total);
              // console.log(newtotal?.data?.extra_cost);
              props.setStairevevators(newtotal?.data?.extra_cost);
              props.setLoading(false);
            });
          });
        });
      }
    }
    // console.log(props.delivery)

    const getBoxProducts = async (rental = props.select_rental) => {
      getBoxPackages(props.category, props.sub_category, rental)
        .then((res) => {
          props.setProducts(res?.data);
        })
        .catch((e) => console.log(e));
    };
  };

  const setaddress = (place) => {
    // const setaddress = () => {
    // props.setSpinner(true);
    setNewAddress(place?.formatted_address);

    // props.setDelivery({ ...props.delivery, delivery_address: place?.formatted_address })
    // localStorage.setItem("deliveryaddress",place?.formatted_address)

    // for (var i = 0; i < place.address_components.length; i++) {
    //   for (var j = 0; j < place.address_components[i].types.length; j++) {
    //     if (place.address_components[i].types[j] === 'postal_code') {
    //       var postal = place.address_components[i].long_name;
    //     }
    //     if (place.address_components[i].types[j] === 'country') {
    //       var country = place.address_components[i].short_name;
    //     }
    //     if (place.address_components[i].types[j] === 'locality') {
    //       var city = place.address_components[i].long_name;
    //     }
    //     if (place.address_components[i].types[j] === 'administrative_area_level_1') {
    //       var state = place.address_components[i].long_name;
    //     }
    //   }
    // }


    //Address Logic
    let postal, country, city, state
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes("postal_code"))
        postal = address_element.long_name;
      if (address_element.types.includes("country"))
        country = address_element.short_name;
      if (address_element.types.includes("locality"))
        city = address_element.long_name;
      if (address_element.types.includes("administrative_area_level_1"))
        state = address_element.long_name
    })

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    props.setAddress(address);
    dispatch(changeDeliveryFormValidation({ delivery_address: true }))
    const data = {
      deliveryzipcode: postal ? postal : "781006"

    };
    setValidatingZipCode(true);
    checkZipCode(data).then((res) => {
      if (res?.data?.success === true) {
        setError(true);
        setMsg1(res?.data?.message_2);
        dispatch(changeDeliveryFormValidation({ no_error: true }))
        setMsg2('');
        props.setSpinner(false);
      } else {
        setError(true);
        setMsg2(res?.data?.message_2);
        dispatch(changeDeliveryFormValidation({ no_error: false }))
        setMsg1('');
        props.setSpinner(false);
      }
    }).finally(() => {
      setValidatingZipCode(false);
    })
  };

  const yesterday = moment().subtract(1, 'day');
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const weekend = (current) => {
    const date = new Date();
    const time = date.getHours();
    if (time <= 15) {
      return (
        // current.day() !== 0 &&
        // current.day() !== 6 &&
        current.isAfter(yesterday) &&
        current.isAfter(today) &&
        !getDisableDate.includes(current.format('YYYY-MM-DD'))
      );
    } else {
      return (
        // current.day() !== 0 &&
        // current.day() !== 6 &&
        current.isAfter(yesterday) &&
        current.isAfter(today) &&
        current.isAfter(tomorrow) &&
        !getDisableDate.includes(current.format('YYYY-MM-DD'))
      );
    }
  };

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      let data = {
        session: ReactSession.get('session'),
        delivery_date: props.delivery?.delivery_date,
        rental: props.delivery?.rental,
        delivery_address: getNewAddress,
        latitude: props.delivery?.latitude,
        longitude: props.delivery?.longitude,
        apt_number: props.delivery?.apt,
        description: props.delivery?.description,
        delivery_window_1: props.delivery?.delivery_window1,
        delivery_window_2: props.delivery?.delivery_window2,
        extra_work: props.delivery?.extra_work
      };
      // console.log(data?.delivery_window_1)
      //console.log(data);
      addDelivery(data).then((res) => {
        getTotal(ReactSession.get('session')).then((newtotal) => {
          // console.log(newtotal);
          props.setTotal(newtotal?.data?.session_cart_total);

          getPickUp(ReactSession.get('session')).then((res) => {
            props.setPickupdate(res?.data?.results[res?.data?.results?.length - 1]?.pickup_date);
            // getDeliverSlots(ReactSession.get('session'))
            // .then((res) => {
            //  props.setDelivetslot1(res?.data?.results?.[0]?.delivery_window_1);
            //  props.setDelivetslot2(res?.data?.results?.[0]?.delivery_window_2);
            // })
          });
          step4Btn();
        });
      });
      step4Btn();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const callApi = () => {
    props.setDelivery({ ...props.delivery, rental: props.select_rental });
    rentalsPeriods()
      .then((res) => {
        setRentalList(res?.data);
      })
      .catch((e) => console.log(e));
    getDeliverWindows()
      .then((res) => {
        setWindows(res?.data.results);
        setWindows1(res?.data.results);
        setWindows2(res?.data.results);
      })
      .catch((e) => console.log(e));

    getDeliverWindows()
      .then((res) => {
        setWindows2(res?.data.results);
      })
      .catch((e) => console.log(e));

    getExtraWork()
      .then((res) => {
        setExtraWork(res?.data);
      })
      .catch((e) => console.log(e));

    getDays().then((res) => {
      setDays(res?.data);

      res?.data?.results?.map((obj) => {
        setDisableDate(obj.date);
        props.setDelivery({ ...props.delivery, rental: props.rentalid });
      });
    });

  };


  React.useEffect(() => {
    // console.log(delivery)
    // setnewdelivery();
    callApi();
  }, []);


  return (
    <>
      <div className="step4" id="step4">
        <h2 className="bg-primary text-white text-center py-2">Step 4: Delivery Details</h2>
        <div className="row">
          <div className="container px-5 my-4">
            {/* <div className="row py-4"> */}

            <form>
              <h5>Delivery Details</h5>
              <hr />
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Delivery Date</label>

                  <DatePicker
                    className={styles.date}
                    timeFormat={false}
                    isValidDate={weekend}
                    inputProps={{ readOnly: true }}
                    name="delivery_date"
                    input={true}
                    dateFormat="YYYY-MM-DD"
                    placeholder="Choose a date"
                    closeOnSelect
                    value={props.delivery.delivery_date}
                    // defaultDate={localStorage.getItem("deliverydate")}
                    onChange={(date) => handleChange123(date)}
                  />

                  {validator.current.message(
                    'Delivery Date',
                    props.delivery.delivery_date,
                    'required',
                    {
                      className: 'text-danger'
                    }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Rental Period</label>
                  <select
                    className="form-control"
                    name="rental"
                    value={props.delivery.rental}
                    onChange={(e) => handleChange(e)}>
                    {rentals.results?.map((obj, i) => {
                      return (
                        <option
                          key={i}
                          value={obj.id}
                          defaultValue={obj.id === props.rentalid ? props.rentalid : ''}>
                          {obj.period}
                        </option>
                      );
                    })}
                  </select>

                  {validator.current.message('Rental', props.delivery.rental, 'required', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Delivery Window 1</label>
                  <select
                    className="form-control"
                    name="delivery_window1"
                    selected={props.delivery.delivery_window1}
                    value={props.delivery.delivery_window1}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select a time slot</option>
                    {windows1.map((obj, i) => {
                      // console.log(obj)
                      return (
                        <option
                          key={i}
                          value={obj.id}>{`${obj.start_time} - ${obj.end_time}`}</option>
                      );
                    })}
                  </select>
                  {validator.current.message(
                    'Delivery Window1',
                    props.delivery.delivery_window1,
                    'required',
                    { className: 'text-danger' }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Delivery Window 2</label>
                  <select
                    className="form-control"
                    name="delivery_window2"
                    value={props.delivery.delivery_window2}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select a time slot</option>
                    {windows2
                      ?.filter((e) => e.id !== parseInt(props.delivery.delivery_window1))
                      .map((obj, i) => {
                        return (
                          <option
                            key={i}
                            value={obj.id}>{`${obj.start_time} - ${obj.end_time}`}</option>
                        );
                      })}
                  </select>

                  {validator.current.message(
                    'Delivery Window2',
                    props.delivery.delivery_window2,
                    'required',
                    { className: 'text-danger' }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className={styles.required}>Delivery Address</label>
                  <Autocomplete
                    className="form-control"
                    name="delivery_address"
                    apiKey={key}
                    onPlaceSelected={(place) => {
                      setaddress(place);
                    }}
                    // value={addressValue}
                    // onChange={handleAddressValue}
                    // onBlur={handleBlurAddressValue}
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'us' }
                    }}
                  />

                  {isError ? <p style={{ color: 'green' }}>{errorMsg1}</p> : ''}
                  {isError ? <p style={{ color: 'red' }}>{errorMsg2}</p> : ''}
                  {validator.current.message(
                    'Delivery Address',
                    props.delivery.delivery_address,
                    'not_in',
                    {
                      className: 'text-danger'
                    }
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label>Apt</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apt"
                    name="apt"
                    autoComplete="off"
                    value={props.delivery.apt}
                    onChange={(e) => handleChange(e)}
                  />

                  {validator.current.message('apt', props.delivery.apt, 'not_in', {
                    className: 'text-danger'
                  })}
                </div>
                <div className="col-md-12 mt-3">
                  {/* <label className={styles.required}>Do you have stairs or an elevator?</label> */}

                  <select
                    className="form-control"
                    name="extra_work"
                    value={props.delivery.extra_work}
                    onChange={(e) => handleChange(e)}>
                    <option value="">Select an option</option>;
                    {extra_work.results?.map((obj, i) => {
                      return (
                        <option key={i} value={obj.id}>{`${obj.title} ($${obj.price})`}</option>
                      );
                    })}
                  </select>

                  {validator.current.message(
                    'stairs or an elevator',
                    props.delivery.extra_work,
                    'required',
                    { className: 'text-danger' }
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label>Is there anything else we should know?</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={props.delivery.description}
                    onChange={(e) => handleChange(e)}></textarea>

                  {/* {validator.current.message(
                    "description",
                    props.delivery.description,
                    "",
                    { className: "text-danger" }
                  )} */}
                </div>
              </div>
            </form>

            {/* </div> */}

            <div className="row">
              <div className="col-6">
                <div className="text-left mt-4">
                  <button className="btn btn-dark step4BackBtn" onClick={step4BackBtn}>
                    Previous Step
                  </button>
                </div>
              </div>
              <div className="col-6">
                <div className="text-right mt-4">
                  <button
                    className="btn btn-dark step4Btn"
                    // onClick={step4Btn}
                    onClick={(e) => {
                      step4Btn;
                      handleDeliverySubmit(e);
                    }}
                    disabled={!validatingZipCode && disableSumbit}>
                    Step 5: Pickup Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Step4.propTypes = {
  tabRef: PropTypes.any,
  delivery: PropTypes.any,
  setDelivery: PropTypes.func,
  setMovings: PropTypes.func,
  setRentalId: PropTypes.func,
  setPackings: PropTypes.func,
  rentalid: PropTypes.any,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  select_rental: PropTypes.any,
  setSelectedRental: PropTypes.func,
  setBox: PropTypes.func,
  setTotal: PropTypes.func,
  setStairevevators: PropTypes.func,
  getStairevevators: PropTypes.any,
  spinner: PropTypes.any,
  setSpinner: PropTypes.func,
  getPickupdate: PropTypes.any,
  setPickupdate: PropTypes.func,
  category: PropTypes.any,
  setCategory: PropTypes.func,
  address: PropTypes.any,
  setAddress: PropTypes.func,
  getDeliverydate: PropTypes.any,
  setDeliverydate: PropTypes.func,
  delivetslot1: PropTypes.array,
  setDelivetslot1: PropTypes.func,
  delivetslot2: PropTypes.array,
  setDelivetslot2: PropTypes.func,
  details: PropTypes.any,
  setDetails: PropTypes.func,
  setProducts: PropTypes.func,
  sub_category: PropTypes.any
};
