import * as actionTypes from './constants';

export const setStepCount = (payload) => ({
    type: actionTypes.SET_STEP_COUNT,
    data: payload
})

export const setCustomer = (payload) => ({
    type: actionTypes.SET_CUSTOMER,
    data: payload
})


export const setBoxCount = (payload) => ({
    type: actionTypes.SET_BOX_COUNT,
    data: payload
})


export const setDeliveryDetails = (payload) => ({
    type: actionTypes.SET_DELIVERY_DETAILS,
    data: payload
})


export const setPersonalDetails = (payload) => ({
    type: actionTypes.SET_PERSONAL_DETAILS,
    data: payload
})


export const resetAuthStore = (payload) => ({
    type: actionTypes.RESET_AUTH_STORE,
    data: payload
})

export const setPincode = (payload) => ({
    type: actionTypes.SET_SB_PINCODE,
    data: payload
})

export const setExtraWork = (payload) => ({
    type: actionTypes.SET_EXTRA_WORK_ID,
    data: payload
})


export const setRequestedBoxes = (payload) => ({
    type: actionTypes.SET_REQUESTED_BOXES,
    data: payload
})

export const setOrderDeliverDetails = (payload) => ({
    type: actionTypes.SET_STORAGE_DELIVER_DETAILS,
    data: payload
})

export const resetStore = () => ({
    type: actionTypes.RESET_REDUX_STORE,
})