// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_date__NYyjd input {\n    background-color: #ffffff !important;\n}\n\n.styles_required__mdPDW::after{\n    color:red;\n    content: \" *\"\n}", "",{"version":3,"sources":["webpack://./src/views/BoxPackages/components/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,SAAS;IACT;AACJ","sourcesContent":[".date input {\n    background-color: #ffffff !important;\n}\n\n.required::after{\n    color:red;\n    content: \" *\"\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "styles_date__NYyjd",
	"required": "styles_required__mdPDW"
};
export default ___CSS_LOADER_EXPORT___;
