export const RESET_REDUX_STORE = 'RESET_REDUX_STORE';
export const SET_STEP_COUNT = 'SET_STEP_COUNT';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_BOX_COUNT = 'SET_BOX_COUNT';
export const SET_DELIVERY_DETAILS = 'SET_DELIVERY_DETAILS';
export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const RESET_AUTH_STORE = 'RESET_AUTH_STORE';
export const SET_SB_PINCODE = 'SET_SB_PINCODE';
export const SET_EXTRA_WORK_ID = 'SET_EXTRA_WORK_ID';
export const SET_REQUESTED_BOXES = 'SET_REQUESTED_BOXES';
export const SET_STORAGE_DELIVER_DETAILS = 'SET_STORAGE_DELIVER_DETAILS';





