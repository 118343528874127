import * as actionTypes from './constants';
export const setBlogsData = (payload) => ({
  type: actionTypes.SET_BLOGS,
  data: payload
});

export const unSetBlogsData = (payload) => ({
  type: actionTypes.RESET_REDUX_STORE,
  data: payload
});
