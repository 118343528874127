import { Button, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ConfirmEmail from '../../../../assets/images/email_sent.png';
import PropTypes from 'prop-types';

import './index.css';
import { useNavigate } from 'react-router-dom';

const textContent = {
    "emailConfirmation" : {
        "heading": "Confirm your email",
        "sub_text_1": "Click the link in your email to finish setting up your account. If you didn’t",
        "sub_text_2": "get it, you can send a new one in",
        "count": 30,
        "button": true,
        "buttonText": "Send new link"
    },
    "emailConfirmed": {
        "heading": "Email Confirmed!",
        "sub_text_1": "You'll be redirected to login in a few seconds",
        "sub_text_2": "",
        "count": 5,
        "button": false,
        "buttonText": ""
    },
    "passwordReset": {
        "heading": "Your password was reset successfully",
        "sub_text_1": "You can now login into your account",
        "sub_text_2": "",
        "count": 0,
        "button": true,
        "buttonText": "Login"
    }
}

const ContentCard = ({ type = "emailConfirmation" }) => {
    const [timer, setTimer] = useState(textContent[type].count);
    const navigate = useNavigate()

    const reduceTime = () => {
        setTimeout(() => {
            setTimer(timer - 1)
        }, 1000)
    }

    useEffect(() => {
        if(timer !==0 ) reduceTime()
        else navigate('/storage-box/login')
    }, [timer])

    const handleClick = (text) => {
        if(text.toLowerCase() == 'login') navigate('/storage-box/login')
        else return true;
    }

    return (
        <Container
            fluid={true}
        >
            <Row>
                <Col>
                    <div className='card-layout py-5'>
                        <div className='card-content text-center'>
                            <Image 
                                src={ConfirmEmail}
                                preview={false}
                                alt={"contnet_img"}
                                className="card-img py-1"
                            />

                            <p className='s-heading mb-0 py-2'>{textContent[type].heading}</p>
                            <p className='s-sub-text mb-0'>{textContent[type].sub_text_1}</p>
                            {
                                type == 'emailConfirmation' 
                                ? <p className='s-sub-text mb-0'>{textContent[type].sub_text_2} <span className='color-secondary'>{timer} seconds</span></p> 
                                :<p className='s-sub-text mb-0'>{textContent[type].sub_text_2}</p>
                            }
                            
                            { textContent[type].button ? <Button type='primary' size={'large'} className='my-4 signup-register-btn' onClick={() => handleClick(textContent[type].buttonText)} disabled={timer !== 0}>{textContent[type].buttonText}</Button> : "" }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ContentCard;

ContentCard.propTypes = {
    type: PropTypes.string
};