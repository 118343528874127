import PropTypes from 'prop-types';

export default function RenderIf({children, isTrue}){
    return (!isTrue)? null : children;
}

RenderIf.propTypes = {
    children: PropTypes.any,
    isTrue: PropTypes.bool
      
}
